import {
    BufferGeometry,
    FileLoader,
    Float32BufferAttribute,
    Group,
    LineBasicMaterial,
    LineSegments,
    Loader,
    Material,
    Mesh,
    MeshPhongMaterial,
    Points,
    PointsMaterial,
    Vector3,
    Color
} from 'three';

// o object_name | g group_name
const _object_pattern = /^[og]\s*(.+)?/;
// mtllib file_reference
const _material_library_pattern = /^mtllib /;
// usemtl material_name
const _material_use_pattern = /^usemtl /;
// usemap map_name
const _map_use_pattern = /^usemap /;
const _face_vertex_data_separator_pattern = /\s+/;

const _vA = new Vector3();
const _vB = new Vector3();
const _vC = new Vector3();

const _ab = new Vector3();
const _cb = new Vector3();

const _color = new Color();

function ParserState() {

    const state = {
        objects: [],
        object: {},

        vertices: [],
        normals: [],
        colors: [],
        uvs: [],

        materials: {},
        materialLibraries: [],

        startObject: function ( name, fromDeclaration ) {

            // If the current object (initial from reset) is not from a g/o declaration in the parsed
            // file. We need to use it for the first parsed g/o to keep things in sync.
            if ( this.object && this.object.fromDeclaration === false ) {

                this.object.name = name;
                this.object.fromDeclaration = ( fromDeclaration !== false );
                return;

            }

            const previousMaterial = ( this.object && typeof this.object.currentMaterial === 'function' ? this.object.currentMaterial() : undefined );

            if ( this.object && typeof this.object._finalize === 'function' ) {

                this.object._finalize( true );

            }

            this.object = {
                name: name || '',
                fromDeclaration: ( fromDeclaration !== false ),

                geometry: {
                    vertices: [],
                    normals: [],
                    colors: [],
                    uvs: [],
                    hasUVIndices: false
                },
                materials: [],
                smooth: true,

                startMaterial: function ( name, libraries ) {

                    const previous = this._finalize( false );

                    // New usemtl declaration overwrites an inherited material, except if faces were declared
                    // after the material, then it must be preserved for proper MultiMaterial continuation.
                    if ( previous && ( previous.inherited || previous.groupCount <= 0 ) ) {

                        this.materials.splice( previous.index, 1 );

                    }

                    const material = {
                        index: this.materials.length,
                        name: name || '',
                        mtllib: ( Array.isArray( libraries ) && libraries.length > 0 ? libraries[ libraries.length - 1 ] : '' ),
                        smooth: ( previous !== undefined ? previous.smooth : this.smooth ),
                        groupStart: ( previous !== undefined ? previous.groupEnd : 0 ),
                        groupEnd: - 1,
                        groupCount: - 1,
                        inherited: false,

                        clone: function ( index ) {

                            const cloned = {
                                index: ( typeof index === 'number' ? index : this.index ),
                                name: this.name,
                                mtllib: this.mtllib,
                                smooth: this.smooth,
                                groupStart: 0,
                                groupEnd: - 1,
                                groupCount: - 1,
                                inherited: false
                            };
                            cloned.clone = this.clone.bind( cloned );
                            return cloned;

                        }
                    };

                    this.materials.push( material );

                    return material;

                },

                currentMaterial: function () {

                    if ( this.materials.length > 0 ) {

                        return this.materials[ this.materials.length - 1 ];

                    }

                    return undefined;

                },

                _finalize: function ( end ) {

                    const lastMultiMaterial = this.currentMaterial();
                    if ( lastMultiMaterial && lastMultiMaterial.groupEnd === - 1 ) {

                        lastMultiMaterial.groupEnd = this.geometry.vertices.length / 3;
                        lastMultiMaterial.groupCount = lastMultiMaterial.groupEnd - lastMultiMaterial.groupStart;
                        lastMultiMaterial.inherited = false;

                    }

                    // Ignore objects tail materials if no face declarations followed them before a new o/g started.
                    if ( end && this.materials.length > 1 ) {

                        for ( let mi = this.materials.length - 1; mi >= 0; mi -- ) {

                            if ( this.materials[ mi ].groupCount <= 0 ) {

                                this.materials.splice( mi, 1 );

                            }

                        }

                    }

                    // Guarantee at least one empty material, this makes the creation later more straight forward.
                    if ( end && this.materials.length === 0 ) {

                        this.materials.push( {
                            name: '',
                            smooth: this.smooth
                        } );

                    }

                    return lastMultiMaterial;

                }
            };

            // Inherit previous objects material.
            // Spec tells us that a declared material must be set to all objects until a new material is declared.
            // If a usemtl declaration is encountered while this new object is being parsed, it will
            // overwrite the inherited material. Exception being that there was already face declarations
            // to the inherited material, then it will be preserved for proper MultiMaterial continuation.

            if ( previousMaterial && previousMaterial.name && typeof previousMaterial.clone === 'function' ) {

                const declared = previousMaterial.clone( 0 );
                declared.inherited = true;
                this.object.materials.push( declared );

            }

            this.objects.push( this.object );

        },

        finalize: function () {

            if ( this.object && typeof this.object._finalize === 'function' ) {

                this.object._finalize( true );

            }

        },

        parseVertexIndex: function ( value, len ) {

            const index = parseInt( value, 10 );
            return ( index >= 0 ? index - 1 : index + len / 3 ) * 3;

        },

        parseNormalIndex: function ( value, len ) {

            const index = parseInt( value, 10 );
            return ( index >= 0 ? index - 1 : index + len / 3 ) * 3;

        },

        parseUVIndex: function ( value, len ) {

            const index = parseInt( value, 10 );
            return ( index >= 0 ? index - 1 : index + len / 2 ) * 2;

        },

        addVertex: function ( a, b, c ) {

            const src = this.vertices;
            const dst = this.object.geometry.vertices;

            dst.push( src[ a + 0 ], src[ a + 1 ], src[ a + 2 ] );
            dst.push( src[ b + 0 ], src[ b + 1 ], src[ b + 2 ] );
            dst.push( src[ c + 0 ], src[ c + 1 ], src[ c + 2 ] );

        },

        addVertexPoint: function ( a ) {

            const src = this.vertices;
            const dst = this.object.geometry.vertices;

            dst.push( src[ a + 0 ], src[ a + 1 ], src[ a + 2 ] );

        },

        addVertexLine: function ( a ) {

            const src = this.vertices;
            const dst = this.object.geometry.vertices;

            dst.push( src[ a + 0 ], src[ a + 1 ], src[ a + 2 ] );

        },

        addNormal: function ( a, b, c ) {

            const src = this.normals;
            const dst = this.object.geometry.normals;

            dst.push( src[ a + 0 ], src[ a + 1 ], src[ a + 2 ] );
            dst.push( src[ b + 0 ], src[ b + 1 ], src[ b + 2 ] );
            dst.push( src[ c + 0 ], src[ c + 1 ], src[ c + 2 ] );

        },

        addFaceNormal: function ( a, b, c ) {

            const src = this.vertices;
            const dst = this.object.geometry.normals;

            _vA.fromArray( src, a );
            _vB.fromArray( src, b );
            _vC.fromArray( src, c );

            _cb.subVectors( _vC, _vB );
            _ab.subVectors( _vA, _vB );
            _cb.cross( _ab );

            _cb.normalize();

            dst.push( _cb.x, _cb.y, _cb.z );
            dst.push( _cb.x, _cb.y, _cb.z );
            dst.push( _cb.x, _cb.y, _cb.z );

        },

        addColor: function ( a, b, c ) {

            const src = this.colors;
            const dst = this.object.geometry.colors;

            if ( src[ a ] !== undefined ) dst.push( src[ a + 0 ], src[ a + 1 ], src[ a + 2 ] );
            if ( src[ b ] !== undefined ) dst.push( src[ b + 0 ], src[ b + 1 ], src[ b + 2 ] );
            if ( src[ c ] !== undefined ) dst.push( src[ c + 0 ], src[ c + 1 ], src[ c + 2 ] );

        },

        addUV: function ( a, b, c ) {

            const src = this.uvs;
            const dst = this.object.geometry.uvs;

            dst.push( src[ a + 0 ], src[ a + 1 ] );
            dst.push( src[ b + 0 ], src[ b + 1 ] );
            dst.push( src[ c + 0 ], src[ c + 1 ] );

        },

        addDefaultUV: function () {

            const dst = this.object.geometry.uvs;

            dst.push( 0, 0 );
            dst.push( 0, 0 );
            dst.push( 0, 0 );

        },

        addUVLine: function ( a ) {

            const src = this.uvs;
            const dst = this.object.geometry.uvs;

            dst.push( src[ a + 0 ], src[ a + 1 ] );

        },

        addFace: function ( a, ua, na, b, ub, nb, c, uc, nc ) {

            const vLen = this.vertices.length;

            let ia = this.parseVertexIndex( a, vLen );
            let ib = this.parseVertexIndex( b, vLen );
            let ic = this.parseVertexIndex( c, vLen );

            this.addVertex( ia, ib, ic );
            this.addColor( ia, ib, ic );

            // normals

            if ( na !== undefined && na !== '' ) {

                const nLen = this.normals.length;

                ia = this.parseNormalIndex( na, nLen );
                ib = this.parseNormalIndex( nb, nLen );
                ic = this.parseNormalIndex( nc, nLen );

                this.addNormal( ia, ib, ic );

            } else {

                this.addFaceNormal( ia, ib, ic );

            }

            // uvs

            if ( ua !== undefined && ua !== '' ) {

                const uvLen = this.uvs.length;

                ia = this.parseUVIndex( ua, uvLen );
                ib = this.parseUVIndex( ub, uvLen );
                ic = this.parseUVIndex( uc, uvLen );

                this.addUV( ia, ib, ic );

                this.object.geometry.hasUVIndices = true;

            } else {

                // add placeholder values (for inconsistent face definitions)

                this.addDefaultUV();

            }

        },

        addPointGeometry: function ( vertices ) {

            this.object.geometry.type = 'Points';

            const vLen = this.vertices.length;

            for ( let vi = 0, l = vertices.length; vi < l; vi ++ ) {

                const index = this.parseVertexIndex( vertices[ vi ], vLen );

                this.addVertexPoint( index );
                this.addColor( index );

            }

        },

        addLineGeometry: function ( vertices, uvs ) {

            this.object.geometry.type = 'Line';

            const vLen = this.vertices.length;
            const uvLen = this.uvs.length;

            for ( let vi = 0, l = vertices.length; vi < l; vi ++ ) {

                this.addVertexLine( this.parseVertexIndex( vertices[ vi ], vLen ) );

            }

            for ( let uvi = 0, l = uvs.length; uvi < l; uvi ++ ) {

                this.addUVLine( this.parseUVIndex( uvs[ uvi ], uvLen ) );

            }

        }

    };

    state.startObject( '', false );

    return state;

}

//

class BINLoader extends Loader {

    constructor( manager ) {

        super( manager );

        this.materials = null;

    }

    load( url, onLoad, onProgress, onError ) {

        const scope = this;

        const loader = new FileLoader( this.manager );
        loader.setPath( this.path );
        loader.setRequestHeader( this.requestHeader );
        loader.setWithCredentials( this.withCredentials );
        loader.load( url, function ( text ) {

            try {

                onLoad( scope.parse( text ) );

            } catch ( e ) {

                if ( onError ) {

                    onError( e );

                } else {

                    console.error( e );

                }

                scope.manager.itemError( url );

            }

        }, onProgress, onError );

    }

    setMaterials( materials ) {

        this.materials = materials;

        return this;

    }

    parse( bin ) {

        // Usefull functions to get shorts and ints
        function getInt16(lowerbyte, upperbyte) {
            var sign = upperbyte & 0x80;
            var value = (upperbyte & 0x7F) << 8 | (lowerbyte);

            if (sign !== 0) {
                value = -32768 + value;
            }
            return (value);
        };

        function getUInt16(lowerbyte, upperbyte) {
            var value = (upperbyte & 0xFF) << 8 | (lowerbyte);

            return (value);
        };

        function getInt32(lowerbyte, lowbyte, upbyte, upperbyte) {
            var sign = upperbyte & 0x80;
            var value = (upperbyte & 0x7F) << 24 | (upbyte << 16) | (lowbyte << 8) | (lowerbyte);

            if (sign !== 0) {
                value = -2147483648 + value;
            }
            return (value);
        };

        function isCompressed(filename) {
            var filenameSplit = filename.split('.');

            if (filenameSplit[filenameSplit.length - 1] === 'dds') {
                return(true);
            }

            return(false);
        }

        const state = new ParserState();

        var mtlFile = "";
            var imgTable = [];

            var idx = 0;

            // While not EOF
            while (idx < bin.length) {
                // Switch comand
                switch (bin.charCodeAt(idx++) & 0xFF) {
                    // L mat library
                    case 76:
                        // Size of the mat lib name
                        var matLibSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.object.startMaterial(bin.substr(idx, matLibSize), state.materialLibraries);
                        // Skip mat lib name
                        idx += matLibSize;
                        break;

                    // V vertices
                    case 86:
                        // Get vertex data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following vertices (int value)
                        var nbVertices = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all vertices to current object
                        if (dataSize === 2) {
                            for (var v = 0; v < nbVertices; v++) {
                                state.vertices.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)));
                            }
                        }
                        else {
                            for (var v = 0; v < nbVertices; v++) {
                                state.vertices.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)));
                            }
                        }
                        break;

                    // N normales
                    case 78:
                        // Get normal data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following normals (int value)
                        var nbNormales = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all normals to current object
                        if (dataSize === 2) {
                            for (var n = 0; n < nbNormales; n++) {
                                state.normals.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        else {
                            for (var n = 0; n < nbNormales; n++) {
                                state.normals.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        break;

                    // T textures
                    case 84:
                        // Get uv data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following uv coords (int value)
                        var nbTextureCoords = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all uv coords to current object
                        if (dataSize === 2) {
                            for (var t = 0; t < nbTextureCoords; t++) {
                                state.uvs.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        else {
                            for (var t = 0; t < nbTextureCoords; t++) {
                                state.uvs.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        break;

                    // S smooth
                    case 83:
                        // Retrieve smooth value and assign to current material
                        state.object.smooth = ((bin.charCodeAt(idx++) & 0xFF) !== 0);

                        var material = state.object.currentMaterial();
                        if (material) {
                            material.smooth = state.object.smooth;
                        }
                        break;

                    // O and G object start
                    case 71:
                    case 79:
                        // Size of the object name
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.startObject(bin.substr(idx, nameSize));
                        // Skip the object name
                        idx += nameSize;
                        break;

                    // M material
                    case 77:
                        // Size of the material name
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.object.startMaterial(bin.substr(idx, nameSize), state.materialLibraries);
                        // Skip material name
                        idx += nameSize;
                        break;

                    // F face
                    case 70:
                        // Get vertex data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following faces (int value)
                        var nbFaces = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all faces to current object
                        if (dataSize === 2) {
                            for (var f = 0; f < nbFaces; f++) {
                                state.addFace(
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF))
                                );
                            }
                        }
                        else {
                            for (var f = 0; f < nbFaces; f++) {
                                state.addFace(
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF))
                                );
                            }
                        }
                        break;

                    // E mtl extra file
                    case 69:
                        // Size of the mtl file
                        var mtlSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        mtlFile = bin.substr(idx, mtlSize);
                        // Skip file mtl file
                        idx += mtlSize;
                        break;

                    // I image
                    case 73:
                        // Size of the filename
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        var imgName = bin.substr(idx, nameSize);
                        // Skip file name size
                        idx += nameSize;

                        // Get image file size
                        var imgSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);
                        var data;

                        // If compressed image then create an array buffer to manipulate data later
                        if (isCompressed(imgName)) {
                            var arrayBuffer = new ArrayBuffer(imgSize);
                            var view = new Int8Array(arrayBuffer);

                            for (var i = 0 ; i < imgSize ; i++) {
                                view[i] = bin.charCodeAt(idx++) & 0xFF;
                            }
                            data = arrayBuffer;
                        } else {
                            // Else copy brutally the text with a substring
                            data = bin.substr(idx, imgSize);
                            // Skip image data
                            idx += imgSize;
                        }

                        imgTable.push({ name: imgName, data: data });
                        break;

                    default:
                        console.log('Invalid command at index ' + idx);
                }
            }

        state.finalize();

        const container = new Group();
        container.materialLibraries = [].concat( state.materialLibraries );

        const hasPrimitives = ! ( state.objects.length === 1 && state.objects[ 0 ].geometry.vertices.length === 0 );

        if ( hasPrimitives === true ) {

            for ( let i = 0, l = state.objects.length; i < l; i ++ ) {

                const object = state.objects[ i ];
                const geometry = object.geometry;
                const materials = object.materials;
                const isLine = ( geometry.type === 'Line' );
                const isPoints = ( geometry.type === 'Points' );
                let hasVertexColors = false;

                // Skip o/g line declarations that did not follow with any faces
                if ( geometry.vertices.length === 0 ) continue;

                const buffergeometry = new BufferGeometry();

                buffergeometry.setAttribute( 'position', new Float32BufferAttribute( geometry.vertices, 3 ) );

                if ( geometry.normals.length > 0 ) {

                    buffergeometry.setAttribute( 'normal', new Float32BufferAttribute( geometry.normals, 3 ) );

                }

                if ( geometry.colors.length > 0 ) {

                    hasVertexColors = true;
                    buffergeometry.setAttribute( 'color', new Float32BufferAttribute( geometry.colors, 3 ) );

                }

                if ( geometry.hasUVIndices === true ) {

                    buffergeometry.setAttribute( 'uv', new Float32BufferAttribute( geometry.uvs, 2 ) );

                }

                // Create materials

                const createdMaterials = [];

                for ( let mi = 0, miLen = materials.length; mi < miLen; mi ++ ) {

                    const sourceMaterial = materials[ mi ];
                    const materialHash = sourceMaterial.name + '_' + sourceMaterial.smooth + '_' + hasVertexColors;
                    let material = state.materials[ materialHash ];

                    if ( this.materials !== null ) {

                        material = this.materials.create( sourceMaterial.name );

                        // mtl etc. loaders probably can't create line materials correctly, copy properties to a line material.
                        if ( isLine && material && ! ( material instanceof LineBasicMaterial ) ) {

                            const materialLine = new LineBasicMaterial();
                            Material.prototype.copy.call( materialLine, material );
                            materialLine.color.copy( material.color );
                            material = materialLine;

                        } else if ( isPoints && material && ! ( material instanceof PointsMaterial ) ) {

                            const materialPoints = new PointsMaterial( { size: 10, sizeAttenuation: false } );
                            Material.prototype.copy.call( materialPoints, material );
                            materialPoints.color.copy( material.color );
                            materialPoints.map = material.map;
                            material = materialPoints;

                        }

                    }

                    if ( material === undefined ) {

                        if ( isLine ) {

                            material = new LineBasicMaterial();

                        } else if ( isPoints ) {

                            material = new PointsMaterial( { size: 1, sizeAttenuation: false } );

                        } else {

                            /**BEGIN MODIFICATIONS - MESH_PHYSICAL_MATERIAL*/
                            material = new MeshPhongMaterial({
                                color: new Color(0xffffff),
                                dithering: true
                            });
                            /**END MODIFICATIONS */

                        }

                        material.name = sourceMaterial.name;
                        material.flatShading = sourceMaterial.smooth ? false : true;
                        material.vertexColors = hasVertexColors;

                        state.materials[ materialHash ] = material;

                    }

                    createdMaterials.push( material );

                }

                // Create mesh

                let mesh;

                if ( createdMaterials.length > 1 ) {

                    for ( let mi = 0, miLen = materials.length; mi < miLen; mi ++ ) {

                        const sourceMaterial = materials[ mi ];
                        buffergeometry.addGroup( sourceMaterial.groupStart, sourceMaterial.groupCount, mi );

                    }

                    if ( isLine ) {

                        mesh = new LineSegments( buffergeometry, createdMaterials );

                    } else if ( isPoints ) {

                        mesh = new Points( buffergeometry, createdMaterials );

                    } else {

                        mesh = new Mesh( buffergeometry, createdMaterials );

                    }

                } else {

                    if ( isLine ) {

                        mesh = new LineSegments( buffergeometry, createdMaterials[ 0 ] );

                    } else if ( isPoints ) {

                        mesh = new Points( buffergeometry, createdMaterials[ 0 ] );

                    } else {

                        mesh = new Mesh( buffergeometry, createdMaterials[ 0 ] );

                    }

                }

                mesh.name = object.name;

                container.add( mesh );

            }

        } else {

            // if there is only the default parser state object with no geometry data, interpret data as point cloud

            if ( state.vertices.length > 0 ) {

                const material = new PointsMaterial( { size: 1, sizeAttenuation: false } );

                const buffergeometry = new BufferGeometry();

                buffergeometry.setAttribute( 'position', new Float32BufferAttribute( state.vertices, 3 ) );

                if ( state.colors.length > 0 && state.colors[ 0 ] !== undefined ) {

                    buffergeometry.setAttribute( 'color', new Float32BufferAttribute( state.colors, 3 ) );
                    material.vertexColors = true;

                }

                const points = new Points( buffergeometry, material );
                container.add( points );

            }

        }

        return { object: container, mtl: mtlFile, images: imgTable };

    }

}

export { BINLoader };
