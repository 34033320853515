import {
	Color,
	Vector2,
	Vector4
} from 'three';

const CelShadingShader = {

	uniforms: {

		'tDiffuse': { value: null },
		'tDepth': { value: null },
		'tNormal': { value: null },
		'resolution': { value: new Vector2() },
		'cameraNearFar': { value: new Vector2() },
		'outlineColor': { value: new Color( 0x000000 ) },
		// 4 scalar values packed in one uniform: depth multiplier, depth bias, and same for normals.
		'multiplierParameters': { value: new Vector4(0.8, 0.4, 0.9, 0.2) }

	},

	vertexShader: `

		varying vec2 vUv;

		void main() {

			vUv = uv;

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader: `

		uniform sampler2D tDiffuse;
		uniform sampler2D tDepth;
		uniform sampler2D tNormal;
		uniform vec2 resolution;
		uniform vec2 cameraNearFar;
		uniform vec3 outlineColor;
		uniform vec4 multiplierParameters;
		varying vec2 vUv;

		#include <packing>
		#include <tonemapping_pars_fragment>

		float getLinearDepth( const in vec2 screenPosition ) {
			return (2.0 * cameraNearFar.x) / ( cameraNearFar.y + cameraNearFar.x - unpackRGBAToDepth( texture2D( tDepth, screenPosition ) ) * ( cameraNearFar.y - cameraNearFar.x ) );
		}

		float getDepth( const in vec2 screenPosition ) {
			return unpackRGBAToDepth( texture2D( tDepth, screenPosition ) );
		}

		float getPixelDepth( const in vec2 screenPosition ) {
			return getDepth( screenPosition );
		}

		vec3 getPixelNormal( const in vec2 screenPosition ) {
			return texture2D( tNormal, screenPosition ).rgb;
		}

		void main() {

			vec2 texel = vec2( 1.0 / resolution.x, 1.0 / resolution.y );
			vec4 baseColor = texture2D( tDiffuse, vUv );

			float depth = getPixelDepth( vUv );
			vec3 normal = getPixelNormal( vUv );

			float depthDiff = 0.0;
			depthDiff += abs( depth - getPixelDepth( vUv + texel * vec2( -1,  0 ) ) );
			depthDiff += abs( depth - getPixelDepth( vUv + texel * vec2( 0,  -1 ) ) );
			depthDiff += abs( depth - getPixelDepth( vUv + texel * vec2( 1,  0 ) ) );
			depthDiff += abs( depth - getPixelDepth( vUv + texel * vec2( 0,  1 ) ) );

			float normalDiff = 0.0;
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( -1,  0 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( 0,  -1 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( 1,  0 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( 0,  1 ) ) );

			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( 1,  1 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( 1,  -1 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( -1,  1 ) ) );
			normalDiff += distance( normal, getPixelNormal( vUv + texel * vec2( -1,  -1 ) ) );

			// Apply multiplier & bias to each 
			float depthBias = multiplierParameters.x;
			float depthMultiplier = multiplierParameters.y;
			float normalBias = multiplierParameters.z;
			float normalMultiplier = multiplierParameters.w;

			depthDiff = depthDiff * depthMultiplier;
			depthDiff = saturate( depthDiff );
			depthDiff = pow( depthDiff, depthBias );

			normalDiff = normalDiff * normalMultiplier;
			normalDiff = saturate( normalDiff );
			normalDiff = pow( normalDiff, normalBias );

			float outline = normalDiff + depthDiff;

			vec4 outlineColor = vec4( outlineColor, 1.0 );

			gl_FragColor = vec4( mix( baseColor, outlineColor, outline ) );
		}`

};

export { CelShadingShader };
