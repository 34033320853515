import * as THREE from 'three';

var scripts = document.getElementsByTagName("script");
export var PLAN_WEBGL_MODULE_PATH : string | null = null;
for (var i = 0; i < scripts.length; ++i) {
    var s = scripts.item(i);
    if (s) {
        if (s.src && s.src.match('planWebGL.min.js')) {
            PLAN_WEBGL_MODULE_PATH = s.src.substring(0, s.src.lastIndexOf("/") + 1);
        }
    }
}
if (PLAN_WEBGL_MODULE_PATH === null) {
    PLAN_WEBGL_MODULE_PATH = 'node_modules/@rhinov/plan-webgl'
}

export var WebGLEvents = {
    RELOAD: "weglevent_reload"
};

export var MOUSE_EVENTS = {
    DOWN: 'mouse_down_event',
    UP: 'mouse_up_event',
    MOVE: 'mouse_move_event',
    DOUBLE_CLICK: 'mouse_double_click_event'
};

export var DRAG_STATE = {
    COATING: 'coating',
    ENTITY: 'entity'
};

export var CAMERA_STATES = {
    COCOS: 'cocos_camera',
    FREE: 'free_camera',
    ENTITY: 'entity_camera',
    FRONT: 'front_camera'
};

export const holdoutTexture = new THREE.TextureLoader().load(PLAN_WEBGL_MODULE_PATH + '/medias/holdout.png');
holdoutTexture.wrapS = THREE.RepeatWrapping;
holdoutTexture.wrapT = THREE.RepeatWrapping;
export let holdoutMaterial = new THREE.MeshPhongMaterial({ map: holdoutTexture, color: 0xffffff });
