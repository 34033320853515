import { Material, MeshPhongMaterial } from "three";

export class Cleaner {

    static __cleanOneMaterial(material: MeshPhongMaterial) : void {
        if (material.map) {
            if (!(material.map as any).weakRef) {
                material.map.dispose();
            } else {
                (material.map as any).weakRef.sharedRef--;
                if ((material.map as any).weakRef.sharedRef === 0) {
                    material.map.dispose();
                }
                (material.map as any).weakRef = null;
            }
        }
        if (material.normalMap) {
            if (!(material.normalMap as any).weakRef) {
                material.normalMap.dispose();
            } else {
                (material.normalMap as any).weakRef.sharedRef--;
                if ((material.normalMap as any).weakRef.sharedRef === 0) {
                    material.normalMap.dispose();
                }
                (material.normalMap as any).weakRef = null;
            }
        }
        if (material.bumpMap) {
            if (!(material.bumpMap as any).weakRef) {
                material.bumpMap.dispose();
            } else {
                (material.bumpMap as any).weakRef.sharedRef--;
                if ((material.bumpMap as any).weakRef.sharedRef === 0) {
                    material.bumpMap.dispose();
                }
                (material.bumpMap as any).weakRef = null;
            }
        }
        if (material.alphaMap) {
            if (!(material.alphaMap as any).weakRef) {
                material.alphaMap.dispose();
            } else {
                (material.alphaMap as any).weakRef.sharedRef--;
                if ((material.alphaMap as any).weakRef.sharedRef === 0) {
                    material.alphaMap.dispose();
                }
                (material.alphaMap as any).weakRef = null;
            }
        }
        material.dispose();
    }

    static cleanMaterial(material: Material | Array<Material>) : void {
        if (Array.isArray(material)) {
            for (var i = 0; i < material.length; ++i) {
                this.__cleanOneMaterial(material[i] as MeshPhongMaterial);
            }
        } else {
            this.__cleanOneMaterial(material as MeshPhongMaterial);
        }
    }

}