import * as SavaneJS from '@rhinov/savane-js';
import * as THREE from 'three';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { Cleaner } from './helpers/cleaner';
import { WebglScene } from './scene';

export class WebGLSun {

    public entity: SavaneJS.Sun;
    public object: Sky;
    public noon: boolean;

    private target: THREE.Object3D;
    private light: THREE.DirectionalLight;
    private scene: WebglScene;
    private distance: number;

    constructor(entity: SavaneJS.Sun, scene: WebglScene) {
        this.entity = entity;
        // Init all fields, WebGL sun object
        this.target = new THREE.Object3D();
        this.target.layers.enableAll();
        this.light = new THREE.DirectionalLight(0xffffff, 1.0);
        this.light.layers.enableAll();
        this.light.castShadow = true;
        this.light.shadow.normalBias = 0.4;
        this.light.shadow.mapSize.width = this.shadowMapSize();
        this.light.shadow.mapSize.height = this.shadowMapSize();
        this.light.target = this.target;
        this.object = new Sky();
        // Sun entity creating this WebGL object
        this.noon = false;

        this.scene = scene;

        this.scene.threeScene.add(this.target);
        this.scene.threeScene.add(this.light);

        //this.helper = new THREE.CameraHelper( this.light.shadow.camera );
        //scene.threeScene.add( this.helper );

        this.object.scale.setScalar(450000);
        this.object.material.uniforms["turbidity"].value = 10;
        this.object.material.uniforms["rayleigh"].value = 1;
        this.object.material.uniforms["mieCoefficient"].value = 0.01;
        this.object.material.uniforms["mieDirectionalG"].value = 0.93;
        this.object.material.uniforms["up"].value = new THREE.Vector3(0, 0, 1);
        this.distance = 400000;
        var sunPosition = new THREE.Vector3(0, 0, this.distance);
        this.object.material.uniforms["sunPosition"].value.copy(sunPosition);
        this.object.layers.enableAll();

        this.scene.threeScene.add(this.object);

        // And update the object
        this.update();
    }

    // Update sun intensity depending on outside weather
    updateIntensity() : number {
        var southDirection = new THREE.Vector3(this.entity.southDirection[0], this.entity.southDirection[1], 0);
        var angle = SavaneJS.Math.Functions.degree2radian(this.entity.altitude) - Math.PI;
        var axis = new THREE.Vector3(0, 0, 1);
        if (this.scene.camera) {
            angle += SavaneJS.Math.Functions.degree2radian(this.scene.camera.entity.sunOffsetAltitude);
            southDirection.applyAxisAngle(axis, SavaneJS.Math.Functions.degree2radian(this.scene.camera.entity.sunOffsetRotation));
        }
        axis.cross(southDirection);
        southDirection.applyAxisAngle(axis, angle);
        var intensity = Math.max(0.05, Math.abs(new THREE.Vector3(0, 0, 1).dot(southDirection)));

        switch(this.entity.weather)
        {
            // Sunny
            case 0:
                return 4 * intensity;

            // Cloudy
            case 1:
                return 2 * intensity;

            // Default = overcast
            default:
                return 1 * intensity;
        }
    }

    // Update sun color depending on time of the day
    updateColor() : THREE.Color {
        var temperature = this.entity.temperature / 100.0;
        var red, green, blue;

        if (temperature < 66.0) {
            red = 255;
        } else {
            red = temperature - 55.0;
            red = 351.97690566805693+ 0.114206453784165 * red - 40.25366309332127 * Math.log(red);
            if (red < 0) red = 0;
            if (red > 255) red = 255;
        }

        /* Calculate green */
        if (temperature < 66.0) {
            green = temperature - 2;
            green = -155.25485562709179 - 0.44596950469579133 * green + 104.49216199393888 * Math.log(green);
            if (green < 0) green = 0;
            if (green > 255) green = 255;

        } else {
            green = temperature - 50.0;
            green = 325.4494125711974 + 0.07943456536662342 * green - 28.0852963507957 * Math.log(green);
            if (green < 0) green = 0;
            if (green > 255) green = 255;
        }

        /* Calculate blue */
        if (temperature >= 66.0) {
            blue = 255;
        } else {
            if (temperature <= 20.0) {
                blue = 0;
            } else {
                blue = temperature - 10;
                blue = -254.76935184120902 + 0.8274096064007395 * blue + 115.67994401066147 * Math.log(blue);
                if (blue < 0) blue = 0;
                if (blue > 255) blue = 255;
            }
        }

        return new THREE.Color(red / 255, green / 255, blue / 255).convertSRGBToLinear();
    }

    shadowMapSize() : number {
        return 1024;
    }

    shadowRadius() : number {
        var radius = this.entity.shadowBlurRadius
        if (radius !== 0) {
            if (radius > 5 && radius <= 20) {
                var t = (radius - 5) / 15;
                return 7.5 * t + (1 - t) * 5;
            } else if (radius > 20) {
                var t = (radius - 20) / 20;
                return 10 * t + (1 - t) * 7.5;
            }
            return radius;
        } 
        else {
            switch(this.entity.weather)
            {
                // Sunny
                case 0:
                    return 5;

                // Cloudy
                case 1:
                    return 7.5;

                // Default = overcast
                default:
                    return 10;
            }
        }
    }

    // Main sun update function
    update() : void {
        // Get floor bounding box
        var bbox    = (this.entity.parent as SavaneJS.Floor).boundingBox;
        // Get top/left
        var topLeft =  new THREE.Vector3(bbox[0][0] / 100, bbox[0][1] / 100, 0);
        // and bottom/right of the floor
        var botRight = new THREE.Vector3(bbox[3][0] / 100, bbox[3][1] / 100, 0);
        // Compute floor radius and multiply it by 2 to add distance
        var radius = topLeft.distanceTo(botRight);
        if (radius === 0) {
            radius = 10;
        }
        var height = ((this.entity.parent as SavaneJS.Floor).height + 2500) / 100;
        // Get sun south direction
        var southDirection = new THREE.Vector3(this.entity.southDirection[0], this.entity.southDirection[1], 0);
        var angle = SavaneJS.Math.Functions.degree2radian(this.entity.altitude) - Math.PI;
        var axis = new THREE.Vector3(0, 0, 1);
        if (this.scene.camera) {
            angle += SavaneJS.Math.Functions.degree2radian(this.scene.camera.entity.sunOffsetAltitude);
            southDirection.applyAxisAngle(axis, SavaneJS.Math.Functions.degree2radian(this.scene.camera.entity.sunOffsetRotation));
        }
        if (this.scene.settings.interactiveProject) {
            angle = SavaneJS.Math.Functions.degree2radian(90) - Math.PI;
        }
        axis.cross(southDirection).normalize();
        southDirection.applyAxisAngle(axis, angle);
        
        // Set sun position
        this.target.position.copy(new THREE.Vector3((topLeft.x + botRight.x) / 2, (topLeft.y + botRight.y) / 2, height / 2));
        var position = new THREE.Vector3(southDirection.x * radius + (topLeft.x + botRight.x) / 2, southDirection.y * radius + (topLeft.y + botRight.y) / 2, southDirection.z * radius + height / 2);
        this.light.position.copy(position);
        this.light.shadow.camera.left = -radius;
        this.light.shadow.camera.bottom = -radius;
        this.light.shadow.camera.right = radius;
        this.light.shadow.camera.top = radius;
        this.light.shadow.camera.far = 2 * radius;
        this.light.shadow.radius = this.shadowRadius();
        this.light.shadow.camera.updateProjectionMatrix();

        var sunPosition = new THREE.Vector3((southDirection.x * this.distance + (topLeft.x + botRight.x) / 2), (southDirection.y * this.distance + (topLeft.y + botRight.y) / 2), (southDirection.z * this.distance));
        this.object.material.uniforms["sunPosition"].value.copy(sunPosition);

        this.light.intensity = this.updateIntensity();
        this.light.color = this.updateColor();

        //this.helper.update();
    }

    dispose() : void {
        this.light.dispose();
        Cleaner.cleanMaterial(this.object.material);
        this.object.geometry.dispose();
    }
}
