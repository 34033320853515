import * as SavaneJS from '@rhinov/savane-js';

export class Coating {

    static getColorFromComponent(coating: SavaneJS.Coating, color: number) : number |string {
        if (coating && coating.colors) {
            if (coating.colors.length > 0) {
                if (coating.colors[0].realColor !== undefined) {
                    return (coating.colors[0].realColor);
                }
                else {
                    if (coating.colors[0].color !== undefined && coating.colors[0].color !== null) {
                        return (coating.colors[0].color.color);
                    }
                }
            }
        }

        return (color);
    }

    static getColorFromAsset(coating: SavaneJS.Coating, color: number) : number | string {
        if (coating.colors && coating.colors.realColor) {
            return (coating.colors.realColor);
        }

        return (color);
    }

}
