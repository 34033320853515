import {
	Color,
	MeshDepthMaterial,
	NoBlending,
	RGBADepthPacking,
    RGBAFormat,
	ShaderMaterial,
	UniformsUtils,
	WebGLRenderTarget
} from 'three';
import { Pass, FullScreenQuad } from 'three/addons/postprocessing/Pass.js'
import { DepthOfFieldShader } from '../shaders/DepthOfFieldShader.js'

/**
 * Depth-of-field post-process with bokeh shader
 */

class DepthOfFieldPass extends Pass {

	constructor( scene, camera ) {

		super();

		this.scene = scene;
		this.camera = camera;

		const focalDepth = 0.0;
		const fstop = 2.8;

		// render targets

		this.renderTargetDepth = new WebGLRenderTarget( 1, 1, {
			generateMipmaps: false,
			stencilBuffer: false,
			format: RGBAFormat,
            samples: 2
		} );

		this.renderTargetDepth.texture.name = 'BokehPass.depth';

		// depth material

        this.depthMaterial = new MeshDepthMaterial();
		this.depthMaterial.depthPacking = RGBADepthPacking;
		this.depthMaterial.blending = NoBlending;

		// bokeh material

		const bokehShader = DepthOfFieldShader;
		const bokehUniforms = UniformsUtils.clone( bokehShader.uniforms );

		bokehUniforms[ 'tDepth' ].value = this.renderTargetDepth.texture;

		bokehUniforms[ 'focalDepth' ].value = focalDepth;
        bokehUniforms[ 'fstop' ].value = fstop;
        bokehUniforms[ 'maxblur' ].value = 0.75;

		this.material = new ShaderMaterial( {
			defines: Object.assign( {}, bokehShader.defines ),
			uniforms: bokehUniforms,
			vertexShader: bokehShader.vertexShader,
			fragmentShader: bokehShader.fragmentShader
		} );

        this.material.defines.RINGS = 3;
        this.material.defines.SAMPLES = 4;

		this.uniforms = bokehUniforms;
		this.needsSwap = false;

		this.fsQuad = new FullScreenQuad( this.material );

		this._oldClearColor = new Color();

	}

	render( renderer, writeBuffer, readBuffer/*, deltaTime, maskActive*/ ) {

		// Render depth into texture
		this.scene.overrideMaterial = this.depthMaterial;

		renderer.getClearColor( this._oldClearColor );
		const oldClearAlpha = renderer.getClearAlpha();
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = false;

		renderer.setClearColor( 0xffffff );
		renderer.setClearAlpha( 1.0 );
		renderer.setRenderTarget( this.renderTargetDepth );
		renderer.clear();
		renderer.render( this.scene, this.camera );

		// Render bokeh composite

		this.uniforms[ 'tColor' ].value = readBuffer.texture;
		this.uniforms[ 'znear' ].value = this.camera.near;
		this.uniforms[ 'zfar' ].value = this.camera.far;
        this.uniforms[ 'focalLength' ].value = this.camera.getFocalLength();

		if ( this.renderToScreen ) {

			renderer.setRenderTarget( null );
			this.fsQuad.render( renderer );

		} else {

			renderer.setRenderTarget( writeBuffer );
			renderer.clear();
			this.fsQuad.render( renderer );

		}

		this.scene.overrideMaterial = null;
		renderer.setClearColor( this._oldClearColor );
		renderer.setClearAlpha( oldClearAlpha );
		renderer.autoClear = oldAutoClear;

	}

	setSize( width, height ) {

		this.renderTargetDepth.setSize( width, height );
        this.uniforms[ 'textureWidth' ].value = width;
        this.uniforms[ 'textureHeight' ].value = height;

	}

	dispose() {

		this.renderTargetDepth.dispose();

		this.depthMaterial.dispose();
		this.material.dispose();

		this.fsQuad.dispose();

	}

}

export { DepthOfFieldPass };
