import * as THREE from 'three';
import { WebglScene } from '../scene';

export class Lighting {

    static disableReflectors(scene: THREE.Scene) : void {
        scene.traverse(function(item) {
            if (item.type === 'Reflector') {
                item.visible = false;
            }
        });
    }

    static enableReflectors(scene: THREE.Scene) : void {
        scene.traverse(function(item) {
            if (item.type === 'Reflector') {
                item.visible = true;
            }
        });
    }

    static updateHullEnvs(scene: WebglScene, done: CallableFunction) : void {
        if (!scene.staticHull) {
            done();
            return;
        }

        var staticHullChild = scene.staticHull.group.children[0];
        var hullChildren = staticHullChild.children;
        if (scene.dynamicHull) {
            var dynamicHullChild = scene.dynamicHull.group.children[0];
            hullChildren = hullChildren.concat(dynamicHullChild.children);
        }

        hullChildren = hullChildren.filter(function(item) {
            return !!(item as any).cubeCamera;
        });

        if (hullChildren.length === 0) {
            done();
            return;
        }
        
        for (var i = 0; i < hullChildren.length; ++i) {
            var child = hullChildren[i];

            scene._idleHullTimeouts.push(setTimeout(function(child) {
                if (!scene._idleTimeout) {
                    return;
                }
                var last = child === hullChildren[hullChildren.length - 1];
                var visibility = child.visible;
                child.visible = false;
                child.cubeCamera.renderTarget.clear(scene.renderer);
                child.cubeCamera.update(scene.renderer, scene.threeScene);
                child.visible = visibility;
                if (last) {
                    done();
                }
            }.bind(this, child), 0));
        }
    }
}
