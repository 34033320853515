import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { WebglScene } from './scene';
import { Material } from './helpers/material';
import { Cleaner } from './helpers/cleaner';
import { PLAN_WEBGL_MODULE_PATH } from '../constants';
import { OBJLoader } from './obj/OBJLoader';
import { MTLLoader } from './obj/MTLLoader';
import { BINLoader } from './obj/BINLoader';

declare let Savane;
declare let AssetManagerServices;
declare var InstallTrigger;
declare var EntityManager;

export class WebglFurniture {

    public entity: SavaneJS.ArrangementObject;
    public object: THREE.Group;
    public probe: THREE.LightProbe | null = null;
    public startManipulation: boolean = false;
    public statistics = {triangles: 0};

    private scene: WebglScene;
    private material: THREE.Material | null = null;
    private defaultMesh: THREE.Mesh | null = null;
    // Will store the resulting 3D model loaded by the loader, for the moment null as the model isn't loaded in memory
    private model: THREE.Group | null = null;
    private wireframeGeometries: Array<THREE.Mesh> = [];
    private mtl: string | null = null;
    private images: Array<{ name: string, data: ArrayBuffer | string}> | null = null;
    private objLoader: OBJLoader;
    private binLoader: BINLoader;
    private mtlLoader: MTLLoader;

    constructor(entity: SavaneJS.ArrangementObject, config: string, lod: string, objLoader: OBJLoader, binLoader: BINLoader, scene: WebglScene, loaded?: CallableFunction) {
        // Loader that will allow the object to be loaded (can be changed by another leader to enable new possibilities like texture models, this one allows only flat models and doesn't load textures)
        this.objLoader = objLoader;
        this.binLoader = binLoader;
        
        // This stores the savane entity to be able to apply changes performed in the WebGL view to the original object
        this.entity = entity;
        // Store scene to updateEnv when object is loaded
        this.scene = scene;
    
        // Create the webGL object
        this.object = new THREE.Group();
        this.object.userData = { id: this.entity.id };
    
        // Create default mesh geometry based on the size of the object (i.e. a simple bounding box)
        var geometry = new THREE.BoxGeometry(
            entity.originalLength ? entity.originalLength / 100 : entity.length / 100,
            entity.originalWidth ? entity.originalWidth / 100 : entity.width / 100,
            entity.originalHeight ? entity.originalHeight / 100 : entity.height / 100);
        // Default mesh material to apply, a simple colored material
        this.material = new THREE.MeshPhongMaterial(
            {
                color: new THREE.Color(0xff33ff).convertSRGBToLinear(),
                dithering: true,
                opacity: scene.settings.interactiveProject ? 0 : 1,
                transparent: scene.settings.interactiveProject ? true : false,
            });
        // Create default mesh based on the geometry and material created above
        this.defaultMesh = new THREE.Mesh(geometry, this.material);
        this.defaultMesh.userData = { id: this.entity.id };
        // The default object will cast and receive shadows
        this.defaultMesh.castShadow = true;
        this.defaultMesh.receiveShadow = true;
        // And link the mesh to it
        this.object.add(this.defaultMesh);
        //Load objet if a entity identifier exists
        if (this.entity.objectId !== null) {
            // Get asset manager base url
            var configNb = 1;
            if (this.entity.colorId !== undefined) {
                configNb = this.entity.colorId;
            }
            var vlpPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + configNb + '/';
            var lpPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/lp_' + configNb + '/';
            var uvlpPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/uvlp_' + configNb + '/';
            var hdPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + configNb + '/';
            var basePath = vlpPath;
    
            if (config) {
                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + config + '/';
            }
            if (this.scene.settings.meshLevel === 2) {
                if (config) {
                    lpPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/lp_' + config + '/';
                }
                basePath = lpPath;
            } else if (this.scene.settings.meshLevel === 0) {
                if (config) {
                    uvlpPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/uvlp_' + config + '/';
                }
                basePath = uvlpPath;
            } else if (this.scene.settings.meshLevel === 3) {
                if (config) {
                    hdPath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + config + '/';
                }
                basePath = hdPath;
            } else if (config && lod) {
                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/tmp/' + config + '/' + lod + '/';
            }
    
            // TO ACTIVATE / DEACTIVATE BELOW TO ACTIVATE/DEACTIVATE .bin loading
            var supportWebp = false;
    
            // Chrome
            if ((window as any).chrome) {
                supportWebp = true;
            }
            // Firefox
            if (typeof InstallTrigger !== 'undefined') {
                supportWebp = true;
            }
            // App electron (i.e. designerApp)
            if (navigator.userAgent.toLowerCase().indexOf('electron') > -1) {
                supportWebp = true;
            }
    
            var supportDds = false;
            /*if (this.scene.renderer.extensions.get('WEBGL_compressed_texture_s3tc') !== null) {
                supportDds = true;
            }*/
    
            var xhr = new XMLHttpRequest();
            if (supportDds) {
                xhr.open('GET', basePath + "objectdds.bin", true);
            }
            else{
                if (supportWebp) {
                    xhr.open('GET', basePath + "objectwebp.bin", true);
                }
                else {
                    xhr.open('GET', basePath + "objectjpg.bin", true);
                }
            }
    
            // Hack to pass bytes through unprocessed.
            xhr.overrideMimeType('text/plain; charset=x-user-defined');
    
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        var data = xhr.responseText;
    
                        if (this.binLoader === null) {
                            return;
                        }
    
                        var loadedStruct = this.binLoader.parse(data);
                        this.model = loadedStruct.object;
                        this.mtl = loadedStruct.mtl;
                        this.images = loadedStruct.images;
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
    
                        this.loadTexturesAndAddToRenderer(basePath, loaded);
                    }
                    else {
                        if (supportDds && supportWebp) {
                            var xhrWebp = new XMLHttpRequest();
    
                            xhrWebp.open('GET', basePath + "objectwebp.bin", true);
    
                            // Hack to pass bytes through unprocessed.
                            xhrWebp.overrideMimeType('text/plain; charset=x-user-defined');
    
                            // Callback called after the object is read
                            xhrWebp.onreadystatechange = () => {
                                // Wait for the request to be fully completed
                                if (xhrWebp.readyState == 4) {
                                    if (xhrWebp.status == 200) {
                                        var data = xhrWebp.responseText;
    
                                        if (this.binLoader === null) {
                                            return;
                                        }
    
                                        var loadedStruct = this.binLoader.parse(data);
                                        this.model = loadedStruct.object;
                                        this.mtl = loadedStruct.mtl;
                                        this.images = loadedStruct.images;
                                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
    
                                        this.loadTexturesAndAddToRenderer(basePath, loaded);
                                    }
                                    else {
                                        // TO ACTIVATE / DEACTIVATE ABOVE TO ACTIVATE/DEACTIVATE .bin loading
                                        // Request the VLP (very low polygon) from the asset manager
                                        var xhttpObj = new XMLHttpRequest();
                                        // Object path in the asset manager
                                        basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + configNb + '/';
                                        if (config && lod) {
                                            basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/tmp/' + config + '/' + lod + '/';
                                        } else if (config) {
                                            basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + config + '/';
                                        }
                                        if (this.scene.settings.meshLevel === 3) {
                                            basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + configNb + '/';
                                            if (config) {
                                                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + config + '/';
                                            }
                                        }
                                        xhttpObj.open("GET", basePath + "object.obj", true);
    
                                        // Callback called after the object is read
                                        xhttpObj.onreadystatechange = function() {
                                            // Wait for the request to be fully completed
                                            if (xhttpObj.readyState == 4) {
                                                // Verify request succeeded
                                                if (xhttpObj.status == 200) {
                                                    // Get object data in text format
                                                    var data = xhttpObj.responseText;
    
                                                    // Object already released, don't try to load its data and material
                                                    if (this.objLoader === null) {
                                                        return;
                                                    }
    
                                                    // Parse the object data and store the result
                                                    this.model = this.objLoader.parse(data);
                                                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
    
                                                    this.loadTexturesAndAddToRenderer(basePath, loaded);
                                                }
                                            }
                                        }.bind(this);
    
                                        // Send the HTTP request to get the object
                                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
                                        xhttpObj.send();
                                        // TO ACTIVATE / DEACTIVATE BELOW TO ACTIVATE/DEACTIVATE .bin loading
                                    }
                                }
                            }
    
                            xhrWebp.send();
                        }
                        else {
                            // TO ACTIVATE / DEACTIVATE ABOVE TO ACTIVATE/DEACTIVATE .bin loading
                            // Request the VLP (very low polygon) from the asset manager
                            var xhttpObj = new XMLHttpRequest();
                            // Object path in the asset manager
                            basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + configNb + '/';
                            if (config && lod) {
                                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/tmp/' + config + '/' + lod + '/';
                            } else if (config) {
                                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/vlp_' + config + '/';
                            }
                            if (this.scene.settings.meshLevel === 3) {
                                basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + configNb + '/';
                                if (config) {
                                    basePath = AssetManagerServices.getMediaArrangementUrl() + this.entity.objectId + '/medias/hd_' + config + '/';
                                }
                            }
                            xhttpObj.open("GET", basePath + "object.obj", true);
    
                            // Callback called after the object is read
                            xhttpObj.onreadystatechange = () => {
                                // Wait for the request to be fully completed
                                if (xhttpObj.readyState == 4) {
                                    // Verify request succeeded
                                    if (xhttpObj.status == 200) {
                                        // Get object data in text format
                                        var data = xhttpObj.responseText;
    
                                        // Object already released, don't try to load its data and material
                                        if (this.objLoader === null) {
                                            return;
                                        }
    
                                        // Parse the object data and store the result
                                        this.model = this.objLoader.parse(data);
                                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
    
                                        this.loadTexturesAndAddToRenderer(basePath, loaded);
                                    }
                                }
                            };
    
                            // Send the HTTP request to get the object
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);
                            xhttpObj.send();
                            // TO ACTIVATE / DEACTIVATE BELOW TO ACTIVATE/DEACTIVATE .bin loading
                        }
                    }
                }
            };
    
            xhr.send();
            // TO ACTIVATE / DEACTIVATE ABOVE TO ACTIVATE/DEACTIVATE .bin loading
        }
    
        // Final update of the WebGL object after it is read
        this.update();
    }

    loadTexturesAndAddToRenderer(basePath: string, loaded: CallableFunction) : void {
        if (this.object === null || this.model === null) {
            // object is disposed before callback is called
            return;
        }
        // WebGL scale is 1/100th of VLP format scale, set it
        this.model.scale.x /= 100;
        this.model.scale.y /= 100;
        this.model.scale.z /= 100;
        // The object will cast and create shadows
        this.model.castShadow = true;
        this.model.receiveShadow = true;

        // Function that will allow to parse all the sub-objects if necessary to set the light shadowing mode
        var traversing = function(child) {
            child.castShadow = true;
            child.receiveShadow = true;
            if (this.object && child.isMesh) {
                child.name += '_' + this.object.name;
                this.statistics.triangles += child.geometry.attributes.position.count / 3;
            }
        }.bind(this);
        // Go through all sub-objects if any
        this.model.traverse(traversing.bind(self));
        // Add loaded model to current objet so it can be added to the scene
        this.object.add(this.model);
        // Set visibility
        this.scene.setLayer(this.model, this.object.layers.mask >> 1 | 0);
        // Normal model is loaded in memory, remove the defautl one as it is not necessary
        if (this.defaultMesh) {
            this.object.remove(this.defaultMesh);
        }
        // Store model user data (i.e. entity id that will be useful to retrieve the object when doing raycasting collision)
        this.model.userData = { id: this.entity.id };
        // update matrices
        this.model.updateMatrixWorld(true);

        // Create a MTL loade to load textures
        this.mtlLoader = new MTLLoader();
        this.mtlLoader.crossOrigin = 'anonymous';
        // Set MTL and JPG path to load textures
        this.mtlLoader.setPath(basePath);
        this.mtlLoader.setResourcePath(basePath);

        // Biggest dimensions will be in dim1 and dim2
        var dim1 = this.entity.length;
        var dim2;

        // Get biggest dimensions from length, with and height
        if (this.entity.width > dim1) {
            dim2 = dim1;
            dim1 = this.entity.width;
        }
        else {
            dim2 = this.entity.width;
        }
        if (this.entity.height > dim2) {
            dim2 = this.entity.height;
        }

        this.updateCoating();
        if (loaded) {
            loaded(this);
        }
    }

    update() : void {
        if (this.object === null) {
            return;
        }

        // Update THREE matrix based on savane matrix datas
        var localMatrix = this.entity.transform.localMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(localMatrix[0], localMatrix[1], localMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(localMatrix[4], localMatrix[5], localMatrix[6]).normalize();
        var Z = new THREE.Vector3().set(localMatrix[8], localMatrix[9], localMatrix[10]).normalize();

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);

        var scaling = SavaneJS.math.vec3.create();
        SavaneJS.math.mat4.getScaling(scaling, localMatrix);

        if (this.entity.symmetry === true) {
            scaling[0] = -scaling[0];
        }

        var dummy = new THREE.Vector3();
        var quaternion = new THREE.Quaternion();
        basis.decompose(dummy, quaternion, dummy);

        this.object.quaternion.copy(quaternion);
        this.object.position.set(localMatrix[12] / 100, localMatrix[13] / 100, localMatrix[14] / 100);
        this.object.scale.set(scaling[0], scaling[1], scaling[2]);

        var traversing = function(child) {
            if (child.body) {
                child.body.needUpdate = true;
            }
        }
        this.object.traverse(traversing);
    }

    mtlLoaded(loader: MTLLoader) : void {
        // Object already released, don't try to load its materials
        if (this.model === null) {
            return;
        }

        let side: THREE.Side = THREE.FrontSide;
        if (this.entity.objectType === "5bc09cf1be6c755c1fb2d60d" || this.entity.objectType == "5bc09b5fbe6c755c1fb2d607") {
            side = THREE.DoubleSide;
        }

        // Preload textures
        loader.textureLoaded = function() {
            this.scene.updateEnvs();
            if (!this.scene.settings.interactiveProject || this.scene.loaded) {
                this.scene.render();
            }
        }.bind(this);
        loader.preload(this.images);

        // Get material list from loader
        var listMaterials = Object.keys(loader.materials).map(function(e) {
            return loader.materials[e];
        });

        // Entity coatings
        var coatings = this.entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;

        // Parse all entity coatings to remove the ones that don't belong to customizations
        for (var i = coatings.length - 1; i >= 0; i--) {
            if (coatings[i].hangType !== SavaneJS.Coating.HangType.arrangementObject) {
                coatings.splice(i, 1);
            }
        }

        // Reassign material with texture onto the mesh children
        for (var i = 0; i < this.model.children.length; ++i) {
            var mesh = this.model.children[i] as THREE.Mesh;
            // Current child material name (created by OBJ loader)
            var materialName = (Array.isArray(mesh.material) ? mesh.material[0].name : mesh.material.name);
            // Sub object name
            var subObjectName = mesh.name;

            // Do we have customizations into this object ?
            if (this.entity.customization) {
                // Not found yet
                let idCoating: string = '';

                for (let j = 0 ; j < this.entity.customization.parts.length ; j++) {
                    if (subObjectName.includes(this.entity.customization.parts[j].name)) {
                        idCoating = this.entity.customization.parts[j].id_coating;
                        break;
                    }
                }

                if (idCoating === '') {
                    if (Array.isArray(mesh.material)) {
                        for (var k = 0; k < mesh.material.length; ++k) {
                            for (var j = 0; j < listMaterials.length; j++) {
                                // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                                if (listMaterials[j].name === mesh.material[k].name.trim()) {
                                    Cleaner.cleanMaterial(mesh.material[k]);
                                    listMaterials[j].used = true;
                                    mesh.material[k] = listMaterials[j];
                                    mesh.material[k].side = side;
                                    this.scene.updateEnvs();
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        // Parse materials loaded by MTL loader
                        for (var j = 0; j < listMaterials.length; j++) {
                            // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                            if (listMaterials[j].name === materialName.trim()) {
                                Cleaner.cleanMaterial(mesh.material);
                                listMaterials[j].used = true;
                                mesh.material = listMaterials[j] as THREE.Material;
                                mesh.material.side = side;
                                this.scene.updateEnvs();
                                break;
                            }
                        }
                    }
                }
                else {
                    Material.setMaterialFromCoating(mesh, {coatingId: idCoating}, false, 1, this.scene, !this.scene.settings.interactiveProject, null);
                }
            }
            if (this.entity.coatingAllowed) {
                // Not found yet
                let found = false;

                // Not found, apply default material normally
                if (coatings.length === 0) {
                    if (Array.isArray(mesh.material)) {
                        for (var k = 0; k < mesh.material.length; ++k) {
                            for (var j = 0; j < listMaterials.length; j++) {
                                // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                                if (listMaterials[j].name === mesh.material[k].name.trim()) {
                                    Cleaner.cleanMaterial(mesh.material[k]);
                                    listMaterials[j].used = true;
                                    mesh.material[k] = listMaterials[j];
                                    mesh.material[k].side = side;
                                    this.scene.updateEnvs();
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        // Parse materials loaded by MTL loader
                        for (var j = 0; j < listMaterials.length; j++) {
                            // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                            if (listMaterials[j].name === materialName.trim()) {
                                Cleaner.cleanMaterial(mesh.material);
                                listMaterials[j].used = true;
                                mesh.material = listMaterials[j] as THREE.Material;
                                mesh.material.side = side;
                                this.scene.updateEnvs();
                                break;
                            }
                        }
                    }
                }
                else {
                    // To activate when we succeed in texturing kitchen elements
                    Material.setMaterialFromCoating(mesh, coatings[0], true, 1, this.scene, !this.scene.settings.interactiveProject, null);
                }
            } else {
                if (Array.isArray(mesh.material)) {
                    for (var k = 0; k < mesh.material.length; ++k) {
                        for (var j = 0; j < listMaterials.length; j++) {
                            // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                            if (listMaterials[j].name === mesh.material[k].name.trim()) {
                                Cleaner.cleanMaterial(mesh.material[k]);
                                listMaterials[j].used = true;
                                mesh.material[k] = listMaterials[j];
                                mesh.material[k].side = side;
                                break;
                            }
                        }
                    }
                } else {
                    // Parse materials loaded by MTL loader
                    for (var j = 0; j < listMaterials.length; j++) {
                        // Find the MTL loader material corresponding to the OBJ material and assign it to the object
                        if (listMaterials[j].name === materialName.trim()) {
                            Cleaner.cleanMaterial(mesh.material);
                            listMaterials[j].used = true;
                            mesh.material = listMaterials[j] as THREE.Material;
                            mesh.material.side = side;
                            break;
                        }
                    }
                }
            }
        }

        for (var i = 0; i < listMaterials.length; ++i) {
            if (!listMaterials[i].used) {
                Cleaner.cleanMaterial(listMaterials[i]);
            }
        }

        if (this.scene.settings.interactiveProject) {
            if (this.entity.hidden && this.object) {
                this.object.traverse(function(child) {
                    if (child instanceof THREE.Mesh) {
                        child.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
                            if ((material as any).wasTransparent === undefined && material.transparent) {
                                (material as any).wasTransparent = material.transparent;
                                (material as any).initialOpacity = material.opacity;
                            } else {
                                (material as any).wasTransparent = false;
                            }

                            material.transparent = true;
                            material.opacity = 0.3;
                        }
                    }
                });
            }
        }

        this.scene.updateEnvs();
        if (!this.scene.settings.interactiveProject) {
            this.scene.render();
        }
    }

    updateCoating() : void {
        if (!this.mtlLoader) {
            return;
        }

        this._deleteWireframe();

        Savane.eventsManager.instance.dispatch(SavaneJS.Events.STOP_UPDATING_ENVS);

        // TO ACTIVATE / DEACTIVATE BELOW TO ACTIVATE/DEACTIVATE .bin loading
        if (this.mtl && this.mtl !== "") {
            this.mtlLoaded(this.mtlLoader.parse(this.mtl, undefined));
        }
        else {
            // TO ACTIVATE / DEACTIVATE ABOVE TO ACTIVATE/DEACTIVATE .bin loading
            this.mtlLoader.load("object.mtl", function(loader) {
                this.mtlLoaded(loader);
            }.bind(this));
            // TO ACTIVATE / DEACTIVATE BELOW TO ACTIVATE/DEACTIVATE .bin loading
        }
        // TO ACTIVATE / DEACTIVATE ABOVE TO ACTIVATE/DEACTIVATE .bin loading
    }

    /**
     * Apply back a change performed in the webGL view into the savane entity
     **/
    applyToEntity(mode: string, space: string) : void {
        if (!this.object) return;
        // Generate array
        if (mode === 'rotate') {
            
            // remove potential symetry
            var position = new THREE.Vector3(), scale = new THREE.Vector3(), quaternion = new THREE.Quaternion();
            this.object.matrix.decompose(position, quaternion, scale);
            scale.x = Math.abs(scale.x);
            this.object.matrix.compose(position, quaternion, scale);

            var array: Array<number> = [];
            this.object.matrix.toArray(array);
            if (space === 'local') {
                array[12] = this.entity.localPosition[0] / 100;
                array[13] = this.entity.localPosition[1] / 100;
                array[14] = this.entity.localPosition[2] / 100;
            }
            var savaneMatrix = SavaneJS.math.mat4.create();
            SavaneJS.math.mat4.set(savaneMatrix,
                array[0], array[1], array[2], array[3],
                array[4], array[5], array[6], array[7],
                array[8], array[9], array[10], array[11],
                array[12] * 100, array[13] * 100, array[14] * 100, array[15]);
            this.entity.transform.localMatrix = savaneMatrix;
        }
        else if (mode === 'translate') {
            var array: Array<number> = [];
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        } else {
            var scale = this.object.scale;
            if (this.entity.symmetry === true) {
                scale.x = Math.abs(scale.x);
            }
            var newScale = new THREE.Vector3().copy(scale);
            if (this.entity.stretchability != undefined) {
                if (this.entity.stretchability.x !== undefined && this.entity.stretchability.x.isStretchable) {
                    this.entity.length = Math.min(Math.max(this.entity.originalLength * Math.abs(scale.x), this.entity.stretchability.x.min), this.entity.stretchability.x.max);
                    newScale.x = this.entity.length / this.entity.originalLength;
                } else {
                    newScale.x = 1;
                }
                if (this.entity.stretchability.y !== undefined && this.entity.stretchability.y.isStretchable) {
                    this.entity.width = Math.min(Math.max(this.entity.originalWidth * scale.y, this.entity.stretchability.y.min), this.entity.stretchability.y.max);
                    newScale.y = this.entity.width / this.entity.originalWidth;
                } else {
                    newScale.y = 1;
                }
                if (this.entity.stretchability.z !== undefined && this.entity.stretchability.z.isStretchable) {
                    this.entity.height = Math.min(Math.max(this.entity.originalHeight * scale.z, this.entity.stretchability.z.min), this.entity.stretchability.z.max);
                    newScale.z = this.entity.height / this.entity.originalHeight;
                } else {
                    newScale.z = 1;
                }
            } else {
                newScale.x = this.entity.transform.localScale[0];
                newScale.y = this.entity.transform.localScale[1];
                newScale.z = this.entity.transform.localScale[2];
            }
            this.entity.transform.localScale = [newScale.x, newScale.y, newScale.z];
            var array: Array<number> = [];
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        }
        // Cancel anchor
        this.entity.isAnchorActive = false;

        if (typeof EntityManager !== 'undefined') {
        var node = EntityManager.getNode(this.entity);
            if (node !== null) {
                node.needRedraw = true;
            }
        }
        this.update();
    }

    enableCollision(scale: boolean) : void {
        if (!this.object) return;

        var traversing = function(child) {
            if (!child.isMesh || !child.name) return;
            if (scale && child.body) {
                this.scene.physics.destroy(child.body);
            }
            if (child.body) return;
            this.scene.physics.add.existing(child, { shape: 'mesh' });
            child.body.setCollisionFlags(2);
            var box = new THREE.Box3().setFromObject(child);
            var size = new THREE.Vector3();
            box.getSize(size);
            var radius = Math.max(size.x, Math.max(size.y, size.z));
            child.body.setCcdMotionThreshold(radius);
            child.body.setCcdSweptSphereRadius(radius / 5);
            child.body.setCollisionFlags(6);
            child.body.on.collision(function(otherObject, event) {
                if (!this.object) return;
                if (this.object.getObjectById(otherObject.id)) {
                    return;
                }
                if (this.startManipulation && otherObject.body) {
                    this.scene.physics.destroy(otherObject.body);
                    otherObject.ignoreCollisions = true;
                    return;
                }
                if (event === 'start' || event === 'collision') {
                    this.scene.detachSelection();
                    this.update();
                    this.scene.attachSelection();
                }
            }.bind(this));
        }.bind(this);

        this.object.traverse(traversing);
    }

    _deleteWireframe() : void {
        for (var i = 0; i < this.wireframeGeometries.length; ++i) {
            this.wireframeGeometries[i].removeFromParent();
            this.wireframeGeometries[i].geometry.dispose();
            (this.wireframeGeometries[i].material as THREE.Material).dispose();
        }
        this.wireframeGeometries = [];
    }

    wireframe() : void {
        if (!this.object) return;

        this.updateCoating();
        this.object.traverse(function(child) {
            if (child.keepMaterial) { return; }
            if (child.isMesh) {
                var geo = new THREE.WireframeGeometry(child.geometry);
                var mat = new THREE.LineBasicMaterial( { color: 0xffffff } );
                var wireframe = new THREE.LineSegments( geo, mat );
                wireframe.layers.mask = this.object.layers.mask;
                child.add(wireframe);
                this.wireframeGeometries.push(wireframe);
                this.scene.render();
            }
        }.bind(this));
    }

    normal() : void {
        if (!this.object) return;

        this._deleteWireframe();
        this.object.traverse(function(child) {
            if (child.keepMaterial) { return; }
            if (child.material) {
                if (Array.isArray(child.material)) {
                    for (var i = 0; i < child.material.length; ++i) {
                        var materialName = child.material[i].name;
                        Cleaner.cleanMaterial(child.material[i]);
                        child.material[i] = new THREE.MeshNormalMaterial();
                        child.material[i].name = materialName;
                    }
                } else {
                    var materialName = child.material.name;
                    Cleaner.cleanMaterial(child.material);
                    child.material = new THREE.MeshNormalMaterial();
                    child.material.name = materialName;
                }
                this.scene.render();
            }
        }.bind(this));
    }

    uvs(texture: string) : void {
        this._deleteWireframe();
        new THREE.TextureLoader().load(PLAN_WEBGL_MODULE_PATH + '/medias/' + texture + '.jpg', function(texture) {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;

            this.object.traverse(function(child) {
                if (child.keepMaterial) { return; }
                if (child.material) {
                    if (Array.isArray(child.material)) {
                        for (var i = 0; i < child.material.length; ++i) {
                            var materialName = child.material[i].name;
                            Cleaner.cleanMaterial(child.material[i]);
                            child.material[i] = new THREE.MeshBasicMaterial( { map:texture } );
                            child.material[i].name = materialName;
                        }
                    } else {
                        var materialName = child.material.name;
                        Cleaner.cleanMaterial(child.material);
                        child.material = new THREE.MeshBasicMaterial( { map:texture } );
                        child.material.name = materialName;
                    }
                    this.scene.render();
                }
            }.bind(this));
        }.bind(this));
    }

    // Free THREE object memory explicitely
    dispose() : void {
        if (this.object) {
            // dispose GL resources
            this.object.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.geometry.dispose();
                    Cleaner.cleanMaterial(child.material);
                    if ((child as any).body) {
                        this.scene.physics.destroy((child as any).body);
                    }
                }
            });
        }

        // Free the 3d model
        this.model = null;

        if (this.defaultMesh) {
            // Free the default mesh object memory
            // Geometry
            this.defaultMesh.geometry.dispose();
            // And material
            Cleaner.cleanMaterial(this.defaultMesh.material);
            // And the object itself
            this.defaultMesh = null;
        }

        // And material
        this.material = null;
        this.mtl = null;
        this.images = null;
    }
}
