import * as THREE from "three";
import * as SavaneJS from '@rhinov/savane-js';
import { WebglScene } from "../webgl/scene";
import { InteractiveProjectHandler } from "../configurator/interactiveProjectHandler";
import { WebglFurniture } from "../webgl/furniture";
import { WebglSettings } from "../webgl/settings";
import { CAMERA_STATES, DRAG_STATE, MOUSE_EVENTS, PLAN_WEBGL_MODULE_PATH, WebGLEvents } from "../constants";
import { PhysicsLoader } from 'enable3d';
import async from 'async-es';
import { WebglCamera } from "../webgl/camera";

declare let Savane;
declare let AssetManagerServices;
declare let PlanManager;
declare let CocosUtils;
declare let EntityManager;
declare let HP_CONFIG;

export class WebGLController {

    public displayCurrentFloor: boolean = parseInt(SavaneJS.SavaneCookie.getCookie("Rhinov-WebGL-displayCurrentFloor", "0")) === 0 ? false : true;
    public displayPhoto: boolean;

    private _jsonScene: any;
    private _scene: SavaneJS.Scene;
    private _webglScene: WebglScene | null = null;
    private _activeCameraId: number | null = null;
    private _interactiveProjectHandler: InteractiveProjectHandler | null = null;
    private _loadedEntity: SavaneJS.ArrangementObject | Array<SavaneJS.ArrangementObject> | null = null;
    private _entity: string;
    private _entities: Array<{
        id: string,
        config: any,
        lod: any;
    }>;
    private _config: string | number;
    private _lod: string | number;
    private _imgProject: Array<any>;
    private _photoFromCurrentProjectNb: number;
    private _leftPanel: boolean = false;
    private _deliverable: any;
    private _deliverableId: number;
    private _user: any;
    private _controls: boolean = true;
    private _useCameraRatio: boolean = true;
    private _noFocus: boolean = false;
    private _settings: WebglSettings;
    private _cameraState: string = CAMERA_STATES.FREE;
    private _displayWarning: boolean = false;
    private _bPressed: boolean = false;
    private _vPressed: boolean = false;
    private _loaded: boolean = false;

    private _delegate: {
        render: CallableFunction,
    };

    private divWebgl: HTMLElement;
    private _zoom: number;
    private _thirdGrid: boolean;
    private _staticHullDatas: any;
    private _dynamicHullDatas: any;
    private _floorGeneratorHullDatas: any;
    private _cameraHeight: string;
    private _cameraFocal: string;
    private _photoOpacity: number;
    private _transformOrigin: string;
    private _loadedHullCount: number;
    private _staticHullCanceller: AbortController;
    private _floorGeneratorCanceller: AbortController;
    private _dynamicHullCanceller: AbortController;
    private _videoPreviewNbUnhiddenObjects: any;
    private _videoPreviewCurrentImage: any;
    private _videoPreviewIntervalId: NodeJS.Timeout | undefined;
    private _enable_additionals_afters: boolean;

    private bindedElement: HTMLElement;
    private mousedownHandler: (this: HTMLElement, ev: MouseEvent) => any;
    private dblclickHandler: (this: HTMLElement, ev: MouseEvent) => any;;
    private mouseupHandler: (this: HTMLElement, ev: MouseEvent) => any;;
    private mousemoveHandler: (this: HTMLElement, ev: MouseEvent) => any;;
    private mouseleaveHandler: (this: HTMLElement, ev: MouseEvent) => any;;
    private mouseoverHandler: (this: HTMLElement, ev: MouseEvent) => any;;
    private wheelHandler: (this: HTMLElement, ev: WheelEvent) => any;;
    private keydownHandler: (this: Document, ev: KeyboardEvent) => any;
    private keyupHandler: (this: Document, ev: KeyboardEvent) => any;
    private currentKeyPressed: number | null;
    private _mouseOver: any;
    private coatingRotationListener: SavaneJS.Listener;
    private coatingDragListener: SavaneJS.Listener;
    private entityDragListener: SavaneJS.Listener;
    private setFilters: SavaneJS.Listener;
    private assetsUpdated: SavaneJS.Listener;
    private stopEnvUpdate: SavaneJS.Listener;
    private updateEnvs: SavaneJS.Listener;
    private computeCameraShoppingList: SavaneJS.Listener;
    private planStateChangedListener: SavaneJS.Listener;
    private projectLoaded: SavaneJS.Listener;
    private projectUpdated: SavaneJS.Listener;
    private refreshListener: SavaneJS.Listener;
    private hullLoadedListener: SavaneJS.Listener;
    private actionCountChange: SavaneJS.Listener;
    private floorListener: SavaneJS.Listener;
    private reloadScene: SavaneJS.Listener;
    private fieldEditChange: SavaneJS.Listener;
    private resizeListener: SavaneJS.Listener;
    private sceneUpdated: SavaneJS.Listener;
    private fullscreenListener: SavaneJS.Listener;
    private displaySizeChanged: SavaneJS.Listener;
    private updateArrangementCoating: SavaneJS.Listener;
    private floorGeneratorChanged: SavaneJS.Listener;
    private floorGeneratorUpdated: SavaneJS.Listener;
    private frontViewFloorGeneratorChanged: SavaneJS.Listener;
    private hideAxo: SavaneJS.Listener;
    private hideArrangements: SavaneJS.Listener;
    private textFieldListener: SavaneJS.Listener;
    private textFieldEndListener: SavaneJS.Listener;
    private _transformOriginMouseDown: number[];
    private _transformOriginOffset: number[];
    private _dragState: {state: string, data: any, value: any} | null = null;
    private _mouseDownEvent: any;
    private _mouseMoveEvent: any;
    
    private updateFloorGeneratorTimeout: NodeJS.Timeout | null = null;
    private updateDynamicHullTimeout: NodeJS.Timeout | null = null;
    private editRenderCameraCommandTimeout: NodeJS.Timeout | null = null;
    private cocosCameraDragListener: SavaneJS.Listener;
    private cancelFloorGeneratorChanged: SavaneJS.Listener;

    // BEGIN: getters and setters

    get jsonScene() : any {
        return this._jsonScene;
    }

    set jsonScene(newValue: any) {
        const oldValue = this._jsonScene;
        this._jsonScene = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (!this._jsonScene) {
                return;
            }

            if (this._scene) {
                this._scene = SavaneJS.JSONImporter.importScene(this._jsonScene);

                if (!this._webglScene) {
                    this._init();
                }

                this._getHulls();
                if (this._webglScene) {
                    this._webglScene.updateScene(this._scene);
                }
            }
        }
    }

    get activeCameraId() : number {
        return this._activeCameraId;
    }

    set activeCameraId(newValue: number) {
        const oldValue = this._activeCameraId;
        this._activeCameraId = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (newValue !== undefined && this._webglScene) {
                this._webglScene.updateCamera(newValue);
                if (this._interactiveProjectHandler) {
                    this._interactiveProjectHandler.UpdateCamera();
                }
                if (this._loadedEntity) {
                    if (Array.isArray(this._loadedEntity)) {
                        for (let i = 0; i < this._loadedEntity.length; ++i) {
                            this._webglScene.fitCamera(this._loadedEntity[i], i);
                        }
                    } else {
                        this._webglScene.fitCamera(this._loadedEntity, 0);
                    }
                }
            }
        }
    }

    get entity() : string {
        return this._entity;
    }

    set entity(newValue: string) {
        const oldValue = this._entity;
        this._entity = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (!this._webglScene) {
                return;
            }

            this._webglScene.defaultCamera.near = 0.01;
            this._webglScene.defaultCamera.updateProjectionMatrix();

            if (Array.isArray(this._loadedEntity)) {
                for (let i = 0; i < this._loadedEntity.length; ++i) {
                    this._scene.deleteEntity(this._loadedEntity[i]);
                    this._webglScene.removeEntity(this._loadedEntity[i]);
                }
            } else if (this._loadedEntity) {
                this._scene.deleteEntity(this._loadedEntity);
                this._webglScene.removeEntity(this._loadedEntity);
            }
            if (newValue !== undefined) {
                this._loadEntity(newValue, this.config, this.lod, undefined);
            }
            this._delegate.render();
        }
    }

    get entities() : Array<{
        id: string,
        config: any,
        lod: any;
    }> {
        return this._entities;
    }

    set entities(newValue: Array<{
        id: string,
        config: any,
        lod: any;
    }>) {
        const oldValue = this._entities;
        this._entities = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (!this._webglScene) {
                return;
            }

            if (this._loadedEntity) {
                if (Array.isArray(this._loadEntity)) {
                    for (let i = 0; i < (this._loadedEntity as Array<SavaneJS.Entity>).length; ++i) {
                        this._scene.deleteEntity(this._loadedEntity[i]);
                        this._webglScene.removeEntity(this._loadedEntity[i]);
                    }
                } else if (this._loadedEntity) {
                    this._scene.deleteEntity(this._loadedEntity as SavaneJS.Entity);
                    this._webglScene.removeEntity(this._loadedEntity as SavaneJS.Entity);
                }
            }
            if (newValue && Array.isArray(newValue)) {
                this._webglScene.defaultCamera.near = 0.01;
                this._webglScene.defaultCamera.updateProjectionMatrix();

                const cameras: Array<THREE.PerspectiveCamera> = [];
                for (let i = 0; i < newValue.length; ++i) {
                    const camera = this._webglScene.defaultCamera.clone();
                    this._webglScene.setLayer(camera, i);
                    camera.up.set(0, 0, 1);
                    cameras.push(camera);
                }

                this._webglScene.cameraArray = cameras;
                this._resizeCameras();
                for (let i = 0; i < newValue.length; ++i) {
                    const entity = newValue[i];
                    this._loadEntity(entity.id, entity.config, entity.lod, i);
                }
            }
            this._delegate.render();
        }
    }

    get config() : string | number {
        return this._config;
    }

    set config(newValue: string | number) {
        const oldValue = this._config;
        this._config = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (Array.isArray(this._loadedEntity)) {
                for (let i = 0; i < this._loadedEntity.length; ++i) {
                    this._scene.deleteEntity(this._loadedEntity[i]);
                    if (this._webglScene) {
                        this._webglScene.removeEntity(this._loadedEntity[i]);
                    }
                }
            } else if (this._loadedEntity) {
                this._scene.deleteEntity(this._loadedEntity);
                if (this._webglScene) {
                    this._webglScene.removeEntity(this._loadedEntity);
                }
            }

            if (newValue !== undefined && this._loadedEntity) {
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        this._loadEntity(this._loadedEntity[i].objectId, newValue, this.lod, i);
                    }
                } else {
                    this._loadEntity(this._loadedEntity.objectId, newValue, this.lod, undefined);
                }
            }
        }
    }

    get lod() : string | number {
        return this._lod;
    }

    set lod(newValue: string | number) {
        const oldValue = this._lod;
        this._lod = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (Array.isArray(this._loadedEntity)) {
                for (let i = 0; i < this._loadedEntity.length; ++i) {
                    this._scene.deleteEntity(this._loadedEntity[i]);
                    if (this._webglScene) {
                        this._webglScene.removeEntity(this._loadedEntity[i]);
                    }
                }
            } else if (this._loadedEntity) {
                this._scene.deleteEntity(this._loadedEntity);
                if (this._webglScene) {
                    this._webglScene.removeEntity(this._loadedEntity);
                }
            }
            if (newValue !== undefined && this._loadedEntity) {
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        this._loadEntity(this._loadedEntity[i].objectId, this.config, newValue, i);
                    }
                } else {
                    this._loadEntity(this._loadedEntity.objectId, this.config, newValue, undefined);
                }
            }
        }
    }

    get imgProject() : Array<any> {
        return this._imgProject;
    }

    set imgProject(newValue: Array<any>) {
        this._imgProject = newValue;
        if (this._webglScene) {
            if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.PhotoRender) {
                this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                texture.colorSpace = THREE.SRGBColorSpace;
                this._webglScene.threeScene.background = texture;
                this._webglScene.toggleSky(false);
            }
        }
    }

    get leftPanel() : boolean {
        return this._leftPanel;
    }

    set leftPanel(newValue: boolean) {
        this._leftPanel = newValue;
        this._resize();
    }

    get deliverable() : any {
        return this._deliverable;
    }

    set deliverable(newValue: any) {
        this._deliverable = newValue;
    }

    get deliverableId() : number {
        return this._deliverableId;
    }

    set deliverableId(newValue: number) {
        this._deliverableId = newValue;
    }

    get user() : any {
        return this._user;
    }

    set user(newValue: any) {
        this._user = newValue;
    }

    get controls() : boolean {
        return this._controls;
    }

    set controls(newValue: boolean) {
        const oldValue = this._controls;
        this._controls = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
        }
    }

    get useCameraRatio() : boolean {
        return this._useCameraRatio;
    }

    set useCameraRatio(newValue: boolean) {
        const oldValue = this._useCameraRatio;
        this._useCameraRatio = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (this._webglScene) {
                this._webglScene.useCameraRatio = this._useCameraRatio;
                this._webglScene.updateCamera(this.activeCameraId);
            }
        }
    }

    get noFocus() : boolean {
        return this._noFocus;
    }

    set noFocus(newValue: boolean) {
        this._noFocus = newValue;
    }

    get settings() : WebglSettings {
        return this._settings;
    }

    set settings(newValue: WebglSettings) {
        const oldValue = this._settings;
        this._settings = newValue;

        if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            if (oldValue === undefined || oldValue.display !== newValue.display) {
                this.display(newValue.display);
            }
            if (oldValue === undefined || oldValue.meshLevel !== newValue.meshLevel) {
                this.meshLevel = newValue.meshLevel;
            }
            this._delegate.render();
        }
    }

    get meshLevel() : number {
        return this._settings.meshLevel;
    }

    set meshLevel(newValue: number) {
        this._settings.meshLevel = newValue;
        if (newValue !== undefined) {
            if (Array.isArray(this._loadedEntity)) {
                for (let i = 0; i < this._loadedEntity.length; ++i) {
                    this._scene.deleteEntity(this._loadedEntity[i]);
                    if (this._webglScene) {
                        this._webglScene.removeEntity(this._loadedEntity[i]);
                    }
                }
            } else if (this._loadedEntity) {
                this._scene.deleteEntity(this._loadedEntity);
                if (this._webglScene) {
                    this._webglScene.removeEntity(this._loadedEntity);
                }
            }

            if (newValue !== undefined && this._loadedEntity) {
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        this._loadEntity(this._loadedEntity[i].objectId, this.config, this.lod, i);
                    }
                } else {
                    this._loadEntity(this._loadedEntity.objectId, this.config, this.lod, undefined);
                }
            }
        }
    }

    get loaded() : boolean {
        return this._loaded;
    }

    set loaded(newValue: boolean) {
        const oldValue = this._loaded;
        this._loaded = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
            
            // required for realtime update in interactive project
            if (this._webglScene) {
                this._webglScene.loaded = newValue;
            }
        }
    }

    get zoom() : number {
        return this._zoom;
    }

    set zoom(newValue: number) {
        const oldValue = this._zoom;
        this._zoom = newValue;

        if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
        }
    }

    get thirdGrid() : boolean {
        return this._thirdGrid;
    }

    set thirdGrid(newValue: boolean) {
        const oldValue = this._thirdGrid;
        this._thirdGrid = newValue;

        if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
        }
    }

    get staticHullDatas() : any {
        return this._staticHullDatas;
    }

    set staticHullDatas(newValue: any) {
        this._staticHullDatas = newValue;
        if (newValue && this._webglScene) {
            this._webglScene.loadStaticHull(newValue, this._scene);
            this.displayCurrentFloorChanged(this.displayCurrentFloor);
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_AXO);
            this._webglScene.updateEnvs();
            this._webglScene.restoreRoomVisibility();
        }
    }

    get dynamicHullDatas() : any {
        return this._dynamicHullDatas;
    }

    set dynamicHullDatas(newValue: any) {
        this._dynamicHullDatas = newValue;
        if (newValue && this._webglScene) {
            this._webglScene.loadDynamicHull(newValue, this._scene);
            const floor = (this.displayCurrentFloor && typeof PlanManager !== 'undefined') ? PlanManager.getInstance().world.currentScene.currentFloor : null;
            if (this._webglScene.dynamicHull != null) {
                this._webglScene.dynamicHull.filterFloor(floor);
            }
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_AXO);
            this._webglScene.updateEnvs();
        }
    }

    get floorGeneratorHullDatas() : any {
        return this._floorGeneratorHullDatas;
    }

    set floorGeneratorHullDatas(newValue: any) {
        this._floorGeneratorHullDatas = newValue;
        if (newValue && this._webglScene) {
            this._webglScene.loadFloorGeneratorHull(newValue, this._scene);
            const floor = (this.displayCurrentFloor && typeof PlanManager !== 'undefined') ? PlanManager.getInstance().world.currentScene.currentFloor : null;
            if (this._webglScene.floorGeneratorHull != null) {
                this._webglScene.floorGeneratorHull.filterFloor(floor);
            }
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_AXO);
            if (this._interactiveProjectHandler && this._webglScene.camera) {
                if (this._webglScene.floorGeneratorHull != null && this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Axonomic) {
                    this._webglScene.floorGeneratorHull.hideCeiling();
                }
            }
            this._webglScene.updateEnvs();
        }
    }

    get cameraHeight() : string {
        return this._cameraHeight;
    }

    set cameraHeight(newValue: string) {
        const oldValue = this._cameraHeight;
        this._cameraHeight = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
        }
    }

    get cameraFocal() : string {
        return this._cameraFocal;
    }

    set cameraFocal(newValue: string) {
        const oldValue = this._cameraFocal;
        this._cameraFocal = newValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this._delegate.render();
        }
    }

    get photoOpacity() : number {
        return this._photoOpacity;
    }

    set photoOpacity(newValue: number) {
        const oldValue = this._photoOpacity;
        this._photoOpacity = newValue;

        if (oldValue !== newValue) {
            this._delegate.render();
        }
    }

    get transformOrigin() : string {
        return this._transformOrigin;
    }

    set transformOrigin(newValue: string) {
        const oldValue = this._transformOrigin;
        this._transformOrigin = newValue;

        if (oldValue !== newValue) {
            this._delegate.render();
        }
    }

    // END: getters and setters

    _updateHulls(event?: MouseEvent) : void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().state.actionEditEnded !== undefined) {
            PlanManager.getInstance().state.actionEditEnded();
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_TULIP, null);
        }

        this._getHulls();
    }

    async _getStaticHull() : Promise<void> {
        if (!this._scene || !this._webglScene) {
            return;
        }

        if (this._staticHullCanceller && this._staticHullCanceller.signal.aborted === false) {
            this._staticHullCanceller.abort();
        }
        this._staticHullCanceller = new AbortController();

        if (this._webglScene !== null) {
            this._webglScene.cleanUpStaticHull();
        }
        const SMART_URI = (typeof HP_CONFIG !== 'undefined') ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';

        const filtered_rooms = this._webglScene.visibleRooms.join();
        const content = JSON.stringify({
            id: this.deliverableId ? Number(this.deliverableId) : -1,
            index: (typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0),
            filtered_rooms: filtered_rooms ? filtered_rooms : null,
            rhinov_format: Savane.KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene,
                [SavaneJS.SceneConstants.EntityType.FunctionalityChip, SavaneJS.SceneConstants.EntityType.Light,
                SavaneJS.SceneConstants.EntityType.RenderCamera, SavaneJS.SceneConstants.EntityType.ArrangementZone, SavaneJS.SceneConstants.EntityType.SketchBlock,
                SavaneJS.SceneConstants.EntityType.WorkTop, SavaneJS.SceneConstants.EntityType.UserPicture,
                SavaneJS.SceneConstants.EntityType.Sun],
                [SavaneJS.ComponentConstants.ComponentType.CoatingArea,
                SavaneJS.ComponentConstants.ComponentType.TemplateImage], true))
        });

        let riserPath = SMART_URI + 'rise/unreal/static/';
        if (this.settings.hullbin) {
            riserPath = SMART_URI + 'rise/unreal/static/bin/';
        }

        //  Static hull
        try {
            let response = await fetch(riserPath, {
                method: "POST",
                signal: this._staticHullCanceller.signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: content,
            });

            if (!this.settings.hullbin) {
                let data = await response.json();
                this.staticHullDatas = data.obj;
                if (this._webglScene) {
                    this._webglScene.updateSettings();
                    this._webglScene.updateEnvs();
                }
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            } else {
                let data = await response.text();
                this.staticHullDatas = data;
                if (this._webglScene) {
                    this._webglScene.updateSettings();
                    this._webglScene.updateEnvs();
                }
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            }
        } catch {}
    }

    async _getFloorGeneratorHull() : Promise<void> {
        if (!this._scene || !this._webglScene) {
            return;
        }

        if (this._floorGeneratorCanceller && this._floorGeneratorCanceller.signal.aborted === false) {
            this._floorGeneratorCanceller.abort();
        }
        this._floorGeneratorCanceller = new AbortController();

        if (this._webglScene !== null) {
            this._webglScene.cleanUpFloorGeneratorHull();
        }

        const SMART_URI = (typeof HP_CONFIG !== 'undefined') ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';

        const filtered_rooms = this._webglScene.visibleRooms.join();
        const content = JSON.stringify({
            id: this.deliverableId ? Number(this.deliverableId) : -1,
            index: (typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0),
            filtered_rooms: filtered_rooms ? filtered_rooms : null,
            rhinov_format: Savane.KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene,
                [SavaneJS.SceneConstants.EntityType.FunctionalityChip, SavaneJS.SceneConstants.EntityType.Light,
                SavaneJS.SceneConstants.EntityType.RenderCamera, SavaneJS.SceneConstants.EntityType.ArrangementZone, SavaneJS.SceneConstants.EntityType.SketchBlock,
                SavaneJS.SceneConstants.EntityType.WorkTop, SavaneJS.SceneConstants.EntityType.UserPicture,
                SavaneJS.SceneConstants.EntityType.Sun],
                [SavaneJS.ComponentConstants.ComponentType.TemplateImage], true))
        });

        let riserPath = SMART_URI + 'rise/unreal/floorgenerator/';
        if (this.settings.hullbin) {
            riserPath = SMART_URI + 'rise/unreal/floorgenerator/bin/';
        }

        try {
            let response = await fetch(riserPath, {
                method: "POST",
                signal: this._floorGeneratorCanceller.signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: content
            });

            if (!this.settings.hullbin) {
                let data = await response.json();
                this.floorGeneratorHullDatas = data.obj;
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            } else {
                let data = await response.text();
                this.floorGeneratorHullDatas = data;
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            }
        } catch {}
    }

    async _getDynamicHull() : Promise<void> {
        if (!this._scene || !this._webglScene) {
            return;
        }

        if (this._dynamicHullCanceller && this._dynamicHullCanceller.signal.aborted === false) {
            this._dynamicHullCanceller.abort();
        }
        this._dynamicHullCanceller = new AbortController();

        if (this._webglScene !== null) {
            this._webglScene.cleanUpDynamicHull();
        }

        const SMART_URI = (typeof HP_CONFIG !== 'undefined') ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';

        const filtered_rooms = this._webglScene.visibleRooms.join();
        const content = JSON.stringify({
            id: this.deliverableId ? Number(this.deliverableId) : -1,
            index: (typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0),
            filtered_rooms: filtered_rooms ? filtered_rooms : null,
            rhinov_format: Savane.KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene,
                [SavaneJS.SceneConstants.EntityType.Staircase, SavaneJS.SceneConstants.EntityType.Light,
                SavaneJS.SceneConstants.EntityType.RenderCamera, SavaneJS.SceneConstants.EntityType.UserPicture,
                SavaneJS.SceneConstants.EntityType.Sun],
                [SavaneJS.ComponentConstants.ComponentType.Functionality, SavaneJS.ComponentConstants.ComponentType.TemplateImage], true))
        });

        let riserPath = SMART_URI + 'rise/unreal/dynamic/';
        if (this.settings.hullbin) {
            riserPath = SMART_URI + 'rise/unreal/dynamic/bin/';
        }
 
        try {
            let response = await fetch(riserPath, {
                method: "POST",
                signal: this._dynamicHullCanceller.signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: content
            });

            if (!this.settings.hullbin) {
                let data = await response.json();
                this.dynamicHullDatas = data.obj;
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            } else {
                let data = await response.text();
                this.dynamicHullDatas = data;
                this._loadedHullCount += 1;
                if (this._loadedHullCount >= 3) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HULL_LOADED);
                    if (this._webglScene) {
                        this._webglScene.restoreRoomVisibility();
                    }
                }
            }
        } catch {}
    }

    _getHulls() : void {
        this._loadedHullCount = 0;
        this._getStaticHull();
        this._getDynamicHull();
        this._getFloorGeneratorHull();
    }

    displayHeight() : boolean {
        if (this._webglScene) {
            return this._webglScene.displayHeight;
        }

        return false;
    }

    displayCameraParameters() : boolean {
        if (this.settings && this.settings.interactiveProject) {
            return false;
        }

        if (this._webglScene) {
            return !!this._webglScene.camera;
        }

        return false;
    }

    //*************CAMERAS***********
    //Set to cocos camera
    _setCocosCamera() : void {
        if (!this._webglScene) return;

        const webglCanvas = this._webglScene.renderer.domElement;

        // Get the current dimensions of the elements
        const heightBuffer = webglCanvas.offsetHeight;
        const widthBuffer = webglCanvas.offsetWidth;

        // Set the camera state
        this._cameraState = CAMERA_STATES.COCOS;

        // Update the camera
        this._webglScene.updateCamera(null);

        // Set the dimensions of the elements
        webglCanvas.style.height = heightBuffer + 'px';
        webglCanvas.style.width = widthBuffer + 'px';

        this.divWebgl.style.height = heightBuffer + 'px';
        this.divWebgl.style.width = widthBuffer + 'px';
    }

    _updateSettings(): void {
        // Assign it to the rhinov cookie as a string
        SavaneJS.SavaneCookie.setCookie("Rhinov-PlanWebGL-Settings", JSON.stringify(this.settings));

        if (this._webglScene) {
            this._webglScene.settings = this.settings;
            // Quality has changed, refresh the view to apply the change
            this._webglScene.updateSettings();
            if (this._loadedEntity) {
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        this._webglScene.fitCamera(this._loadedEntity[i], i);
                    }
                } else {
                    this._webglScene.fitCamera(this._loadedEntity, 0);
                }
            }
        }
    }

    _setFreeCamera($event: MouseEvent) : void {
        $event.stopPropagation();
        this._cameraState = CAMERA_STATES.FREE;
        if (this._webglScene) {
            this._webglScene.updateCamera(null);
        }
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.CAMERA_RESIZED);
    }

    _gizmoSpace() : string {
        if (this._webglScene) {
            return this._webglScene.gizmo.space;
        }

        return 'world';
    }

    _gizmoEnabled() : boolean {
        if (this._webglScene) {
            return this._webglScene.gizmo['enabled'];
        }

        return false;
    }

    _changeGizmoSpace(event: MouseEvent | KeyboardEvent) : void {
        event.preventDefault();
        event.stopPropagation();
        if (this._webglScene) {
            if (this._webglScene.gizmo.mode === 'scale') {
                return;
            }
            if (this._webglScene.gizmo.space === 'world') {
                this._webglScene.gizmo.space = 'local';
            } else {
                this._webglScene.gizmo.space = 'world';
            }
        }
    }

    _togglePhysics(event: MouseEvent | KeyboardEvent) : void {
        event.preventDefault();
        event.stopPropagation();
        if (this._webglScene) {
            this._webglScene.physicsEnabled = !this._webglScene.physicsEnabled;
        }
    }

    _physicEnabled() : boolean {
        if (this._webglScene) {
            return this._webglScene.physicsEnabled;
        }

        return false;
    }

    _fullScreen($event: MouseEvent) : void {
        if (!this._webglScene) return;
        
        $event.stopPropagation();
        const canvas = this._webglScene.renderer.domElement;

        if (this.divWebgl.offsetWidth == document.documentElement.clientWidth) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            this.divWebgl.style.height = 0 + 'px';
            canvas.style.height = 0 + 'px';
        } else {
            this.divWebgl.style.height = "100%";
            this.divWebgl.style.width = "100%";
            canvas.style.height = "100%";
            canvas.style.width = "100%";
            this.divWebgl.requestFullscreen();
        }
        this._resize();
    }

    _init() : void {
        this._webglScene = new WebglScene(this.divWebgl, this._scene, this.settings, this.controls, this.useCameraRatio, this.leftPanel);
        if (this.settings.interactiveProject) {
            this._interactiveProjectHandler = new InteractiveProjectHandler(this.divWebgl, this._webglScene);
        }
        this._webglScene.render();
    }

    /**
    *
    * Reset scene to webgl, remove all items and recreate
    **/
    _refresh() : void {
        if (typeof PlanManager === 'undefined') {
            return;
        }

        //Notify reload
        Savane.eventsManager.instance.dispatch(WebGLEvents.RELOAD);
        this._scene = PlanManager.getInstance().world.currentScene;

        if (this._webglScene) {
            this._webglScene.cleanUpDynamicHull();
            this._webglScene.updateScene(this._scene);
        }
        this._getHulls();
    }

    displayCurrentFloorChanged(value: boolean) : void {
        if (typeof PlanManager === 'undefined') {
            return;
        }

        if (!this._webglScene) {
            return;
        }

        this._webglScene.displayCurrentFloor(value);
    }

    _replaceWithObject(oldEntity: SavaneJS.ArrangementObject, arrayObj: Array<any>, index: number, oldObjects: Array<SavaneJS.Entity>, newObjectsAndParents: Array<{entity: SavaneJS.Entity, parent: SavaneJS.Entity}>, callback: CallableFunction) : void {
        AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, arrayObj[index], true, (newEntity) => {
            if (oldEntity.parent && oldEntity.floor) {
                SavaneJS.math.mat4.copy(newEntity.transform.localMatrix, oldEntity.transform.globalMatrix);
                // Save group altervatives
                newEntity.closeObjects = arrayObj;
                // Save current alternative number
                newEntity.closeCurrentObject = index;
                // Disable anchoring to prevent updateFloorHeight
                newEntity.isAnchorActive = false;

                // Adapt floor height according to object height change
                let position = oldEntity.position;
                if (oldEntity.anchor && oldEntity.anchor[1] === -3) {
                    position[2] += (oldEntity.realHeight - newEntity.realHeight) / 2;
                } else {
                    position[2] -= (oldEntity.realHeight - newEntity.realHeight) / 2;
                }
                newEntity.position = position;

                oldObjects.push(oldEntity);
                newObjectsAndParents.push({ entity: newEntity, parent: oldEntity.parent });
            }
            callback();
        });
    }

    _loadCloseObjects(entity: SavaneJS.ArrangementObject, add: number, oldObjects: Array<SavaneJS.Entity>, newObjectsAndParents: Array<{entity: SavaneJS.Entity, parent: SavaneJS.Entity}>, callback: CallableFunction) : void {
        let minlength;
        let maxlength;
        let percent = 10;

        if (entity.length > 400) {
            percent = 50;
        }

        minlength = entity.length - (entity.length * percent / 100);
        maxlength = entity.length + (entity.length * percent / 100);

        let url = AssetManagerServices.getUrl() + 'api/v1/assets?q.objectType.eq=' + entity.objectType +
            '&fields=marketName,price,genericName,name,dimensions,sketchBlock,_id,marketLink,configs,stretchability,objectType,coatingType,manufacturer,retailer,pipeline,styles' +
            '&q.dimensions.width.bt=' + minlength + ',' + maxlength +
            '&q.pipeline.state.in=available,archived&sort=dimensions.width&q.pipeline.modelState.eq=validated';

        if (entity.objectStyles && entity.objectStyles.length > 0) {
            url += "&q.styles.in=";
            for (let i = 0; i < entity.objectStyles.length; i++) {
                url += entity.objectStyles[i]._id;
                if (i < entity.objectStyles.length - 1) {
                    url += ",";
                }
            }
        }
        url += '&apikey=' + AssetManagerServices.getToken();

        SavaneJS.HttpService.get(url)
            .then((response) => {
                //success
                if (response.status === 200 && response.data && response.data.resources && response.data.resources.length > 0) {
                    // We search for the current resource within the current returned data
                    let i = 0;
                    for (; i < response.data.resources.length; i++) {
                        if (entity.objectId === response.data.resources[i]._id) {
                            // Found
                            break;
                        }
                    }

                    // Not found search the closest
                    if (i >= response.data.resources.length) {
                        for (let i = 0; i < response.data.resources.length; i++) {
                            if (entity.length === response.data.resources[i].dimensions.width) {
                                // Found
                                break;
                            }

                            if (entity.length < response.data.resources[i].dimensions.width) {
                                // Found
                                if (add < 0) {
                                    i += add;
                                }
                                break;
                            }
                        }
                    } else {
                        i += add;
                    }

                    if (i >= response.data.resources.length) {
                        i = response.data.resources.length - 1;
                    }
                    if (i < 0) {
                        i = 0;
                    }

                    this._replaceWithObject(entity, response.data.resources, i, oldObjects, newObjectsAndParents, callback);
                }
                else {
                    this._displayWarning = true;
                    callback();
                }
            })
            .catch(() => {
                callback();
            });
    }

    _switchCloseObjects(entity: SavaneJS.ArrangementObject, add: number, oldObjects: Array<SavaneJS.Entity>, newObjectsAndParents: Array<{entity: SavaneJS.Entity, parent: SavaneJS.Entity}>, callback: CallableFunction) : void {
        if ((entity as any).closeObjects !== undefined) {
            if ((entity as any).closeObjects.length > 1) {
                let configNb = (entity as any).closeCurrentObject + add;
                if (configNb >= (entity as any).closeObjects.length) {
                    configNb >= (entity as any).closeObjects.length - 1;
                }
                if (configNb < 0) {
                    configNb = 0;
                }
                this._replaceWithObject(entity, (entity as any).closeObjects, configNb, oldObjects, newObjectsAndParents, callback);
            }
        }
    }

    _changeCamera(keyCode: number) : void {
        if (!this._webglScene) return;

        if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
            const entity = this._webglScene.camera.entity;
            entity.startTemporary();
            if (entity.cameraType !== SavaneJS.SceneConstants.CameraType.Perspective) {
                entity.fov = SavaneJS.SceneConstants.CameraPerspectiveOriginalFOV;
            }
            switch (keyCode) {
                case 65: //a
                    entity.cameraType = SavaneJS.SceneConstants.CameraType.Axonomic;
                    break;
                case 80: //p
                    entity.cameraType = SavaneJS.SceneConstants.CameraType.Panoramic;
                    break;
                case 82: //r
                    entity.cameraType = SavaneJS.SceneConstants.CameraType.Storage;
                    break;
                case 67: //h
                    if (entity.cameraType !== SavaneJS.SceneConstants.CameraType.Perspective && entity.cameraType !== SavaneJS.SceneConstants.CameraType.Storage) {
                        entity.cameraType = SavaneJS.SceneConstants.CameraType.Perspective;
                    }
                    entity.format = SavaneJS.SceneConstants.CameraPreset.NormalSquare;
                    break;
                case 72: //h
                    if (entity.cameraType !== SavaneJS.SceneConstants.CameraType.Perspective && entity.cameraType !== SavaneJS.SceneConstants.CameraType.Storage) {
                        entity.cameraType = SavaneJS.SceneConstants.CameraType.Perspective;
                    }
                    entity.format = SavaneJS.SceneConstants.CameraPreset.NormalPaysage;
                    break;
                case 86: //v
                    if (entity.cameraType !== SavaneJS.SceneConstants.CameraType.Perspective && entity.cameraType !== SavaneJS.SceneConstants.CameraType.Storage) {
                        entity.cameraType = SavaneJS.SceneConstants.CameraType.Perspective;
                    }
                    entity.format = SavaneJS.SceneConstants.CameraPreset.NormalPortrait;
                    break;
            }

            if (entity.cameraType !== entity.cameraType) {
                entity.updateCameraNb(PlanManager.getInstance().world);
            }

            PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(entity, false));
            this._webglScene.updateCamera(entity.id);
        }
    }

    _videoPreviewStep() : void {
        if (!this._webglScene) return;
        if (!this._webglScene.camera) return;
        const allArrangements = PlanManager.getInstance().world.currentScene.arrangementObjects;
        const sortedArrangements = allArrangements.sort(function (a, b) { return a.id - b.id; });
        let i;
        const nbObjectsToUnhide = (sortedArrangements.length - this._videoPreviewNbUnhiddenObjects) / ((this._webglScene.camera.entity.nbImages - 1) - this._videoPreviewCurrentImage);

        for (i = this._videoPreviewNbUnhiddenObjects; i < this._videoPreviewNbUnhiddenObjects + nbObjectsToUnhide; i++) {
            const entity = this._webglScene.getPlanEntity(sortedArrangements[i].id);
            if (!entity) {
                continue;
            }

            this._webglScene.setLayer(entity.object, 0);
        }
        this._webglScene.render();
        this._videoPreviewNbUnhiddenObjects = i;
        this._videoPreviewCurrentImage++;

        if (this._videoPreviewCurrentImage >= (this._webglScene.camera.entity.nbImages - 1)) {
            clearInterval(this._videoPreviewIntervalId);
            this._videoPreviewIntervalId = undefined;
        }
    }

    _setCurrentPhoto(photoNb: number) : void {
        if (!this._webglScene) return;
        if (this._webglScene.camera !== null) {
            this._photoFromCurrentProjectNb = photoNb;
            setTimeout(() => {
                this.displayPhoto = true;
                this._delegate.render();
            });
        }
    };

    _getOriginalPhotoUrl() : string {
        if (this._photoFromCurrentProjectNb === -1 || !this._webglScene) {
            return ("");
        }

        if (this.imgProject !== undefined) {
            let photoId = this._getPhotoId(this._webglScene.camera)

            // Photo id not found
            if (photoId === -1) {
                return ("");
            }

            for (let i = 0; i < this.imgProject.length; i++) {
                if (this.imgProject[i].id === photoId) {
                    return this.imgProject[i].original_path;
                }
            }

            if (this.imgProject.length > this._photoFromCurrentProjectNb) {
                return this.imgProject[this._photoFromCurrentProjectNb].original_path;
            } else {
                return ("");
            }
        } else {
            return ("");
        }
    };

    _getPhotoUrl() : string {
        if (this._photoFromCurrentProjectNb === -1 || !this._webglScene) {
            return ("");
        }

        if (this.imgProject !== undefined) {
            let photoId = this._getPhotoId(this._webglScene.camera)

            // Photo id not found
            if (photoId === -1) {
                return ("");
            }

            for (let i = 0; i < this.imgProject.length; i++) {
                if (this.imgProject[i].id === photoId) {
                    return this.imgProject[i].before_post_prod_path + "?" + performance.now();
                }
            }

            if (this.imgProject.length > this._photoFromCurrentProjectNb) {
                return this.imgProject[this._photoFromCurrentProjectNb].before_post_prod_path + "?" + performance.now();
            } else {
                return ("");
            }
        } else {
            return ("");
        }
    };

    _getPhotoId(camera: WebglCamera) : number {
        if (!camera) return -1;
        const photoName = camera.entity.name;
        // If no photo name then it was a fake draw photo self created then return invalid id -1
        if (photoName) {
            const photoNameParse = photoName.split('_');

            return Number(photoNameParse[photoNameParse.length - 1]);
        } else {
            return (-1);
        }
    };

    //INITILIAZE
    initialize() : void {
        if (this.jsonScene) {
            this._scene = SavaneJS.JSONImporter.importScene(this.jsonScene);
        } else if (typeof PlanManager !== 'undefined') {
            // the variable is defined
            this._scene = PlanManager.getInstance().world.currentScene;
        }
        if (this.activeCameraId) {
            this._cameraState = CAMERA_STATES.COCOS;
        }
        this._init();
        this._getHulls();

        if (this._webglScene) {
            this._webglScene.updateScene(this._scene);
            if (this.jsonScene) {
                this._webglScene.updateCamera(this.activeCameraId);
            }
        }
    }

    destroy() : void {
        if (!this.bindedElement) return;
        console.log("Destroy WebGL");
        // Destroy
        if (this._webglScene !== null) {
            this._webglScene.destroy();
            this._webglScene = null;
        }
        //destroy
        this.bindedElement.removeEventListener("mousedown", this.mousedownHandler);
        this.bindedElement.removeEventListener("dblclick", this.dblclickHandler);
        this.bindedElement.removeEventListener("mouseup", this.mouseupHandler);
        this.bindedElement.removeEventListener("mousemove", this.mousemoveHandler);
        this.bindedElement.removeEventListener("mouseleave", this.mouseleaveHandler);
        this.bindedElement.removeEventListener("mouseover", this.mouseoverHandler);
        this.bindedElement.removeEventListener("wheel", this.wheelHandler);
        document.removeEventListener("keydown", this.keydownHandler);
        document.removeEventListener("keyup", this.keyupHandler);
        window.removeEventListener('resize', this._resize);
        //Destroy listeners
        Savane.eventsManager.instance.removeListener(this.coatingRotationListener);
        Savane.eventsManager.instance.removeListener(this.coatingDragListener);
        Savane.eventsManager.instance.removeListener(this.entityDragListener);
        Savane.eventsManager.instance.removeListener(this.setFilters);
        Savane.eventsManager.instance.removeListener(this.assetsUpdated);
        Savane.eventsManager.instance.removeListener(this.stopEnvUpdate);
        Savane.eventsManager.instance.removeListener(this.updateEnvs);
        Savane.eventsManager.instance.removeListener(this.computeCameraShoppingList);
        Savane.eventsManager.instance.removeListener(this.planStateChangedListener);
        Savane.eventsManager.instance.removeListener(this.projectLoaded);
        Savane.eventsManager.instance.removeListener(this.projectUpdated);
        Savane.eventsManager.instance.removeListener(this.refreshListener);
        Savane.eventsManager.instance.removeListener(this.hullLoadedListener);
        if (this.actionCountChange) {
            Savane.eventsManager.instance.removeListener(this.actionCountChange);
        }
        Savane.eventsManager.instance.removeListener(this.floorListener);
        Savane.eventsManager.instance.removeListener(this.reloadScene);
        Savane.eventsManager.instance.removeListener(this.fieldEditChange);
        Savane.eventsManager.instance.removeListener(this.resizeListener);
        Savane.eventsManager.instance.removeListener(this.sceneUpdated);
        Savane.eventsManager.instance.removeListener(this.fullscreenListener);
        Savane.eventsManager.instance.removeListener(this.displaySizeChanged);
        Savane.eventsManager.instance.removeListener(this.updateArrangementCoating);
        Savane.eventsManager.instance.removeListener(this.floorGeneratorChanged);
        Savane.eventsManager.instance.removeListener(this.floorGeneratorUpdated);
        Savane.eventsManager.instance.removeListener(this.frontViewFloorGeneratorChanged);
        Savane.eventsManager.instance.removeListener(this.hideAxo);
        Savane.eventsManager.instance.removeListener(this.hideArrangements);
        Savane.eventsManager.instance.removeListener(this.textFieldListener);
        Savane.eventsManager.instance.removeListener(this.textFieldEndListener);
    }

    _addTarget(parent: SavaneJS.Entity, index: number) : void {
        AssetManagerServices.getAsset("assets", "5f49124f6c44d36fc0f1fb56", null, (result) => {
            AssetManagerServices.createAssetEntity("assets", result, false, (entity) => {
                if (!this._webglScene) return;
                entity.position = SavaneJS.math.vec3.fromValues(0, 0, -parent.position[2]);
                parent.addChild(entity);
                this._webglScene.addEntity(entity, null, null, () => {
                    if (!this._webglScene) return;
                    let webglEntity = this._webglScene.getPlanEntity(entity.id)!;
                    let traversing = (child) => {
                        child.receiveShadow = false;
                        child.keepMaterial = true;
                    }
                    webglEntity.object!.traverse(traversing);
                    this._webglScene.setLayer(webglEntity.object, index);
                });
                this._webglScene.render();
            });
        });
    }

    _loadEntity(id: string, config: any, lod: any, index: number) : void {
        if (index !== undefined) {
            this._loadedEntity = [];
        } else {
            this._loadedEntity = null;
            index = 0;
        }
        AssetManagerServices.getAsset("assets", id, null, (result) => {
            let configIndex = config;
            if (config.startsWith && config.startsWith('lod')) {
                configIndex = config.split('_')[1];
            }
            configIndex = parseInt(configIndex);
            let height = result.configs[configIndex - 1].dimensions.height;
            AssetManagerServices.createAssetEntity("assets", result, false, (entity) => {
                if (!this._webglScene) return;
                let position = SavaneJS.math.vec3.fromValues(0, Math.max(Math.max(entity.height, entity.width), entity.length) * 10 * index, height / 2);
                entity.position = position;
                if (Array.isArray(this._loadedEntity)) {
                    this._loadedEntity.push(entity);
                } else {
                    this._loadedEntity = entity;
                }
                this._addTarget(entity, index);
                this._webglScene.settings.meshLevel = this.meshLevel;
                this._webglScene.meshLevel = this.meshLevel;
                this._webglScene.forceProbes = true;
                this._scene.addEntity(entity);
                this._webglScene.addEntity(entity, config, lod, () => {
                    if (!this._webglScene) return;
                    let webglEntity = this._webglScene.getPlanEntity(entity.id);
                    if (webglEntity && webglEntity instanceof WebglFurniture) {
                        this._webglScene.setLayer(webglEntity.object, index);
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.AM_ENTITY_STATS, { index: index, statistics: webglEntity.statistics });
                    }
                });
                this._webglScene.updateSettings();
                this._webglScene.fitCamera(entity, index);
                this._webglScene.render();
            });
        });
    }

    _resizeCameras() : void {
        if (!this._webglScene) {
            return;
        }

        if (!this._webglScene.cameraArray) {
            return;
        }

        const cameras = this._webglScene.cameraArray;
        const lines = Math.floor(cameras.length / 3);
        const columns = 3;
        const fullWidth = this.divWebgl.parentElement!.offsetWidth;
        const fullHeight = this.divWebgl.offsetHeight;
        const width =  fullWidth / columns;
        const height = fullHeight / lines;
        for (let i = 0; i < cameras.length; ++i) {
            const camera = cameras[i];
            const line = Math.floor(i / 3);
            const column = Math.floor(i % 3);
            camera.setViewOffset(fullWidth, fullHeight, Math.floor(column * width), Math.floor(line * height), Math.ceil(width), Math.ceil(height));
            (camera as any).target = new THREE.Vector3();
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }
    }

    display(value: number) : void {
        if (!this._webglScene) {
            return;
        }

        switch (value) {
            case 0:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).updateCoating();
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).updateCoating();
                }
                break;
            case 1:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).wireframe();
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).wireframe();
                }
                break;
            case 2:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).normal();
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).normal();
                }
                break;
            case 3:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).uvs('numbers');
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).uvs('numbers');
                }
                break;
            case 4:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).uvs('checker');
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).uvs('checker');
                }
                break;
            case 5:
                if (Array.isArray(this._loadedEntity)) {
                    for (let i = 0; i < this._loadedEntity.length; ++i) {
                        (this._webglScene.getPlanEntity(this._loadedEntity[i].id) as WebglFurniture).uvs('lines');
                    }
                } else {
                    (this._webglScene.getPlanEntity(this._loadedEntity!.id) as WebglFurniture).uvs('lines');
                }
                break;
        }
        this._webglScene.render();
    }

    _resize = (updateCamera?: boolean | Event) : any => {
        setTimeout(() => { 
            if (updateCamera === undefined) updateCamera = true;
            if (this._webglScene) {
                if (this._interactiveProjectHandler) {
                    this._interactiveProjectHandler.Resize();
                    this._interactiveProjectHandler.UpdateCamera();
                }
                else {
                    this._resizeCameras();
                    this._webglScene.leftPanel = this.leftPanel;
                    this._webglScene.updateEnvs();
                    if (!this._webglScene.camera) {
                        this._cameraState = CAMERA_STATES.FREE;
                    }
                    if (this._cameraState === CAMERA_STATES.COCOS && updateCamera && this._webglScene.camera) {
                        this._webglScene.updateCamera(this._webglScene.camera.entity.id);
                    } else if (this._cameraState === CAMERA_STATES.FREE) {
                        this._webglScene.updateCamera(null);
                    }
                    if (this._webglScene.camera) {
                        this.cameraHeight = (this._webglScene.camera.entity.transform.localPosition[2] / 10).toFixed(1);
                        this.cameraFocal = this._webglScene.camera.entity.fov.toFixed(1);
                        this._delegate.render();
                    }
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.CAMERA_CHANGED);
                }
            }
        });
    }

    _resetZoom() : void {
        if (!this._webglScene) return;
        this.zoom = 1;
        this.transformOrigin = "center";
        this._transformOriginMouseDown = [0, 0];
        this._transformOriginOffset = [0, 0];
        this._webglScene.renderer.domElement.setAttribute("style", "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin);
        this._delegate.render();
    }

    constructor(delegate: { render: CallableFunction }) {
        this.displayPhoto = false;
        this._photoOpacity = 0.5;
        this._photoFromCurrentProjectNb = -1;
        this._transformOrigin = "center";
        this._transformOriginMouseDown = [0, 0];
        this._transformOriginOffset = [0, 0];

        this._videoPreviewIntervalId = undefined;
        this._videoPreviewNbUnhiddenObjects = 0;
        this._videoPreviewCurrentImage = 0;

        this._enable_additionals_afters = true;

        this._bPressed = false;
        this._vPressed = false;

        this._delegate = delegate;

        this.thirdGrid = false;
        this.loaded = false;
        this.zoom = 1;

        this._cameraState = CAMERA_STATES.FREE;
    }

    run(bindedElement: HTMLElement, divWebgl: HTMLElement, callback: CallableFunction) : void {

        this.bindedElement = bindedElement;
        this.divWebgl = divWebgl;
        PhysicsLoader(PLAN_WEBGL_MODULE_PATH + '/enable3d/ammo', () => {

            // Check if owner needs additional cameras or not (to hide the add camera button)
            if (this.user && this.user.platform.enable_additionals_afters !== undefined && this.user.platform.enable_additionals_afters === 0) {
                this._enable_additionals_afters = false;
            }

            this.settings = this.settings || JSON.parse(SavaneJS.SavaneCookie.getCookie("Rhinov-PlanWebGL-Settings", '{\
                        "meshLevel": 1,\
                        "interactiveProject": false,\
                        "mirrors": true,\
                        "fxaa": true,\
                        "display": 0,\
                        "hullbin": true\
                    }'));

            if (this.meshLevel === undefined) {
                this.meshLevel = 1;
            }

            if (!this.settings.interactiveProject) {
                this.loaded = true;
            }

            this.settings.display = 0;
            this._dragState = null;

            this._interactiveProjectHandler = null;

            this._mouseDownEvent = null;
            this._mouseMoveEvent = null;
            this._mouseOver = false;
            this._loadedEntity = null;

            this.updateFloorGeneratorTimeout = null;
            this.updateDynamicHullTimeout = null;
            this.editRenderCameraCommandTimeout = null;

            this.currentKeyPressed = null;
            this._loadedHullCount = 0;

            this.projectLoaded = Savane.eventsManager.instance.addListener(SavaneJS.Events.PROJECT_DID_LOAD, this._getHulls.bind(this))!;
            this.projectUpdated = Savane.eventsManager.instance.addListener(SavaneJS.Events.UPDATE_RHINOV_FORMAT, this._getHulls.bind(this))!;
            this.refreshListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.PROJECT_REFRESH_HULL, this._getDynamicHull.bind(this))!;

            this.hullLoadedListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.HULL_LOADED, function() {
                if (this._webglScene !== null) {
                    this.loaded = this._loadedHullCount >= 3;
                    this._webglScene.render();
                }
            }.bind(this));

            // Listener for COCOS Camera movement
            let cocosPosition = new THREE.Vector3();
            this.cocosCameraDragListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.POS_WIN_CHANGED, function(event) {
                if (this._webglScene !== null) {
                    cocosPosition.set(event.userData.x / 10, event.userData.y / 10, 150 / event.userData.zoom);
                    if (!this._webglScene.defaultCameraMoved) {
                        cocosPosition.z = this._webglScene.getDefaultCameraDistanceFit();
                        this._webglScene.defaultCamera.position.copy(cocosPosition);
                        (this._webglScene.defaultCamera as any).target = new THREE.Vector3(cocosPosition.x, cocosPosition.y, 0);
                        this._webglScene.render();
                    }
                }
            }.bind(this));

            // Listen to floor change and display current floor if asked
            this.floorListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.PLAN_FLOOR_CHANGED, function(event) {
                if (this._webglScene) {
                    this._webglScene.detachSelection();
                    this._webglScene.visibleRooms = [];

                    this.displayCurrentFloorChanged(this.displayCurrentFloor);
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_ARRANGEMENTS);

                    if (this._webglScene.camera) {
                        if (this._webglScene.camera.entity.cameraType !== SavaneJS.SceneConstants.CameraType.PhotoRender) {
                            this._cameraState = CAMERA_STATES.FREE;
                            this._webglScene.updateCamera(null);
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.CAMERA_RESIZED);
                        } else {
                            this._webglScene.updateCamera(this._webglScene.camera.entity.id);
                        }
                    }
                }
            }.bind(this));

            // Listen to floor change and reload the entire webgl scene
            this.coatingRotationListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.COATING_ROTATION_CHANGED, function(event) {
                if (this._webglScene) {
                    this._webglScene.updateCoatingParameters(event.userData);
                }
            }.bind(this));

            this.coatingDragListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.COATING_DRAG, function(data) {
                this.bindedElement.style.cursor = 'grabbing';
                this._dragState = {
                    state: DRAG_STATE.COATING,
                    data: data.userData,
                    value: data.userData.coating
                };
            }.bind(this));

            this.entityDragListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.ENTITY_EDITION, function(data) {
                this.bindedElement.style.cursor = 'grabbing';
                this._dragState = {
                    state: DRAG_STATE.ENTITY,
                    data: data.userData,
                    value: data.userData.entity
                };
            }.bind(this));

            this.planStateChangedListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.PLAN_STATE_CHANGED, function(state) {
                if (state.userData !== 36 && state.userData !== 31 && state.userData !== 41 && state.userData !== 42 && !this._mouseOver) {
                    this.bindedElement.style.cursor = 'default';
                    this._dragState = null;
                }
            }.bind(this));

            this.setFilters = Savane.eventsManager.instance.addListener(SavaneJS.Events.SET_FILTERS, function(event) {
                //resize();
            }.bind(this));

            this.assetsUpdated = Savane.eventsManager.instance.addListener('results_update_datas', function(event) {
                if (this._webglScene !== null) {
                    this._webglScene.updateEnvs();
                }
            }.bind(this));

            this.stopEnvUpdate = Savane.eventsManager.instance.addListener(SavaneJS.Events.STOP_UPDATING_ENVS, function(event) {
                if (this._webglScene !== null) {
                    this._webglScene.stopEnvUpdate();
                }
            }.bind(this));

            this.updateEnvs = Savane.eventsManager.instance.addListener(SavaneJS.Events.START_UPDATING_ENVS, function(event) {
                if (this._webglScene !== null) {
                    this._webglScene.updateEnvs();
                }
            }.bind(this));

            this.computeCameraShoppingList = Savane.eventsManager.instance.addListener(SavaneJS.Events.COMPUTE_CAMERA_SHOPPING_LIST, function(event) {
                if (this._webglScene !== null) {
                    this._webglScene.computeCameraShoppingList(event.userData, this.user);
                }
            }.bind(this));

            if (!this.settings.interactiveProject) {
                // Listener for command proceed in COCOS that will cause an update into the webGL view (move an arrangement objet, group arrangement objects or groups)
                this.actionCountChange = Savane.eventsManager.instance.addListener(SavaneJS.Events.COMMAND_EXECUTED, async function(event) {
                    // If the webGL scene exists
                    if (this._webglScene !== null) {
                        // Depending on the command name (see Command.js from CocosPlanModule/src/cocos/scripts/command)
                        switch (event.userData.name) {

                            case SavaneJS.Commands.CommandEnum.EditRenderCameraCommand:
                                this._webglScene.showExcludedObject();
                                this._webglScene.hideExcludedObject(this._webglScene.getPlanCamera(event.userData.datas.id));
                                this._webglScene.updateCamera(event.userData.datas.id);
                                this._resize(false);
                                break;

                            // Edit entity command : edition of cameras, lights, arrangement object and group
                            case SavaneJS.Commands.CommandEnum.EditGeometryPrimitiveCommand:
                            case SavaneJS.Commands.CommandEnum.EditTechnicalElementCommand:
                            case SavaneJS.Commands.CommandEnum.EditJoineryCommand:
                            case SavaneJS.Commands.CommandEnum.EditStaircaseCommand:
                            case SavaneJS.Commands.CommandEnum.EditRoomPropertiesCommand:
                            case SavaneJS.Commands.CommandEnum.EditEntityCommand:
                                // We do this code for both execute and undo

                                // If we edit a camera
                                if (event.userData.datas.type === SavaneJS.SceneConstants.EntityType.RenderCamera) {
                                    // The camera is now used for the preview
                                    this._cameraState = CAMERA_STATES.COCOS;
                                    if (event.userData.datas.select) {
                                        this._webglScene.updateCamera(event.userData.datas.id);
                                        if (this._webglScene.camera) {
                                            if (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.PhotoRender) {
                                                if (this._webglScene.sun.entity.exterior === 'defaut') {
                                                    this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                                                    const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                                                    texture.colorSpace = THREE.SRGBColorSpace;
                                                    this._webglScene.threeScene.background = texture;
                                                    this._webglScene.toggleSky(false);
                                                } else {
                                                    this._webglScene.loadBackground(this._webglScene.sun.entity, false);
                                                }
                                            } else if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Axonomic) {
                                                this._webglScene.threeScene.background = null;
                                                this._webglScene.toggleSky(true);
                                            } else {
                                                let additional = (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Perspective || this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Storage) && this.user && this.user.platforms_id !== 1;
                                                this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
                                            }
                                        }
                                    } else {
                                        if (this._webglScene.camera) {
                                            this._webglScene.camera.targetInitialized = false;
                                        }
                                        this._webglScene.updateCamera(event.userData.datas.id);
                                    }
                                    this._resize(false);
                                }
                                else {
                                    // Get webGL entity
                                    let webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                                    // If it exists
                                    if (webglEntity !== null) {
                                        //  We will go up the hierarchy
                                        let rootArrangementEntity = webglEntity.entity;

                                        // Until we find the root arrangement
                                        if (rootArrangementEntity.parent) {
                                            while (rootArrangementEntity.parent &&
                                                (rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementObject ||
                                                 rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.GeometryGroup)) {
                                                rootArrangementEntity = rootArrangementEntity.parent;
                                            }
                                        }

                                        // And we update the whole tree underneath so all positions are updated
                                        this._webglScene.updateTree(rootArrangementEntity);
                                    }
                                }
                                break;

                            // Entity addition to the scene, we need to create the new entity into the webGL scene
                            case SavaneJS.Commands.CommandEnum.AddEntityCommand:
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // Undo, we remove the entity tree from the webGL scene (undo addition = destruction)
                                    this._webglScene.removeEntityTree(event.userData.datas.entity);
                                }
                                else {
                                    // Execute, we add the entity tree to the webGL scene
                                    this._webglScene.addEntityTree(event.userData.datas.entity);

                                    // Get webGL entity
                                    let webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                                    // If it exists
                                    if (webglEntity !== null) {
                                        //  We will go up the hierarchy
                                        let rootArrangementEntity = webglEntity.entity;

                                        // Until we find the root arrangement
                                        if (rootArrangementEntity.parent) {
                                            while (rootArrangementEntity.parent && (rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementObject)) {
                                                rootArrangementEntity = rootArrangementEntity.parent;
                                            }
                                        }

                                        // And we update the whole tree underneath so all positions are updated
                                        this._webglScene.updateTree(rootArrangementEntity);
                                    }
                                }
                                break;

                            case SavaneJS.Commands.CommandEnum.AddEntitiesCommand:
                                // Undo or execute ?
                                if (event.userData.undo) {
                                }
                                else {
                                    for (let i = 0; i < event.userData.datas.entitiesAndParents.length; i++) {
                                        // And we update the whole tree underneath so all positions are updated
                                        this._webglScene.updateTree(event.userData.datas.entitiesAndParents[i].entity);
                                    }
                                }
                                break;

                            // Entity deletion from the scene
                            case SavaneJS.Commands.CommandEnum.DeleteEntityCommand:
                            case SavaneJS.Commands.CommandEnum.DeleteTechnicalElementCommand:
                            case SavaneJS.Commands.CommandEnum.DeleteStaircaseCommand:
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // Undo, we add the entity tree to the webGL scene (undo deletion = addition)
                                    this._webglScene.addEntityTree(event.userData.datas.entity);

                                    // Get webGL entity
                                    let webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                                    // If it exists
                                    if (webglEntity !== null) {
                                        //  We will go up the hierarchy
                                        let rootArrangementEntity = webglEntity.entity;

                                        // Until we find the root arrangement
                                        if (rootArrangementEntity.parent) {
                                            while (rootArrangementEntity.parent && (rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === SavaneJS.SceneConstants.EntityType.ArrangementObject)) {
                                                rootArrangementEntity = rootArrangementEntity.parent;
                                            }
                                        }

                                        // And we update the whole tree underneath so all positions are updated
                                        this._webglScene.updateTree(rootArrangementEntity);
                                    }
                                }
                                else {
                                    // Execute, we delete the entity tree from the webGL scene
                                    this._webglScene.removeEntityTree(event.userData.datas.entity);
                                }
                                break;

                            // Arrangement group creation
                            case SavaneJS.Commands.CommandEnum.CreateGroupCommand:
                                this._webglScene.detachSelection();
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // Undo, we remove all grouped objects from the webGL scene and add them again because their parent has changed (we need to remove them so they are removed from
                                    // their original webGL parent and we add them back to attach them to their new parent)
                                    // Warning, we don't reove the group entity tree because it has been emptied before the execution arrives here so we use the arrangement field of the execDatas parameter
                                    for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                                        this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                                        this._webglScene.addEntityTree(event.userData.datas.arrangements[i]);
                                    }
                                    // Remove the created group from the scene
                                    this._webglScene.removeEntity(event.userData.datas.group);
                                }
                                else {
                                    // Execute, we remove all grouped objects from the webGL scene and we will add them back along with the created group at the end (this will be done recursively by
                                    // the addEntityTree function)
                                    for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                                        this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                                    }
                                    this._webglScene.addEntityTree(event.userData.datas.group);
                                }
                                this._webglScene.attachSelection();
                                break;

                            // Arrangement group deletion
                            case SavaneJS.Commands.CommandEnum.DeleteGroupCommand:
                                this._webglScene.detachSelection();
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // Undo, we add the group back to the scene, we will remove the children trees from the webGL scene
                                    for (let i = 0; i < event.userData.datas.group.iArrangements.length; i++) {
                                        this._webglScene.removeEntityTree(event.userData.datas.group.iArrangements[i]);
                                    }
                                    // And we add the group entity tree to the webGL scene (this is done recursively by the addEntityTree function)
                                    this._webglScene.addEntityTree(event.userData.datas.group);
                                }
                                else {
                                    // Execute, we remove the groupe entity tree from the scene (done recursively)
                                    this._webglScene.removeEntityTree(event.userData.datas.group);
                                    // And we add back all children to the webGL scene
                                    for (let i = 0; i < event.userData.datas.group.iArrangements.length; i++) {
                                        this._webglScene.addEntityTree(event.userData.datas.group.iArrangements[i]);
                                    }
                                }
                                this._webglScene.attachSelection();
                                break;

                            // Entities leaving a group
                            case SavaneJS.Commands.CommandEnum.LeaveGroupCommand:
                                this._webglScene.detachSelection();
                                // We simply remove and add the entity leaving the group for both execute and undo
                                this._webglScene.removeEntityTree(event.userData.datas.entity);
                                this._webglScene.addEntityTree(event.userData.datas.entity);
                                this._webglScene.attachSelection();
                                break;

                            // Merge or multiple arrangement groups
                            case SavaneJS.Commands.CommandEnum.MergeGroupCommand:
                                this._webglScene.detachSelection();
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // Undo the groups are unmerged, remove the merged group
                                    this._webglScene.removeEntityTree(event.userData.datas.group);

                                    // And re-create original groups
                                    for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                                        this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                                        this._webglScene.addEntityTree(event.userData.datas.arrangements[i]);
                                    }
                                }
                                else {
                                    // Execute, remove each merged entity tree from the scene
                                    for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                                        this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                                    }

                                    // And add the created group tree (already containing the entities above as children) to the webGL scene
                                    this._webglScene.addEntityTree(event.userData.datas.group);
                                }
                                this._webglScene.attachSelection();
                                break;

                            // Geometry group creation
                            case SavaneJS.Commands.CommandEnum.CreateGeometryGroupCommand:
                                this._webglScene.detachSelection();
                                if (event.userData.undo) {
                                    // Undo the groups are unmerged, remove the merged group
                                    this._webglScene.removeEntityTree(event.userData.datas.group);
                                }
                                else {
                                    // And add the created group tree (already containing the entities above as children) to the webGL scene
                                    this._webglScene.addEntityTree(event.userData.datas.group);
                                }
                                await this._getDynamicHull();
                                this._webglScene.attachSelection();
                                break;

                            case SavaneJS.Commands.CommandEnum.DeleteGeometryGroupCommand:
                                this._webglScene.detachSelection();
                                // Undo or execute ?
                                if (event.userData.undo) {
                                    // And we add the group entity tree to the webGL scene (this is done recursively by the addEntityTree function)
                                    this._webglScene.addEntityTree(event.userData.datas.group);
                                }
                                else {
                                    // Execute, we remove the groupe entity tree from the scene (done recursively)
                                    this._webglScene.removeEntityTree(event.userData.datas.group);
                                }
                                await this._getDynamicHull();
                                this._webglScene.attachSelection();
                                break;

                            case SavaneJS.Commands.CommandEnum.AddComponentCommand:
                            case SavaneJS.Commands.CommandEnum.ChangeCoatingCommand:
                            case SavaneJS.Commands.CommandEnum.EditComponentCommand:
                                if (typeof PlanManager !== 'undefined') {
                                    this._webglScene.detachSelection();
                                    this._scene = PlanManager.getInstance().world.currentScene;
                                    if (event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.CoatingArea || event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.Area) {
                                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.FRONTVIEW_FLOOR_GENERATOR_CHANGED);
                                    } else if (event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.FloorCoatingArea) {
                                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.FLOOR_GENERATOR_CHANGED);
                                    } else {
                                        let entity = event.userData.datas.entity || event.userData.datas.component.entity;
                                        if (entity && entity.isArrangementObjectEntity()) {
                                            let glEntity = this._webglScene.getPlanEntity(entity.id);
                                            if (glEntity instanceof WebglFurniture) {
                                                glEntity.updateCoating();
                                            }
                                        } else {
                                            this._webglScene.updateHull(entity);
                                        }
                                    }
                                    this._webglScene.attachSelection();
                                }
                                break;

                            case SavaneJS.Commands.CommandEnum.DeleteComponentCommand:
                                if (event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.CoatingArea || event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.Area) {
                                    this._getDynamicHull();
                                } else if (event.userData.datas.component.componentType === SavaneJS.ComponentConstants.ComponentType.FloorCoatingArea) {
                                    this._getFloorGeneratorHull();
                                }
                                break;

                            case SavaneJS.Commands.CommandEnum.ApplySmartDesignerSolutionCommand:
                                if (typeof PlanManager !== 'undefined') {
                                    this._scene = PlanManager.getInstance().world.currentScene;

                                    for (let i = 0; i < event.userData.datas.coatings.length; i++) {
                                        let entity = this._scene.getDeepChild(event.userData.datas.coatings[i].forEntityId);

                                        if (entity) {
                                            this._webglScene.updateHull(entity);
                                        }
                                    }
                                }
                                this._getDynamicHull();
                                this._getFloorGeneratorHull();
                                break;
                            case SavaneJS.Commands.CommandEnum.ApplySmartRestylerSolutionCommand:
                                if (typeof PlanManager !== 'undefined') {
                                    this._scene = PlanManager.getInstance().world.currentScene;

                                    for (let i = 0; i < event.userData.datas.coatings.length; i++) {
                                        let entity = this._scene.getDeepChild(event.userData.datas.coatings[i].forEntityId);

                                        if (entity) {
                                            this._webglScene.updateHull(entity);
                                        }
                                    }
                                }
                                this._getDynamicHull();
                                this._getFloorGeneratorHull();
                                break;
                        }

                        this._webglScene.updateEnvs();
                        this._webglScene.render();
                    }
                    else {
                        this._refresh();
                    }
                }.bind(this));
            }

            this.fieldEditChange = Savane.eventsManager.instance.addListener(SavaneJS.Events.ENTITY_PARAM_EDITED, function(event) {
                if (!this._webglScene) {
                    return;
                }

                if (event.userData.type === SavaneJS.SceneConstants.EntityType.RenderCamera) {
                    this._cameraState = CAMERA_STATES.COCOS;
                    this._webglScene.updateCamera(event.userData.id);
                    this._resize(false);
                    if (event.userData.key === 'cameraType' || event.userData.key === undefined) {
                        if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Axonomic) {
                            this._webglScene.threeScene.background = null;
                            this._webglScene.toggleSky(true);
                        } else {
                            if (this._webglScene.sun.entity.exterior === 'defaut' && this._webglScene.camera) {
                                this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                                const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                                texture.colorSpace = THREE.SRGBColorSpace;
                                this._webglScene.threeScene.background = texture;
                                this._webglScene.toggleSky(false);
                            } else {
                                let additional = this._webglScene.camera && (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Perspective || this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Storage) && this.user && this.user.platforms_id !== 1;
                                this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
                            }
                        }
                    }
                } else if (event.userData.type === SavaneJS.SceneConstants.EntityType.Sun) {
                    if ((event.userData.key === 'exterior' || event.userData.key === 'additionalExterior' || event.userData.key === 'decorsRotation') && this._webglScene.camera) {
                        if (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.PhotoRender) {
                            if (this._webglScene.sun.entity.exterior === 'defaut') {
                                this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                                const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                                texture.colorSpace = THREE.SRGBColorSpace;
                                this._webglScene.threeScene.background = texture;
                                this._webglScene.toggleSky(false);
                            } else {
                                this._webglScene.loadBackground(this._webglScene.sun.entity, false);
                            }
                        } else {
                            let additional = (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Perspective || this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Storage) && this.user && this.user.platforms_id !== 1;
                            this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
                        }
                    }
                    this._webglScene.sun.update();
                } else {
                    let webglEntity = this._webglScene.getPlanEntity(event.userData.id);
                    if (webglEntity !== null) {
                        let attached = false;
                        if (this._webglScene.interactionWithItemAllowed(webglEntity.entity)) {
                            attached = this._webglScene.detachFromSelection(webglEntity);
                        }
                        webglEntity.update();
                        if (attached && this._webglScene.interactionWithItemAllowed(webglEntity.entity)) {
                            this._webglScene.attachToSelection(webglEntity);
                        }
                    }
                }
                this._webglScene.updateEnvs();
                this._webglScene.render();
            }.bind(this));

            this.updateArrangementCoating = Savane.eventsManager.instance.addListener(SavaneJS.Events.UPDATE_ARRANGEMENT_COATING, function(event) {
                if (this._webglScene === null) {
                    return;
                }

                let webglEntity = this._webglScene.getPlanEntity(event.userData.id);
                if (!webglEntity) {
                    return;
                }

                if (webglEntity instanceof WebglFurniture) {
                    webglEntity.updateCoating();
                }
            }.bind(this));

            this.floorGeneratorChanged = Savane.eventsManager.instance.addListener(SavaneJS.Events.FLOOR_GENERATOR_CHANGED, function() {
                if (this.updateFloorGeneratorTimeout) {
                    clearTimeout(this.updateFloorGeneratorTimeout);
                }
                this.updateFloorGeneratorTimeout = setTimeout(() => {
                    this.updateFloorGeneratorTimeout = null;
                    this._getFloorGeneratorHull();
                }, 1500);
            }.bind(this));

            this.cancelFloorGeneratorChanged = Savane.eventsManager.instance.addListener(SavaneJS.Events.CANCEL_FLOOR_GENERATOR_CHANGED, function() {
                if (this.updateFloorGeneratorTimeout) {
                    clearTimeout(this.updateFloorGeneratorTimeout);
                    this.updateFloorGeneratorTimeout = null;
                }
            }.bind(this));

            this.frontViewFloorGeneratorChanged = Savane.eventsManager.instance.addListener(SavaneJS.Events.FRONTVIEW_FLOOR_GENERATOR_CHANGED, function() {
                if (this.updateDynamicHullTimeout) {
                    clearTimeout(this.updateDynamicHullTimeout);
                }
                this.updateDynamicHullTimeout = setTimeout(() => {
                    this.updateDynamicHullTimeout = null;
                    this._getDynamicHull();
                }, 1500);
            }.bind(this));

            this.floorGeneratorUpdated = Savane.eventsManager.instance.addListener(SavaneJS.Events.FLOOR_GENERATOR_UPDATED, function(event) {
                if (this._webglScene && this._webglScene.floorGeneratorHull) {
                    this._webglScene.floorGeneratorHull.UpdateComponent(event.userData.component, event.userData.offset);
                }
            }.bind(this));

            this.sceneUpdated = Savane.eventsManager.instance.addListener(SavaneJS.Events.RHINOV_FORMAT_UPDATED, function(event) {
                this._scene = event.userData.scene;
            }.bind(this));

            this.resizeListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.CAMERA_RESIZED, function() {
                this._resize();
            }.bind(this));

            this.fullscreenListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.FULLSCREEN_CHANGED, function() {
                this._resize();
            }.bind(this));

            this.displaySizeChanged = Savane.eventsManager.instance.addListener('display_size_changed', function() {
                this._resize();
            }.bind(this));

            this.reloadScene = Savane.eventsManager.instance.addListener(SavaneJS.Events.PROJECT_RELOAD, this._refresh.bind(this))!;

            this.hideAxo = Savane.eventsManager.instance.addListener(SavaneJS.Events.HIDE_AXO, function() {
                if (!this._webglScene) {
                    return;
                }

                if (typeof PlanManager === 'undefined') {
                    return;
                }

                if (this._webglScene.staticHull) {
                    if (PlanManager.getInstance()._hideAxo) {
                        this._webglScene.staticHull.hideCeiling();
                    } else {
                        this._webglScene.staticHull.showCeiling();
                    }
                }
                if (this._webglScene.dynamicHull) {
                    if (PlanManager.getInstance()._hideAxo) {
                        this._webglScene.dynamicHull.hideCeiling();
                    } else {
                        this._webglScene.dynamicHull.showCeiling();
                    }
                }
                if (this._webglScene.floorGeneratorHull) {
                    if (PlanManager.getInstance()._hideAxo) {
                        this._webglScene.floorGeneratorHull.hideCeiling();
                    } else {
                        this._webglScene.floorGeneratorHull.showCeiling();
                    }

                }
                this._webglScene.render();
            }.bind(this));

            this.hideArrangements = Savane.eventsManager.instance.addListener(SavaneJS.Events.HIDE_ARRANGEMENTS, function() {
                if (!this._webglScene) {
                    return;
                }

                if (typeof PlanManager === 'undefined') {
                    return;
                }

                if (PlanManager.getInstance()._hideArrangements) {
                    this._webglScene.hideArrangements();
                } else {
                    this._webglScene.showArrangements(this.displayCurrentFloor);
                }
                this._webglScene.render();
            }.bind(this));

            let textFieldCallback = function(event) {
                if (this._webglScene) {
                    const entity = this._webglScene.getPlanEntity(event.getUserData().obj.id);
                    if (entity) {
                        this._webglScene.detachSelection();
                        entity.update();
                        this._webglScene.attachSelection();
                        this._webglScene.render();
                    }
                }
            }

            this.textFieldListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.TEXTFIELD_EDIT, textFieldCallback.bind(this))!;
            this.textFieldEndListener = Savane.eventsManager.instance.addListener(SavaneJS.Events.TEXTFIELD_EDIT_ENDED, textFieldCallback.bind(this))!;

            this.mousedownHandler = (event: MouseEvent) => {
                event.stopPropagation();

                if (this._webglScene && this._webglScene.renderer) {
                    if (event.srcElement !== this._webglScene.renderer.domElement && this.zoom === 1) {
                        return;
                    }
                }

                // Hide tulip will commit current changes
                if (this._webglScene && this._webglScene.gizmo.dragging === false) {
                    if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().state.actionEditEnded !== undefined) {
                        PlanManager.getInstance().state.actionEditEnded();
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_TULIP, null);
                    }
                }

                if (event.button === 1) {
                    event.preventDefault();
                }
                if (!this._webglScene) {
                    return;
                }

                if (this.settings.interactiveProject && this._interactiveProjectHandler) {
                    this._interactiveProjectHandler.HandleMouseDown(event);
                }

                this._mouseDownEvent = {};
                for (let item in event) {
                    this._mouseDownEvent[item] = event[item];
                }

                if (!this.controls) {
                    return;
                }

                switch (this._mouseDownEvent.button) {
                    case 1:
                        if (this._mouseDownEvent.altKey && this._webglScene && this.zoom !== 1) {
                            this._transformOriginMouseDown = [event.clientX, event.clientY];
                            return;
                        }
                    case 2:
                        if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                            this._webglScene.camera.entity.startTemporary();
                        }
                        break;
                }

                this._webglScene.stopEnvUpdate();
                if (!this.settings.interactiveProject) {
                    this._webglScene.selectionBoxHelper.isDown = false;
                    if (typeof PlanManager !== 'undefined') {
                        let selection = PlanManager.getInstance().selection.slice();
                        selection = selection.filter(function (item) {
                            return this._webglScene.interactionWithItemAllowed(item);
                        }.bind(this));
                        if (event.ctrlKey && selection.length === 0) {
                            this._webglScene.selectionBox.startPoint.set(
                                (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                                (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                                0.5
                            );
                            this._webglScene.selectionBoxHelper.isDown = true;
                            return;
                        } else {
                            this._webglScene.selectionBoxHelper.onSelectOver();
                        }
                    }
                }

                Savane.eventsManager.instance.dispatch(MOUSE_EVENTS.DOWN, {
                    x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                    y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                    offsetX: event.offsetX,
                    offsetY: event.offsetY,
                    button: event.button,
                    ctrl: event.ctrlKey,
                    alt: event.altKey,
                    shift: event.shiftKey
                });

                this._webglScene.render();
            }

            this.bindedElement.addEventListener("mousedown", this.mousedownHandler);

            this.dblclickHandler = (event: MouseEvent) => {
                event.stopPropagation();
                if (this._webglScene && this._webglScene.renderer) {
                    if (event.srcElement !== this._webglScene.renderer.domElement) {
                        return;
                    }
                }

                Savane.eventsManager.instance.dispatch(MOUSE_EVENTS.DOUBLE_CLICK, {
                    x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                    y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                    offsetX: event.offsetX,
                    offsetY: event.offsetY,
                    button: event.button,
                    ctrl: event.ctrlKey,
                    alt: event.altKey,
                    shift: event.shiftKey
                });

                if (this._webglScene) {
                    this._webglScene.render();
                }
            }

            this.bindedElement.addEventListener("dblclick", this.dblclickHandler);

            this.mousemoveHandler = (event: MouseEvent) => {
                event.stopPropagation();

                if (!this._webglScene) {
                    return;
                }

                if (this._webglScene && this._webglScene.renderer) {
                    if (event.srcElement !== this._webglScene.renderer.domElement && this.zoom === 1) {
                        return;
                    }
                }

                if (!this.loaded) {
                    return;
                }

                this._mouseMoveEvent = {};
                for (let item in event) {
                    this._mouseMoveEvent[item] = event[item];
                }

                if (this.settings.interactiveProject && this._interactiveProjectHandler) {
                    this._interactiveProjectHandler.Hovering(event);
                }

                if (!this._mouseDownEvent) {
                    return;
                }

                const dx = event.offsetX - this._mouseDownEvent.offsetX;
                const dy = event.offsetY - this._mouseDownEvent.offsetY;

                this._mouseDownEvent.offsetX = event.offsetX;
                this._mouseDownEvent.offsetY = event.offsetY;

                if (!this.controls) {
                    return;
                }

                this._webglScene.updateEnvs();
                switch (this._mouseDownEvent.button) {
                    case 1:
                        if (this._mouseDownEvent.altKey && this._webglScene && this.zoom !== 1) {
                            this._webglScene.selectionBoxHelper.isDown = false;
                            this._transformOriginOffset = [this._transformOriginOffset[0] + this.zoom * (this._transformOriginMouseDown[0] - event.clientX), this._transformOriginOffset[1] + this.zoom * (this._transformOriginMouseDown[1] - event.clientY)];
                            this._transformOriginMouseDown = [event.clientX, event.clientY];
                            this.transformOrigin = (this._transformOriginOffset[0] + this._mouseDownEvent.target.clientWidth / 2) + "px " + (this._transformOriginOffset[1] + this._mouseDownEvent.target.clientHeight / 2) + "px";
                            this._webglScene.renderer.domElement.setAttribute("style", "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin);
                            this._delegate.render();
                            return;
                        }
                        if (event.altKey) {
                            this._webglScene.rotateCamera(-dx / (event.target as HTMLElement).clientWidth, dy / (event.target as HTMLElement).clientHeight, 400);
                        }
                        else if (event.ctrlKey) {
                            this._webglScene.dollyCamera((dy / (event.target as HTMLElement).clientHeight) * 400, {
                                x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                                y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1
                            });
                        }
                        else {
                            this._webglScene.panCamera(this._mouseDownEvent.offsetX, this._mouseDownEvent.offsetY, dx, dy);
                        }
                        break;
                    case 2:
                        this._webglScene.focalCamera((dy / (event.target as HTMLElement).clientHeight) * 60);
                }

                if (this._webglScene.camera) {
                    this.cameraHeight = (this._webglScene.camera.entity.transform.localPosition[2] / 10).toFixed(1);
                    this.cameraFocal = this._webglScene.camera.entity.fov.toFixed(1);
                    this._delegate.render();
                }

                if (!this.settings.interactiveProject) {
                    if (event.shiftKey && this._webglScene.selectionBoxHelper.isDown) {
                        this._webglScene.selectionBox.endPoint.set(
                            (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                            (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                            0.5
                        );
                    }
                }

                Savane.eventsManager.instance.dispatch(MOUSE_EVENTS.MOVE, {
                    x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                    y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                    dx: dx / (event.target as HTMLElement).clientWidth,
                    dy: dy / (event.target as HTMLElement).clientHeight,
                    offsetX: event.offsetX,
                    offsetY: event.offsetY,
                    button: this._mouseDownEvent ? this._mouseDownEvent.button : null
                });

                this._webglScene.render();
            }

            this.bindedElement.addEventListener("mousemove", this.mousemoveHandler);

            this.mouseleaveHandler = (event: MouseEvent) => {
                if (this._mouseOver) {
                    if (this._mouseDownEvent) {
                        switch (this._mouseDownEvent.button) {
                            case 1:
                            case 2:
                                if (this._webglScene && this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                                    PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, false));
                                }
                                break;
                        }
                    }

                    this.bindedElement.style.cursor = 'default';
                    this._mouseDownEvent = null;
                    this._mouseOver = false;
                    this.currentKeyPressed = null;
                    if (this._dragState) {
                        if (this._dragState.state === DRAG_STATE.COATING) {
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.COATING_DRAG, this._dragState.data);
                        } else if (this._dragState.state === DRAG_STATE.ENTITY) {
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.ENTITY_EDITION, this._dragState.data);
                        }
                    }
                    if (this._webglScene) {
                        this._webglScene.keyPressed = {};
                        if (this.settings.interactiveProject && this._interactiveProjectHandler) {
                            this._interactiveProjectHandler._clearHovering();
                        }
                        this._webglScene.render();
                    }
                }
            }

            this.bindedElement.addEventListener("mouseleave", this.mouseleaveHandler);

            this.mouseoverHandler = (event: MouseEvent) => {
                if (this.noFocus) {
                    return;
                }

                if (!this._mouseOver) {
                    this._mouseOver = true;
                    this.divWebgl.focus();
                }
                if (this._dragState) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.DRAG_OVER_WEBGL);
                }
            }

            this.bindedElement.addEventListener("mouseover", this.mouseoverHandler);

            this.mouseupHandler = (event: MouseEvent) => {
                event.stopPropagation();

                this._mouseDownEvent = null;

                if (!this._webglScene) {
                    return;
                }

                if (this._webglScene && this._webglScene.renderer) {
                    if (event.target !== this._webglScene.renderer.domElement && this.zoom === 1) {
                        return;
                    }
                }

                if (!this._mouseOver) {
                    return;
                }

                if (!this.controls) {
                    return;
                }

                switch (event.button) {
                    case 1:
                    case 2:
                        if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                            PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, false));
                        }
                        break;
                }

                this.bindedElement.style.cursor = 'default';
                switch (event.button) {
                    case 0:
                        if (this._dragState) {
                            switch (this._dragState.state) {
                                case DRAG_STATE.COATING:
                                    this._webglScene.setCoatingOnDrop((event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                                        (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                                        this._dragState.value, false);
                                    break;
                                case DRAG_STATE.ENTITY:
                                    this._webglScene.setEntityOnDrop((event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                                        (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                                        this._dragState.value);
                                    break;
                            }
                            this._dragState = null;
                        }
                    case 2:
                        this._dragState = null;
                        break;
                }

                if (typeof PlanManager !== 'undefined') {
                    let selection = PlanManager.getInstance().selection.slice();
                    selection = selection.filter(function (item) {
                        return this._webglScene.interactionWithItemAllowed(item);
                    }.bind(this));
                    if (event.ctrlKey && selection.length === 0) {
                        this._webglScene.selectionBox.endPoint.set(
                            (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                            (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                            0.5
                        );
                        this._webglScene.setBoxSelection();
                        return;
                    }
                }

                Savane.eventsManager.instance.dispatch(MOUSE_EVENTS.UP, {
                    x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                    y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1,
                    offsetX: event.offsetX,
                    offsetY: event.offsetY,
                    button: event.button
                });

                this._webglScene.updateEnvs();
                this._webglScene.render();
            }

            this.bindedElement.addEventListener("mouseup", this.mouseupHandler);

            this.wheelHandler = (event: WheelEvent) => {
                event.preventDefault();
                if (!this._webglScene) {
                    return;
                }

                if (!this.controls) {
                    return;
                }

                if (!this.loaded) {
                    return;
                }

                let deltaY = event.deltaY;
                if (!deltaY) {
                    return;
                }

                deltaY /= 100;

                if (event.altKey && this._webglScene &&
                    this._webglScene.camera && (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.PhotoRender ||
                        this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Perspective ||
                        this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Storage)) {
                    this.zoom -= deltaY * 0.1;
                    if (this.zoom < 1) {
                        this.zoom = 1;
                    }
                    this._webglScene.renderer.domElement.setAttribute("style", "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin);
                    this._delegate.render();
                    return;
                }

                // start temporary for camera update
                if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                    this._webglScene.camera.entity.startTemporary();
                }

                this._webglScene.stopEnvUpdate();
                this._webglScene.dollyCamera((deltaY / (event.target as HTMLElement).clientHeight) * 4000, {
                    x: (event.offsetX / (event.target as HTMLElement).clientWidth) * 2 - 1,
                    y: (((event.target as HTMLElement).clientHeight - event.offsetY) / (event.target as HTMLElement).clientHeight) * 2 - 1
                });
                this._webglScene.render();

                // end temporary after 800ms for camera update
                if (this.editRenderCameraCommandTimeout) {
                    clearTimeout(this.editRenderCameraCommandTimeout);
                }
                this.editRenderCameraCommandTimeout = setTimeout(() => {
                    if (this._webglScene && this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                        PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, false));
                    }
                    this.editRenderCameraCommandTimeout = null;
                }, 800);
            }

            this.bindedElement.addEventListener("wheel", this.wheelHandler);

            this.keydownHandler = (event: KeyboardEvent) => {
                this.currentKeyPressed = event.keyCode;
                if (!this._webglScene) {
                    return;
                }

                this._webglScene.keyPressed.shift = event.shiftKey;
                this._webglScene.keyPressed.ctrl = event.ctrlKey;
                if (this.currentKeyPressed === 88) {
                    this._webglScene.keyPressed.x = true;
                }
                if (this.currentKeyPressed === 82) {
                    this._webglScene.keyPressed.r = true;
                }
                if (this.currentKeyPressed === 70) {
                    this._webglScene.keyPressed.f = true;
                }

                if (this.settings.interactiveProject && this._interactiveProjectHandler) {
                    this._interactiveProjectHandler.HandleKeyboard(event);
                }

                if (!this.controls) {
                    return;
                }

                if (!this._mouseOver) {
                    return;
                }

                if (!this.loaded) {
                    return;
                }

                this._webglScene.stopEnvUpdate();

                // Camera shortcuts
                if ((event.keyCode === 65 || event.keyCode === 80 || event.keyCode === 72 || event.keyCode === 82 || event.keyCode === 86 || event.keyCode === 67) && event.shiftKey) {
                    this._changeCamera(event.keyCode);
                }

                switch (event.keyCode) {
                    case 13:
                        if (this._webglScene.camera && typeof PlanManager !== 'undefined') {
                            if (this._webglScene.camera.entity.cameraType === SavaneJS.SceneConstants.CameraType.Video && this._videoPreviewIntervalId === null) {
                                const allArrangements = PlanManager.getInstance().world.currentScene.arrangementObjects;

                                for (let i = 0; i < allArrangements.length; ++i) {
                                    const entity = this._webglScene.getPlanEntity(allArrangements[i].id);
                                    if (!entity) {
                                        continue;
                                    }

                                    this._webglScene.setLayer(entity.object, 1);
                                }

                                this._videoPreviewNbUnhiddenObjects = 0;
                                this._videoPreviewCurrentImage = 0;
                                this._videoPreviewIntervalId = setInterval(this._videoPreviewStep, 200);
                            }
                        }
                        break;
                    case 90: //z
                        if (!event.ctrlKey) {
                            if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                                this._webglScene.camera.entity.startTemporary();
                                this._webglScene.targetCamera((this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth) * 2 - 1, ((this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight) * 2 - 1);
                                PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, false));
                            }
                            else if (!this._webglScene.camera) {
                                // update free camera target
                                this._webglScene.targetCamera((this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth) * 2 - 1, ((this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight) * 2 - 1);
                            }
                        }
                        if (event.ctrlKey) {
                            this._webglScene.undoAction();
                        }
                        break;
                    case 89: //y
                        if (event.ctrlKey) {
                            this._webglScene.redoAction();
                        }
                        break;
                    case 40: //down
                        if (this._bPressed) {
                            if (typeof PlanManager !== 'undefined') {
                                const selection = PlanManager.getInstance().selectedEntities;
                                const newObjectsAndParents: Array<{entity: SavaneJS.Entity, parent: SavaneJS.Entity}> = [];
                                const oldObjects: Array<SavaneJS.Entity> = [];

                                for (let i = 0; i < selection.length; i++) {
                                    if (selection[i].isArrangementGroupEntity()) {
                                        if (selection[i].masterObjectId) {
                                            for (let j = 0; j < selection[i].children.length; j++) {
                                                if (selection[i].children[j].objectId === selection[i].masterObjectId) {
                                                    oldObjects.push(selection[i]);
                                                    newObjectsAndParents.push({ entity: selection[i].children[j], parent: selection[i].parent });
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (oldObjects.length > 0) {
                                    PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                                }
                            }
                        }
                        else {
                            if (event.ctrlKey) {
                                this._webglScene.snapSelectionDown();
                            }
                            if (this._webglScene.gizmo['enabled']) {
                                if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.Bottom) {
                                    this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Center;
                                } else {
                                    this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Bottom;
                                }
                                this._webglScene.setGizmoPosition(true);
                            }
                        }
                        break;
                    case 38: //up
                        if (event.ctrlKey) {
                            this._webglScene.snapSelectionUp();
                        }
                        if (this._webglScene.gizmo['enabled']) {
                            if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.Top) {
                                this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Center;
                            } else {
                                this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Top;
                            }
                            this._webglScene.setGizmoPosition(true);
                        }
                        break;
                    case 68: //d
                        if (event.ctrlKey && event.altKey) {
                            if (typeof PlanManager !== 'undefined') {
                                CocosUtils.cleanWorldTemplates();
                            }
                        }
                        else {
                            if (!event.ctrlKey && !event.altKey) {
                                this._webglScene.setCameraDof((this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth) * 2 - 1, ((this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight) * 2 - 1);
                                if (this._webglScene.camera && typeof PlanManager !== 'undefined') {
                                    PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, true));
                                }
                            }
                        }
                        break;
                    case 39: //right
                        if (this._bPressed) {
                            if (typeof PlanManager !== 'undefined') {
                                const selection = PlanManager.getInstance().selectedEntities;
                                const newObjectsAndParents = [];
                                const oldObjects = [];

                                async.eachSeries(selection, function (entity, endCallback) {
                                    if (entity.isArrangementObjectEntity()) {
                                        AssetManagerServices.loadPopulatedObjects(entity, oldObjects, newObjectsAndParents, function (displayWarning) {
                                            this._displayWarning = displayWarning;
                                            endCallback();
                                        }.bind(this));
                                    }
                                    else if (entity.isArrangementGroupEntity()) {
                                        AssetManagerServices.switchPopulatedObjects(entity, 1, oldObjects, newObjectsAndParents, function (displayWarning) {
                                            this._displayWarning = displayWarning;
                                            endCallback();
                                        }.bind(this));
                                    } else {
                                        endCallback();
                                    }
                                }.bind(this),
                                    function () {
                                        if (oldObjects.length > 0) {
                                            PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                                        }

                                        if (this._displayWarning) {
                                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.DISPLAY_TOASTER, { message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.' });
                                        }
                                    }.bind(this));
                            }
                        }
                        else {
                            if (this._vPressed) {
                                if (typeof PlanManager !== 'undefined') {
                                    const selection = PlanManager.getInstance().selectedEntities;
                                    const newObjectsAndParents = [];
                                    const oldObjects = [];

                                    this._displayWarning = false;

                                    async.eachSeries(selection, function (entity, endCallback) {
                                        if (entity.isArrangementObjectEntity()) {
                                            if (entity.closeObjects === undefined) {
                                                this._loadCloseObjects(entity, 1, oldObjects, newObjectsAndParents, function () {
                                                    endCallback();
                                                });
                                            }
                                            else {
                                                this._switchCloseObjects(entity, 1, oldObjects, newObjectsAndParents, function () {
                                                    endCallback();
                                                });
                                            }
                                        } else {
                                            endCallback();
                                        }
                                    }.bind(this),
                                        function (err) {
                                            if (oldObjects.length > 0) {
                                                PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                                            }

                                            if (this._displayWarning) {
                                                Savane.eventsManager.instance.dispatch(SavaneJS.Events.DISPLAY_TOASTER, { message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.' });
                                            }
                                        }.bind(this));
                                }
                            }
                            else {
                                if (this._webglScene.gizmo['enabled']) {
                                    if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.Center || this._webglScene.gizmoPosition === WebglScene.GizmoPosition.xLeft || this._webglScene.gizmoPosition === WebglScene.GizmoPosition.yLeft) {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.xRight;
                                    } else if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.xRight) {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.yRight;
                                    } else {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Center;
                                    }
                                    this._webglScene.setGizmoPosition(true);
                                }
                            }
                        }
                        break;
                    case 37: //left
                        if (this._bPressed) {
                            if (typeof PlanManager !== 'undefined') {
                                const selection = PlanManager.getInstance().selectedEntities;
                                const newObjectsAndParents = [];
                                const oldObjects = [];

                                async.eachSeries(selection, function (entity, endCallback) {
                                    if (entity.isArrangementObjectEntity()) {
                                        AssetManagerServices.loadPopulatedObjects(entity, oldObjects, newObjectsAndParents, function (displayWarning) {
                                            this._displayWarning = displayWarning;
                                            endCallback();
                                        }.bind(this));
                                    }
                                    else if (entity.isArrangementGroupEntity()) {
                                        AssetManagerServices.switchPopulatedObjects(entity, -1, oldObjects, newObjectsAndParents, function (displayWarning) {
                                            this._displayWarning = displayWarning;
                                            endCallback();
                                        }.bind(this));
                                    } else {
                                        endCallback();
                                    }
                                }.bind(this),
                                    function () {
                                        if (oldObjects.length > 0) {
                                            PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                                        }

                                        if (this._displayWarning) {
                                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.DISPLAY_TOASTER, { message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.' });
                                        }
                                    }.bind(this));
                            }
                        }
                        else {
                            if (this._vPressed) {
                                if (typeof PlanManager !== 'undefined') {
                                    const selection = PlanManager.getInstance().selectedEntities;
                                    const newObjectsAndParents = [];
                                    const oldObjects = [];

                                    this._displayWarning = false;
                                    async.eachSeries(selection, function (entity, endCallback) {
                                        if (entity.isArrangementObjectEntity()) {
                                            if (entity.closeObjects === undefined) {
                                                this._loadCloseObjects(entity, -1, oldObjects, newObjectsAndParents, function () {
                                                    endCallback();
                                                });
                                            }
                                            else {
                                                this._switchCloseObjects(entity, -1, oldObjects, newObjectsAndParents, function () {
                                                    endCallback();
                                                });
                                            }
                                        } else {
                                            endCallback();
                                        }
                                    }.bind(this),
                                        function (err) {
                                            if (oldObjects.length > 0) {
                                                PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                                            }

                                            if (this._displayWarning) {
                                                Savane.eventsManager.instance.dispatch(SavaneJS.Events.DISPLAY_TOASTER, { message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.' });
                                            }
                                        }.bind(this));
                                }
                            }
                            else {
                                if (this._webglScene.gizmo['enabled']) {
                                    if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.Center || this._webglScene.gizmoPosition === WebglScene.GizmoPosition.xRight || this._webglScene.gizmoPosition === WebglScene.GizmoPosition.yRight) {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.xLeft;
                                    } else if (this._webglScene.gizmoPosition === WebglScene.GizmoPosition.xLeft) {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.yLeft;
                                    } else {
                                        this._webglScene.gizmoPosition = WebglScene.GizmoPosition.Center;
                                    }
                                    this._webglScene.setGizmoPosition(true);
                                }
                            }
                        }
                        break;
                    case 46: //delete
                        this._webglScene.deleteSelectedEntities();
                        break;
                    case 83: //s
                        this._webglScene.toggleSnap();
                        break;
                    case 82: //r
                        if (!this._dragState) {
                            if (event.shiftKey) {
                                this._webglScene.duplicateEntitiesOffset();
                            }
                            else if (this._webglScene.gizmo.mode === 'scale' && this._webglScene.gizmo['enabled']) {
                                this._webglScene.gizmo['enabled'] = false;
                                this._webglScene.gizmo.visible = false;
                                this._webglScene.gizmo.space = 'world';
                            } else {
                                this._webglScene.gizmo.setMode("scale");
                                this._webglScene.gizmo['enabled'] = true;
                                this._webglScene.gizmo.visible = true;
                                this._webglScene.gizmo.space = 'local';
                            }
                            this._delegate.render();
                        }
                        break;
                    case 70: //f
                        if (this._loadedEntity) {
                            if (Array.isArray(this._loadedEntity)) {
                                for (let i = 0; i < this._loadedEntity.length; ++i) {
                                    this._webglScene.fitCamera(this._loadedEntity[i], i);
                                }
                            } else {
                                this._webglScene.fitCamera(this._loadedEntity, 0);
                            }
                        }
                        break;
                    case 48: // zero
                    case 96: // zero numpad
                        this._webglScene.resetCameraPitch();
                        break;
                    case 71: // g
                        if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().selectedEntities.length > 1) {
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.GROUP_ENTITIES);
                        } else {
                            this.thirdGrid = !this.thirdGrid;
                        }
                        this._delegate.render();
                        break;
                    case 73: // i
                        if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().selectedEntities.length > 1) {
                            PlanManager.getInstance().executeCommand(new Savane.Commands.SnapEntitiesCommand(PlanManager.getInstance().selectedEntities,
                                SavaneJS.Commands.SnapEntitiesCommand.SnapEnum.CenterOnTop));
                        }
                        break;
                    case 81: // q
                        const value = parseInt(SavaneJS.SavaneCookie.getCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 0));

                        if (value === 0) {
                            SavaneJS.SavaneCookie.setCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 1);
                        }
                        else {
                            SavaneJS.SavaneCookie.setCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 0);
                        }
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.ENABLE_OBJECT_FILTERS_CHANGED, { forceUpdate: true });
                        break;
                    case 80: // p
                        if (!event.shiftKey && this._webglScene.camera) {
                            this._webglScene.toggleFreeCamera();
                            this._cameraState = CAMERA_STATES.FREE;
                            this._delegate.render();
                        }
                        break;
                    case 86: // v
                        if (!event.shiftKey && !event.ctrlKey) {
                            this._vPressed = true;
                        }
                        if (event.ctrlKey) {
                            this._webglScene.pasteCoating((this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth) * 2 - 1, ((this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight) * 2 - 1);
                        }
                        break;
                    case 66: // b
                        this._bPressed = true;
                        break;
                    case 84: //t
                        cocosPosition.z = this._webglScene.getDefaultCameraDistanceFit();
                        this._webglScene.defaultCamera.position.copy(cocosPosition);
                        (this._webglScene.defaultCamera as any).target = new THREE.Vector3(cocosPosition.x, cocosPosition.y, 0);
                        this._webglScene.defaultCamera.up = new THREE.Vector3(0, 1, 0);
                        this._webglScene.defaultCamera.lookAt((this._webglScene.defaultCamera as any).target);
                        this._webglScene.defaultCameraMoved = false;

                        this._webglScene.updateCamera(null);
                        this._cameraState = CAMERA_STATES.FREE;
                        this._delegate.render();
                        break;
                    case 67: //c
                        if (event.ctrlKey) {
                            this._webglScene.copyCoating((this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth) * 2 - 1, ((this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight) * 2 - 1);
                        } else {
                            if (!event.shiftKey) {
                                if (this._enable_additionals_afters) {
                                    this._webglScene.clearExcludedObject();
                                    this._webglScene.createCameraFromFreeCamera(this.deliverable);
                                }
                            }
                        }
                        break;
                    case 65: //a
                        if (!event.shiftKey && !event.ctrlKey) {
                            this._webglScene.toggleMagnet();
                        }
                        if (event.ctrlKey) {
                            if (typeof PlanManager !== 'undefined') {
                                PlanManager.getInstance().selectAll();
                            }
                        }
                        break;
                    case 85: //u
                    if (typeof PlanManager !== 'undefined') {
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.UNGROUP_ENTITIES);
                        }
                        break;
                    case 32: //space
                        if (typeof PlanManager !== 'undefined') {
                            const selection = PlanManager.getInstance().selectedEntities;
                            let doUpdateHull = true;
                            for (let i = 0; i < selection.length; ++i) {
                                const entity = selection[i];
                                if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity()) {
                                    doUpdateHull = false;
                                    entity.locked = !entity.locked;
                                }
                            }

                            if (doUpdateHull) {
                                this._updateHulls();
                            }
                        } else {
                            this._updateHulls();
                        }
                        break;
                    case 9: //tab
                        this._changeGizmoSpace(event);
                        this._delegate.render();
                        break;
                    case 87: //w
                        if (event.ctrlKey && event.altKey) {
                            if (typeof PlanManager !== 'undefined') {
                                const selection = PlanManager.getInstance().selectedEntities;
                                this._webglScene.detachSelection();
                                for (let i = 0; i < selection.length; ++i) {
                                    const entity = selection[i];
                                    if (entity.isArrangementObjectEntity() && entity.locked !== true && entity.stretchability !== undefined && entity._excludeFromShoppingList === true) {
                                        entity.disableStretchability();

                                        const node = EntityManager.getNode(entity);
                                        if (node !== null) {
                                            node.addStretchability(false);
                                            node.updateSprite();
                                            node.needRedraw = true;
                                        }

                                        const webglEntity = this._webglScene.getPlanEntity(entity.id);
                                        if (webglEntity) {
                                            webglEntity.update();
                                        }
                                    }
                                }
                                this._webglScene.attachSelection();
                            }
                        } else {
                            if (this._webglScene.gizmo.mode === 'translate' && this._webglScene.gizmo['enabled']) {
                                this._webglScene.gizmo['enabled'] = false;
                                this._webglScene.gizmo.visible = false;
                            } else {
                                this._webglScene.gizmo.setMode("translate");
                                this._webglScene.gizmo['enabled'] = true;
                                this._webglScene.gizmo.visible = true;
                            }
                            this._delegate.render();
                        }
                        break;
                    case 88: //x
                        if (event.ctrlKey && event.altKey) {
                            if (typeof PlanManager !== 'undefined') {
                                const selection = PlanManager.getInstance().selectedEntities;
                                this._webglScene.detachSelection();
                                for (let i = 0; i < selection.length; ++i) {
                                    const entity = selection[i];
                                    if (entity.isArrangementObjectEntity() && entity.locked !== true && entity.stretchability === undefined) {
                                        entity.enableStretchability();

                                        const node = EntityManager.getNode(entity);
                                        if (node !== null) {
                                            node.addStretchability(true);
                                            node.updateSprite();
                                            node.needRedraw = true;
                                        }

                                        const webglEntity = this._webglScene.getPlanEntity(entity.id);
                                        if (webglEntity) {
                                            webglEntity.update();
                                        }
                                    }
                                }
                                this._webglScene.attachSelection();
                            }
                        }
                        break;
                    case 69: //e
                        if (this._webglScene.gizmo.mode === 'rotate' && this._webglScene.gizmo['enabled']) {
                            this._webglScene.gizmo['enabled'] = false;
                            this._webglScene.gizmo.visible = false;
                        } else {
                            this._webglScene.gizmo.setMode("rotate");
                            this._webglScene.gizmo['enabled'] = true;
                            this._webglScene.gizmo.visible = true;
                        }
                        this._delegate.render();
                        break;
                    case 79: //o
                        // Toggle Holdout
                        if (typeof PlanManager !== 'undefined') {
                            const selection = PlanManager.getInstance().selectedEntities;
                            for (let i = 0; i < selection.length; ++i) {
                                let entity = selection[i];
                                entity.holdout = !entity.holdout;
                                if (this._webglScene) {
                                    this._webglScene.updateHull(entity);
                                }
                            }
                        }
                        break;
                    case 112: //F1
                        if (typeof PlanManager !== 'undefined') {
                            PlanManager.getInstance()._hideArrangements = !PlanManager.getInstance()._hideArrangements;
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_ARRANGEMENTS);
                        }
                        event.preventDefault();
                        break;
                    case 113: //F2
                        if (typeof PlanManager !== 'undefined') {
                            PlanManager.getInstance()._hideAxo = !PlanManager.getInstance()._hideAxo;
                            Savane.eventsManager.instance.dispatch(SavaneJS.Events.HIDE_AXO);
                        }
                        break;
                    case 114: //F3
                        if (this._webglScene) {
                            this._webglScene.hullTransparency = !this._webglScene.hullTransparency;
                            this._webglScene.render();
                        }
                        event.preventDefault();
                        break;
                    case 115: //F4
                        this._togglePhysics(event);
                        this._delegate.render();
                        break;
                    case 120: //F9
                        if (this._webglScene) {
                            this._webglScene.renderPass.enabled = !this._webglScene.renderPass.enabled;
                            this._webglScene.outlinePass.enabled = !this._webglScene.outlinePass.enabled;
                            this._webglScene.composer.reset();
                        }
                        break;
                    case 121: //F10
                        if (this._webglScene) {
                            const strMime = "image/png";
                            const strDownloadMime = "image/octet-stream";

                            const imgData = this._webglScene.renderer.domElement.toDataURL(strMime);
                            const data = imgData.replace(strMime, strDownloadMime)
                            const link = document.createElement('a');
                            document.body.appendChild(link); //Firefox requires the link to be in the body
                            link.download = "capture.png";
                            link.href = data;
                            link.click();
                            document.body.removeChild(link);
                        }
                        break;
                    case 72: //h
                        if (this._webglScene && !event.shiftKey) {
                            this._webglScene.displayHeight = !this._webglScene.displayHeight;
                            this._delegate.render();
                        }
                        break;
                    case 75: //k
                        break;
                    case 123: //F12
                        if (this._webglScene) {
                            this._webglScene.stats.showPanel(0);
                        }
                        break;
                    case 187: // +
                        if (this.photoOpacity < 0.8) {
                            this.photoOpacity += 0.1;
                            this._delegate.render();
                        }
                        break;
                    case 219: // -
                        if (this.photoOpacity > 0.2) {
                            this.photoOpacity -= 0.1;
                            this._delegate.render();
                        }
                        break;
                    default:
                        event.preventDefault();
                }

                //number 1 -> 9
                if (event.keyCode >= 49 && event.keyCode <= 57) {
                    if (this.displayPhoto) {
                        setTimeout(() => { 
                            this.displayPhoto = false; 
                            this._delegate.render();
                        });
                    } else {
                        this._setCurrentPhoto(event.keyCode - 49);
                    }
                }

                this._webglScene.render();
            }

            this.keyupHandler = (event: KeyboardEvent) => {
                this.currentKeyPressed = null;
                if (!this._webglScene) {
                    return;
                }

                if (!this.loaded) {
                    return;
                }

                this._webglScene.keyPressed.shift = event.shiftKey;
                this._webglScene.keyPressed.ctrl = event.ctrlKey;

                switch (event.keyCode) {
                    case 88:
                        this._webglScene.keyPressed.x = false;
                        break;
                    case 82:
                        this._webglScene.keyPressed.r = false;
                        break;
                    case 70:
                        this._webglScene.keyPressed.f = false;
                        break;
                    case 66:
                        this._bPressed = false;
                        break;
                    case 86:
                        this._vPressed = false;
                        break;
                }

                if (!this.controls) {
                    return;
                }

                if (!this._mouseOver) {
                    return;
                }

                this._webglScene.updateEnvs();
            }

            document.addEventListener("keydown", this.keydownHandler);
            document.addEventListener("keyup", this.keyupHandler);

            this.initialize();

            window.addEventListener('resize', this._resize);

            let destroyObserver = new MutationObserver((mutationsList, observer) => {
                mutationsList.forEach((mutation) => {
                    if (mutation.type === 'childList' && Array.from(mutation.removedNodes).includes(this.bindedElement)) {
                        this.destroy();
                    }
                });
            });

            let observerConfig = { childList: true };

            destroyObserver.observe(document.body, observerConfig);

            if (callback) {
                callback();
            }

        });

    }

}
