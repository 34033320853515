import {
    Color,
    MeshDepthMaterial,
	MeshNormalMaterial,
    NoBlending,
    RGBADepthPacking,
    RGBAFormat,
	ShaderMaterial,
	UniformsUtils,
    Vector2,
    WebGLRenderTarget
} from 'three';

import { Pass, FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass.js';
import { CelShadingShader } from '../shaders/CelShadingShader'

class CelShadingPass extends Pass {

	constructor( scene, camera, resolution = new Vector2( 256, 256 ) ) {

		super();

		if ( CelShadingShader === undefined ) {

			console.error( 'THREE.CelShadingPass relies on CelShadingShader' );

		}

        this.scene = scene;
		this.camera = camera;
        this.resolution = new Vector2( resolution.x, resolution.y );

        this.originalClearColor = new Color();

        this.depthMaterial = new MeshDepthMaterial();
		this.depthMaterial.depthPacking = RGBADepthPacking;
		this.depthMaterial.blending = NoBlending;

		this.normalMaterial = new MeshNormalMaterial();

        this.depthRenderTarget = new WebGLRenderTarget( this.resolution.x, this.resolution.y, {
			generateMipmaps: false,
			stencilBuffer: false,
			format: RGBAFormat,
			samples: 2
		} );

		this.normalRenderTarget = new WebGLRenderTarget( this.resolution.x, this.resolution.y, {
			generateMipmaps: false,
			stencilBuffer: false,
			format: RGBAFormat,
			samples: 2
		} );

        this.uniforms = UniformsUtils.clone( CelShadingShader.uniforms );

        this.material = new ShaderMaterial( {

            defines: Object.assign( {}, CelShadingShader.defines ),
            uniforms: this.uniforms,
            vertexShader: CelShadingShader.vertexShader,
            fragmentShader: CelShadingShader.fragmentShader

        } );

        this.material.uniforms[ 'tDepth' ].value = this.depthRenderTarget.texture;
		this.material.uniforms[ 'tNormal' ].value = this.normalRenderTarget.texture;
        this.material.uniforms[ 'resolution' ].value.set( resolution.x, resolution.y );
        this.material.uniforms[ 'cameraNearFar' ].value.set( this.camera.near, this.camera.far );

		this.fsQuad = new FullScreenQuad( this.material );

	}

    dispose() {

        this.depthMaterial.dispose();
		this.normalRenderTarget.dispose();
        this.depthRenderTarget.dispose();
		this.depthMaterial.dispose();
		this.normalMaterial.dispose();
        this.material.dispose();
        this.fsQuad.dispose();

    }

	render( renderer, writeBuffer, readBuffer ) {

        // Rendering scene to depth texture
        this.renderOverride( renderer, this.depthMaterial, this.depthRenderTarget, 0x000000, 1.0 );

		// Rendering scene to normal texture
		this.renderOverride( renderer, this.normalMaterial, this.normalRenderTarget, 0x000000, 1.0 );

        this.uniforms[ 'tDiffuse' ].value = readBuffer.texture;

		this.fsQuad.material = this.material;

		if ( this.renderToScreen ) {

			renderer.setRenderTarget( null );
			this.fsQuad.render( renderer );

		} else {

			renderer.setRenderTarget( writeBuffer );
			this.fsQuad.render( renderer );

		}

	}

    setSize( width, height ) {

		this.depthRenderTarget.setSize( width, height );
		this.normalRenderTarget.setSize( width, height );

		this.material.uniforms[ 'resolution' ].value.set( width, height );
		this.material.needsUpdate = true;

	}

    renderOverride( renderer, overrideMaterial, renderTarget, clearColor, clearAlpha ) {

		renderer.getClearColor( this.originalClearColor );
		const originalClearAlpha = renderer.getClearAlpha();
		const originalAutoClear = renderer.autoClear;

		renderer.setRenderTarget( renderTarget );
		renderer.autoClear = false;

		clearColor = overrideMaterial.clearColor || clearColor;
		clearAlpha = overrideMaterial.clearAlpha || clearAlpha;
		if ( ( clearColor !== undefined ) && ( clearColor !== null ) ) {

			renderer.setClearColor( clearColor );
			renderer.setClearAlpha( clearAlpha || 0.0 );
			renderer.clear();

		}

		this.scene.overrideMaterial = overrideMaterial;
		renderer.render( this.scene, this.camera );
		this.scene.overrideMaterial = null;

		// restore original state
		renderer.autoClear = originalAutoClear;
		renderer.setClearColor( this.originalClearColor );
		renderer.setClearAlpha( originalClearAlpha );

	}

}

export { CelShadingPass };
