import * as SavaneJS from '@rhinov/savane-js';
import { InteractiveProjectHandler } from '../interactiveProjectHandler';
import { _ASSET_TYPE } from '@rhinov/asset-manager-service';

declare var AssetManagerServices;
declare var Savane;

export class ReplaceKitchenCoatingCommand extends SavaneJS.Commands.Command {
    
    private _handler: any;
    private _entity: any;
    private _amCoating: any;
    private _customName: any;
    private _topBottoms: any;
    private _topBottomsConstrained: any;
    private _commands: any[];

    constructor(handler: InteractiveProjectHandler, entity: SavaneJS.ArrangementObject, replaceBy: any /**AM Coating*/, topBottoms, topBottomsConstrained) {
        super();

        this._handler = handler;
        this._entity = entity;
        this._amCoating = replaceBy.coating;
        this._customName = replaceBy.partType;
        this._topBottoms = topBottoms;
        this._topBottomsConstrained = topBottomsConstrained;

        this._commands = [];
    }

    name = () : string => {
        return "ReplaceKitchenCoatingCommand";
    }

    assetType = () : _ASSET_TYPE => {
        return _ASSET_TYPE.COATINGS;
    }

    undo = () : void => {
        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].undo();
        }

        Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_ARRANGEMENT, { entity: this._entity, isKitchenEntity: true, point: null });
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: null });
    }

    redo = () : void => {
        this._exec(true);
    }

    get _toReplace() : SavaneJS.Coating {
        if (this._commands.length > 0) {
            var item = this._commands[0]._modifiedEntities[0];
            var coatings = item.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating);
            var hangType = SavaneJS.Commands.ChangeKitchenCoatingCommand.getHangType(this._customName);
            for (var i = 0; i < coatings.length; ++i) {
                if (coatings[i].hangType === hangType) {
                    return coatings[i];
                }
            }
        }

        return null;
    }

    get _replaceBy() : { _coatingId: string } {
        return { _coatingId: this._amCoating._id };
    }

    execute = () : void => {
        this._exec(false);
    }

    _exec = (redo: boolean) => {
        var objectTypeList = SavaneJS.SceneConstants.ArrangementType.kitchenCustomizableArrangements
        this._commands.push(new Savane.Commands.ChangeKitchenCoatingCommand([this._entity], this._amCoating, this._customName, objectTypeList, this._topBottoms, this._topBottomsConstrained))

        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].execute();
        }
        
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_ARRANGEMENT, { entity: this._entity, isKitchenEntity: true, point: null });
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: redo ? null : this });
    }

}
