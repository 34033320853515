import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { WebglScene } from '../scene';
import { Cleaner } from '../helpers/cleaner';
import { Material } from '../helpers/material';
import { WebglHullEntity } from '../hullEntity';
import { OBJLoader } from '../obj/OBJLoader.js';
import { BINLoader } from '../obj/BINLoader.js';

export class FloorGeneratorHull {

    public group: THREE.Group;
    public hull: THREE.Group;

    private savane: SavaneJS.Scene;
    private scene: WebglScene;
    private _entity: SavaneJS.Entity | null = null;

    constructor(obj: string, savane: SavaneJS.Scene, scene: WebglScene) {
        this.savane = savane;
        this.scene = scene;
        let loader;
        if (!scene.settings.hullbin) {
            loader = new OBJLoader();
            this.hull = loader.parse(obj);
        }
        else {
            loader = new BINLoader();
            this.hull = loader.parse(obj).object;
        }
        this.group = new THREE.Group();
        this.group.add(this.hull);
        //updated entity
        this._entity = null;
        this._process();
        this.hideCeiling();
    }

    static create(obj: string, savane: SavaneJS.Scene, scene: WebglScene) {
        return new FloorGeneratorHull(obj, savane, scene);
    }

    dispose() : void {
        let traversing = function(child: THREE.Mesh) {
            if (child.geometry) {
                child.geometry.dispose();
            }
            if ((child as any).body) {
                this.scene.physics.destroy((child as any).body);
            }
            if (child.material) {
                Cleaner.cleanMaterial(child.material);
            }
            if ((child as any).cubeCamera) {
                (child as any).cubeCamera.renderTarget.dispose();
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    showCeiling() : void {
        let traversing = function(child: THREE.Object3D) {
            if (child.name.startsWith("axo")) {
                child.castShadow = true;
                child.receiveShadow = true;
                child.layers.set(0);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    hideCeiling() : void {
        let traversing = function(child: THREE.Object3D) {
            // Ceiling always hidden
            let hide = false;
            if (child.name.startsWith("axo")) {
                hide = true;
            }

            if (hide === true) {
                child.castShadow = false;
                child.receiveShadow = false;
                child.layers.set(1);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    showUpperFloor(floor: SavaneJS.Floor) : void {
        if (!floor) return;

        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(0);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    hideUpperFloor(floor: SavaneJS.Floor) : void {
        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(1);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    update(entity: SavaneJS.Entity) : void {
        this._entity = entity;
        this.hull.traverse(this._processChild.bind(this));
        this._entity = null;
    }

    updateCoatingParameters(entity: SavaneJS.Entity) {
        let traversing = function(child: THREE.Mesh) {
            if (child.name.startsWith("Floor") || child.name.startsWith("axo_Floor")) {
                //add node to wall
                let floorParse = child.name.split('_');
                
                let parentId = Number(floorParse[1]);
                if (child.name.startsWith("axo_Floor")) {
                    parentId = Number(floorParse[2]);
                }

                if (parentId === entity.id) {
                    let parent = this.savane.getDeepChild(parentId);

                    if (parent !== null) {
                        if (parent.isRoomEntity()) {
                            let coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating);
                            let hangType = SavaneJS.Coating.HangType.floor;
                            if (child.name.startsWith("axo_FloorCeiling")) {
                                hangType = SavaneJS.Coating.HangType.ceiling;
                            }

                            if (coatings !== null) {
                                for (let i = 0; i < coatings.length; i++) {
                                    if (coatings[i].hangType === hangType) {
                                        let material = child.material as THREE.MeshPhongMaterial;
                                        if (material.map) {
                                            material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                            material.map.offset.x = (coatings[i].offset[0]);
                                            material.map.offset.y = (coatings[i].offset[1]);
                                            material.map.repeat.x = (material.map as any).originalRepeat.x * (coatings[i].repeat[0]);
                                            material.map.repeat.y = (material.map as any).originalRepeat.y * (coatings[i].repeat[1]);
                                        }
                                    }
                                }
                            }
                        } else if (parent.isFloorEntity()) {
                            let coatingAreaNb = -1;
                            if (child.name.startsWith("axo_Floor")) {
                                coatingAreaNb = Number(floorParse[3]) - 1;
                            } else {
                                coatingAreaNb = Number(floorParse[2]) - 1;
                            }
                            let coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.FloorCoatingArea);
                            if (coatings !== null) {
                                let material = child.material as THREE.MeshPhongMaterial;
                                if (material.map) {
                                    material.map.rotation = (coatings[coatingAreaNb].rotation * Math.PI) / 180;
                                    material.map.offset.x = (coatings[coatingAreaNb].offset[0]);
                                    material.map.offset.y = (coatings[coatingAreaNb].offset[1]);
                                    material.map.repeat.x = (material.map as any).originalRepeat.x * (coatings[coatingAreaNb].repeat[0]);
                                    material.map.repeat.y = (material.map as any).originalRepeat.y * (coatings[coatingAreaNb].repeat[1]);
                                }
                            }

                        }
                    }
                }
            }
        };

        this.hull.traverse(traversing.bind(this));
    }

    filterFloor(floor: SavaneJS.Floor) : void {
        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (floor !== null) {
                if (child.name.startsWith("InterFloor")) {
                    child.layers.set(1);
                }
                if (entity !== null && (floor.getDeepChild(entity.id) || entity === floor)) {
                    child.layers.set(0);
                } else {
                    child.layers.set(1);
                }
            } else {
                child.layers.set(0);
            }

        }
        this.hull.traverse(traversing.bind(this));
    }

    static getIdFromChild(child: THREE.Object3D) : number | null {
        let result: number | null = null;

        let parse = child.name.split('_');
        if (child.name.startsWith("FloorPlinth")) {
            result = Number(parse[2]);
        } else if (child.name.startsWith("Floor")) {
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_Floor")) {
            result = Number(parse[2]);
        } else if (child.name.startsWith("InterFloor")) {
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_InterFloor")) {
            result = Number(parse[2]);
        } else if (child.name.startsWith("Step")) {
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_Step")) {
            result = Number(parse[2]);
        } else if (child.name.startsWith("CounterStep")) {
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_CounterStep")) {
            result = Number(parse[2]);
        } else if (child.name.startsWith("BackStep")) {
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_BackStep")) {
            result = Number(parse[2]);
        }

        return result ? Math.abs(result) : null;
    }

    getChildsFromId(id: number) : Array<THREE.Object3D> {
        let result: Array<THREE.Object3D> = [];
        let traversing = function(child: THREE.Object3D) {
            if (id === FloorGeneratorHull.getIdFromChild(child)) {
                result.push(child);
            }
        }
        this.hull.traverse(traversing.bind(this));
        return result;
    }

    getEntityFromChild(child: THREE.Object3D) : SavaneJS.Entity |null {
        let id = FloorGeneratorHull.getIdFromChild(child);
        if (id) {
            return this.savane.getDeepChild(id);
        }
        return null;
    }

    _process() : void {
        this.hull.traverse(this._processChild.bind(this));
    }

    _handleInterFloor(child: THREE.Mesh) : void {
        if (!child.name.startsWith("InterFloor")) {
            return;
        }

        // Default white color assignment to interfloors
        child.material = Material.default(new THREE.Color(0x505050).convertSRGBToLinear(), null, {
            
        });
    }

    _handleFloor(child: THREE.Mesh, entity: SavaneJS.Entity) : void {
        if (!(child.name.startsWith("Floor") || child.name.startsWith("axo_Floor") ||
              child.name.startsWith("Step") || child.name.startsWith("axo_Step") ||
              child.name.startsWith("CounterStep") || child.name.startsWith("axo_CounterStep") ||
              child.name.startsWith("BackStep") || child.name.startsWith("axo_BackStep"))) {
            return;
        }

        //add node to wall
        let floorParse = child.name.split('_');
        let parentId = Number(floorParse[1]);
        if (child.name.startsWith("axo_Floor")) {
            parentId = Number(floorParse[2]);
        }
        let slope = child.name.startsWith("axo_FloorSlope");
        let parent = this.savane.getDeepChild(parentId);

        if (!parent) {
            return;
        }

        if (entity && entity.id !== parentId) {
            return;
        }

        let { coatings, config, hangType, coatingAreaNb } = this.getCoatingParameters(child, parent);
        let found = false;

        if (coatings !== null) {
            // Assign material to mesh
            if (parent.isRoomEntity()) {
                hangType = SavaneJS.Coating.HangType.floor;
                if (child.name.startsWith("axo_FloorCeiling") || child.name.startsWith("FloorPoolEdge")) {
                    hangType = SavaneJS.Coating.HangType.ceiling;
                }

                // Handle wall Plinth hang type override
                if (child.name.startsWith("FloorPlinth")) {
                    let wall = this.savane.getDeepChild(Number(floorParse[2]));
                    if (wall) {
                        let wallCoatings = wall.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;
                        for (let i = 0; i < wallCoatings.length; i++) {
                            if (wallCoatings[i].hangType === hangType) {
                                coatings = wallCoatings;
                            }
                        }
                    }
                }
                for (let i = 0; i < coatings.length; i++) {
                    if (coatings[i].hangType === hangType) {
                        Material.setMaterialFromCoating(child, coatings[i], true, config, this.scene, false, null);
                        (child as any).coatingId = coatings[i].coatingId;
                        if (coatings[i].floorGeneratorSettings && coatings[i].floorGeneratorSettings.jointCoating && !config) {
                            child.material = Material.default(new THREE.Color(coatings[i].floorGeneratorSettings.jointCoating.colors[0].realColor).convertSRGBToLinear(), null, {
                                
                            });
                        }
                        found = true;
                        break;
                    }
                }
            }
            else if (parent.isWallEntity()) {
                if ((coatings !== null) && (coatings.length > 0)) {
                    for (let i = 0; i < coatings.length; i++) {
                        if (slope) {
                            if (coatings[i].hangType === hangType) {
                                Material.setMaterialFromCoating(child, coatings[i], true, config, this.scene, false, null);
                                (child as any).coatingId = coatings[i].coatingId;
                                found = true;
                                break;
                            }
                        } else {
                            if (coatings[i].hangType === hangType) {
                                Material.setMaterialFromCoating(child, coatings[i], true, config, this.scene, false, null);
                                (child as any).coatingId = coatings[i].coatingId;
                                found = true;
                                break;
                            }
                        }
                    }
                }
            }
            else if (parent.isFloorEntity()) {
                Material.setMaterialFromCoating(child, coatings[coatingAreaNb], true, config, this.scene, false, null);
                (child as any).coatingId = coatings[coatingAreaNb].coatingId;
                if (coatings[coatingAreaNb].floorGeneratorSettings && coatings[coatingAreaNb].floorGeneratorSettings.jointCoating && !config) {
                    child.material = Material.default(new THREE.Color(coatings[coatingAreaNb].floorGeneratorSettings.jointCoating.colors[0].realColor).convertSRGBToLinear(), null, {
                        
                    });
                }
                found = true;
            }
            else if (parent.isGeometryPrimitiveEntity()) {
                for (let i = 0; i < coatings.length; i++) {
                    if (coatings[i].hangType === SavaneJS.Coating.HangType.technicalElement) {
                        Material.setMaterialFromCoating(child, coatings[i], true, config, this.scene, false, null);
                        (child as any).coatingId = coatings[i].coatingId;
                        found = true;
                        break;
                    }
                }
            }
            else if (parent.isStaircaseEntity()) {
                for (let i = 0; i < coatings.length; i++) {
                    Material.setMaterialFromCoating(child, coatings[i], true, config, this.scene, false, null);
                    (child as any).coatingId = coatings[i].coatingId;
                    found = true;
                    break;
                }
            }
        }

        // Coating not found, assign default material
        if (!found) {
            // Default color assignment when no coating
            child.material = Material.default(new THREE.Color(0xffffff), null, {
                
            });
        }

        if (!entity && (parent.isGeometryPrimitiveEntity() || parent.isWallEntity() || (parent.isRoomEntity() && child.name.startsWith("axo_FloorCeiling")))) {
            if (child.isMesh) {
                let self = this;
                child.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
                    if (self.scene.hullTransparency == false) {
                        material.transparent = false;
                        material.needsUpdate = true;
                        return;
                    }
                    if (camera.parent && camera.parent.type === "CubeCamera") return;

                    let direction = new THREE.Vector3();
                    camera.getWorldDirection(direction);
                    let target = (self.scene.defaultCamera as any).target;
                    for (let i = 0; i < self.scene.planCameras.length; ++i) {
                        if (camera === self.scene.planCameras[i].object) {
                            target = (self.scene.planCameras[i] as any).target;
                        }
                    }
                    if (!target) return;
                    let distanceToTarget = new THREE.Vector3().subVectors(target, camera.position).length();
                    let center = new THREE.Vector3();
                    if (!geometry.boundingBox) {
                        geometry.computeBoundingBox();
                    }
                    geometry.boundingBox.getCenter(center);
                    let distanceToCenter = new THREE.Vector3().subVectors(center, camera.position).length();
                    if (distanceToTarget > distanceToCenter) {
                        material.transparent = true;
                        material.opacity = 0.05;
                    } else {
                        material.transparent = false;
                    }
                    material.needsUpdate = true;
                };
            }
        }
    }

    getCoatingParameters(child: THREE.Object3D, parent: SavaneJS.Entity) : { coatings: Array<SavaneJS.Coating>, config: number | null, hangType: SavaneJS.Coating.HangType | null, coatingAreaNb: number } {
        let floorParse = child.name.split('_');

        if (!parent) {
            let parentId = Number(floorParse[1]);
            if (child.name.startsWith("axo_Floor")) {
                parentId = Number(floorParse[2]);
            }
            parent = this.savane.getDeepChild(parentId);
        }

        let coatingAreaNb: number = -1;
        let hangType: SavaneJS.Coating.HangType | null = null;
        let coatings: Array<SavaneJS.Coating> = [];
        let config: number | null = null;

        if (!parent) {
            return { coatings, config, hangType, coatingAreaNb };
        }

        if (parent.isRoomEntity()) {
            coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;
            if (floorParse.length === 5) {
                config = Number(floorParse[4]);
            }
            if (child.name.startsWith("FloorPlinth")) {
                config = Number(floorParse[9]);
                hangType = (floorParse[3] === 'Direct' ? SavaneJS.Coating.HangType.plinthDirect : SavaneJS.Coating.HangType.plinthUndirect)
            }
            if (child.name.startsWith("axo_FloorCeiling")) {
                config = Number(floorParse[6]);
            }
            if (child.name.startsWith("FloorPoolEdge")) {
                config = Number(floorParse[5]);
            }
        } else if (parent.isWallEntity()) {
            coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;
            if (child.name.startsWith("axo_Floor")) { // slope
                hangType = (floorParse[5] === 'Direct' ? SavaneJS.Coating.HangType.slopeDirect : SavaneJS.Coating.HangType.slopeUndirect);
                config = Number(floorParse[7]);
            } else {
                hangType = (floorParse[4] === 'Direct' ? SavaneJS.Coating.HangType.wallDirect:  SavaneJS.Coating.HangType.wallUndirect);
                if (floorParse[4] === 'Top') hangType = SavaneJS.Coating.HangType.wallTop;
                if (floorParse[4] === 'Bottom') hangType = SavaneJS.Coating.HangType.wallBottom;
                if (floorParse[4] === 'Left') hangType = SavaneJS.Coating.HangType.wallLeftSide;
                if (floorParse[4] === 'Right') hangType = SavaneJS.Coating.HangType.wallRightSide;
                config = Number(floorParse[6]);
            }
        } else if (parent.isFloorEntity()) {
            if (child.name.startsWith("axo_Floor")) {
                coatingAreaNb = Number(floorParse[3]) - 1;
                if (floorParse.length === 7) {
                    config = Number(floorParse[6]);
                }
                if (floorParse[4] === '0') config = null;
            } else {
                coatingAreaNb = Number(floorParse[2]) - 1;
                if (floorParse.length === 6) {
                    config = Number(floorParse[5]);
                    if (floorParse[3] === '0') config = null;
                }
            }
            coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.FloorCoatingArea) as Array<SavaneJS.FloorCoatingArea>;
        } else if (parent.isGeometryPrimitiveEntity()) {
            coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;
            if (child.name.startsWith("axo_Floor")) {
                config = Number(floorParse[6]);
            } else {
                config = Number(floorParse[5]);
            }
        } else if (parent.isStaircaseEntity()) {
            let searchedUsemtlName;
            coatings = parent.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;

            if (child.name.indexOf("CounterStep") !== -1) {
                searchedUsemtlName = '2';
            }
            else {
                if (child.name.indexOf("BackStep") !== -1) {
                    searchedUsemtlName = '5';
                }
                else {
                    if (child.name.indexOf("Step") !== -1) {
                        searchedUsemtlName = '1';
                    }
                }
            }

            for (let i = coatings.length - 1 ; i >= 0 ; i--) {
                if (coatings[i].usemtlName !== searchedUsemtlName) {
                  coatings.splice(i, 1);
                }
            }

            if (child.name.startsWith("axo_Floor")) {
                config = Number(floorParse[6]);
            } else {
                config = Number(floorParse[5]);
            }
        }
        return { coatings, config, hangType, coatingAreaNb };
    }

    _processChild(child: THREE.Mesh) : void {
        if (!this._entity) {
            if (child.isMesh) {
                let euler = new THREE.Euler(Math.PI * 0.5, 0, 0, 'XYZ');
                child.geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromEuler(euler));
                child.geometry.applyMatrix4(new THREE.Matrix4().makeScale(0.01, 0.01, 0.01));
            }

            //hull creation - link elements to scene entities
            let entity = this.getEntityFromChild(child);
            if (entity) {
                if (this.scene.interactionWithItemAllowed(entity)) {
                    this.scene.planEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, false));
                } else {
                    this.scene.nonInteractivePlanEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, true));
                }
            }
        }

        //Enable shadow
        child.receiveShadow = true;
        child.castShadow = true;

        this._handleFloor(child, this._entity);
        this._handleInterFloor(child);

        if (!child.material && child.isMesh) {
            child.material = Material.default(new THREE.Color(0xffffff), null, {
                
            });
        }

        // apply customCoating
        for (let i = 0; i < this.savane.children.length; ++i) {
            let floor = this.savane.children[i] as SavaneJS.Floor;
            if (floor.entityType === SavaneJS.SceneConstants.EntityType.Floor) {
                let name = child.name.replace(/_[a-fA-F0-9]{24}_\d+/, '');
                let custom = floor.getCustomCoating(name);
                if (custom) {
                    Material.setMaterialFromCoating(child, custom.coating, true, undefined, this.scene, false, null);
                    (child as any).coatingId = custom.coating.coatingId;
                }
            }
        }
    }

}
