import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { InteractiveProjectHandler } from '../interactiveProjectHandler';
import { _ASSET_TYPE } from '@rhinov/asset-manager-service';

declare var Savane;

export class ReplaceArrangementCommand extends SavaneJS.Commands.Command {
    
    private _handler: any;
    private _toReplace: any;
    private _replaceBy: any;
    private _isSubCommand: any;
    private _commands: any[];

    constructor(handler: InteractiveProjectHandler, toReplace: SavaneJS.ArrangementObject, replaceBy: SavaneJS.ArrangementObject, isSubCommand: boolean) {
        super();

        replaceBy.isAnchorActive = false;
        toReplace.isAnchorActive = false;
        
        this._handler = handler;
        this._toReplace = toReplace;
        this._replaceBy = replaceBy;

        this._replaceBy.id = this._toReplace.id;

        this._isSubCommand = isSubCommand;

        if (!this._isSubCommand) {
            if ((toReplace as any).onTopEntities) {
                (replaceBy as any).onTopEntities = (toReplace as any).onTopEntities;
            } else {
                (toReplace as any).onTopEntities = this._handler.collectArrangementsOnTop(toReplace);
                (replaceBy as any).onTopEntities = (toReplace as any).onTopEntities;
            }
            if ((toReplace as any).isUnder) {
                (replaceBy as any).isUnder = (toReplace as any).isUnder;
            }
            if (toReplace.initialObjectId) {
                replaceBy.initialObjectId = toReplace.initialObjectId;
            } else {
                replaceBy.initialObjectId = toReplace.objectId;
            }
        }

        this._commands = [];
    }

    _snapArrangementsOn = (arrangement: SavaneJS.ArrangementObject, candidates: Array<SavaneJS.ArrangementObject>, differencial: number) : void => {
        this._handler.scene.showExcludedObject();
        candidates.sort(function(a, b) {
            return b.floorHeight - a.floorHeight;
        });
        for (var i = 0; i < candidates.length; ++i) {
            var furniture = this._handler.scene.getPlanEntity(candidates[i].id);
            if (!furniture) { 
                continue;
            }

            if (furniture.entity.id === arrangement.id) {
                continue;
            }

            if (furniture.entity.anchor[2] !== -1) {
                continue;
            }

            var position = furniture.entity._transform.globalPosition;
            // Snap down
            var startPoint = new THREE.Vector3(position[0] / 100, position[1] / 100, (position[2] + differencial + (arrangement.height / 2 + 100)) / 100);
            if (furniture.entity.isUnder.indexOf(arrangement.id) !== -1) {
                //plaid
                if (furniture.entity.objectType === "5bc09cbc008fef5c0a7e3f49") {
                    startPoint.z = (furniture.entity.floorHeight + furniture.entity.realHeight + 100) / 100;
                } else {
                    startPoint.z = (furniture.entity.floorHeight + 100) / 100;
                }
            }
            var direction = new THREE.Vector3(0, 0, -1);
            this._handler.scene.rayCaster.set(startPoint, direction);
            this._handler.scene.setLayer(furniture.object, 1);
            var intersects = this._handler.scene.rayCaster.intersectObjects(this._handler.scene.threeScene.children, true);
            intersects = this._handler.removeGizmoFromIntersectionResults(intersects, true);

            this._handler.scene.setLayer(furniture.object, 0);

            if (intersects.length === 0) {
                continue;
            }

            var doSnap = false;
            var point = null;
            for (var j = 0; j < intersects.length; ++j) {
                var id = intersects[j].object.parent.userData.id;

                // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                if (id === undefined) {
                    id = intersects[j].object.userData.id;
                }
                if (id === arrangement.id) {
                    doSnap = true;
                    point = intersects[0].point;
                    break;
                }
            }
            
            furniture.entity.startTemporary();
            if (doSnap) {
                if (furniture.entity.objectType === "5bc09c78008fef5c0a7e3f47") {
                    //coussin
                    point.z -= (furniture.entity.realHeight / 100) * 0.1;
                } else if (furniture.entity.objectType === "5bc06947efe98c5c27d0a809" ||
                    furniture.entity.objectType === "5be400d5243a8b5d4c9bfb9a") {
                    //couette
                    point.z -= (furniture.entity.height / 100) * 0.15;
                } else if (furniture.entity.objectType === "5bc09cbc008fef5c0a7e3f49") {
                    //plaid
                    point.z -= (furniture.entity.height / 100) * 0.85;
                }
                if (furniture.entity.parent) {
                    var toLocal = furniture.entity.parent.transform.invertedGlobalMatrix;
                    var p = SavaneJS.math.vec3.fromValues(point.x * 100, point.y * 100, point.z * 100);
                    SavaneJS.math.vec3.transformMat4(p, p, toLocal);
                    point = new THREE.Vector3(p[0] / 100, p[1] / 100, p[2] / 100);
                }

                furniture.entity.floorHeight = point.z * 100 + (furniture.entity.realHeight - furniture.entity.height) / 2;
                furniture.entity.isAnchorActive = false;
                furniture.update();
            }

            this._commands.push(new Savane.Commands.EditEntityCommand(furniture.entity));
        }
        this._handler.scene.updateCamera(this._handler.scene.camera.entity.id, true);
    }

    _updateOnTopEntities = (toReplace: SavaneJS.ArrangementObject, replaceBy: SavaneJS.ArrangementObject) : void => {
        var arrangementObjects = this._handler.scene.savaneScene.arrangementObjects;
        for (var i = 0; i < arrangementObjects.length; ++i) {
            var arrangementObject = arrangementObjects[i];
            if (arrangementObject.onTopEntities) {
                for (var j = 0; j < arrangementObject.onTopEntities.length; ++j) {
                    if (arrangementObject.onTopEntities[j] === toReplace) {
                        arrangementObject.onTopEntities[j] = replaceBy;
                    }
                }
            }
            if (arrangementObject.isUnder) {
                for (var j = 0; j < arrangementObject.isUnder.length; ++j) {
                    if (arrangementObject.isUnder[j] === toReplace.id) {
                        arrangementObject.isUnder[j] = replaceBy.id;
                    }
                }
            }
        }
    }

    // Command name returned from a global enum
    name = () : string => {
        return "ReplaceArrangementCommand";
    }

    assetType = () => {
        return _ASSET_TYPE.ARRANGEMENTS;
    }

    undo = () : void => {
        var position = this._replaceBy.position;
        SavaneJS.math.mat4.copy(this._toReplace.transform.localMatrix, this._replaceBy.transform.globalMatrix);
        if (this._toReplace.anchor && this._toReplace.anchor[1] === -3 || this._replaceBy.anchor && this._replaceBy.anchor[1] === -3) {
            position[2] += (this._replaceBy.height - this._toReplace.height) / 2;
        } else {
            position[2] -= (this._replaceBy.height - this._toReplace.height) / 2;
        }
        this._toReplace.position = position;

        let lengthRatio = 1;
        let widthRatio = 1;
        let heightRatio = 1;
        if (this._toReplace.originalLength !== undefined) {
            lengthRatio = this._toReplace.length / this._toReplace.originalLength;
        }
        if (this._toReplace.originalWidth !== undefined) {
            widthRatio = this._toReplace.width / this._toReplace.originalWidth;
        }
        if (this._toReplace.originalHeight !== undefined) {
            heightRatio = this._toReplace.height / this._toReplace.originalHeight;
        }

        this._toReplace.transform.localScale = SavaneJS.math.vec3.fromValues(lengthRatio, widthRatio, heightRatio);

        for (var i = this._commands.length - 1; i >= 0; --i) {
            this._commands[i].undo();
        }

        this._handler.scene.removeEntity(this._replaceBy);
        this._handler.scene.addEntityTree(this._toReplace, function(entity) {
            if (this._isSubCommand) return;
            if (this._handler.scene.outlinePass) {
                this._handler.scene.outlinePass.hiddenEdgeColor = new THREE.Color("#000000").convertSRGBToLinear();
                this._handler.scene.outlinePass.selectedObjects = [entity.object];
            }

            for (var i = 0; i < this._replaceBy.onTopEntities.length; ++i) {
                var furniture = this._handler.scene.getPlanEntity(this._replaceBy.onTopEntities[i].id);
                if (!furniture) { 
                    continue;
                }

                furniture.update();
            }

            this._handler.scene.render();
            this._updateOnTopEntities(this._replaceBy, this._toReplace);

            Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_ARRANGEMENT, { entity: entity.entity, point: null });
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this._toReplace.scene, command: null });
        }.bind(this));
    }

    redo = () : void => {
        this._exec(true, null);
    }

    execute = () : void => {
        this._exec(false, null);
    }

    _exec = (redo: boolean, callback: CallableFunction) : void => {
        this._commands = [];

        var position = this._toReplace.position;
        SavaneJS.math.mat4.copy(this._replaceBy.transform.localMatrix, this._toReplace.transform.globalMatrix);
        
        var differencial = 0;
        if (this._toReplace.anchor && this._toReplace.anchor[1] === -3 || this._replaceBy.anchor && this._replaceBy.anchor[1] === -3) {
            differencial = (this._toReplace.height - this._replaceBy.height) / 2;
            position[2] += differencial;
        } else {
            differencial = -(this._toReplace.height - this._replaceBy.height) / 2;
            position[2] += differencial;
        }
        this._replaceBy.position = position;
        this._replaceBy.transform.localScale = SavaneJS.math.vec3.fromValues(1, 1, 1);

        var replaceCmd = new Savane.Commands.ReplaceEntitiesCommand([this._toReplace], [{entity: this._replaceBy, parent: this._toReplace.parent}]);
        this._commands.push(replaceCmd);

        this._handler.scene.removeEntity(this._toReplace);
        this._handler.scene.addEntityTree(this._replaceBy, function(entity) {
            if (!this._isSubCommand) {
                this._snapArrangementsOn(this._replaceBy, this._toReplace.onTopEntities, (differencial < 0 ? 0 : differencial));
                if (this._handler.scene.outlinePass) {
                    this._handler.scene.outlinePass.hiddenEdgeColor = new THREE.Color("#000000").convertSRGBToLinear();
                    this._handler.scene.outlinePass.selectedObjects = [entity.object];
                }
            }

            for (var i = 0; i < this._commands.length; ++i) {
                this._commands[i].execute();
            }
        
            if (!this._isSubCommand) {
                this._updateOnTopEntities(this._toReplace, this._replaceBy);
                this._handler.selectedNode = [entity];

                this._handler.UpdateCamera();
                this._handler.scene.render();

                Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this._replaceBy.scene, command: redo ? null : this });
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_ARRANGEMENT, { entity: entity.entity, point: null });
            }

            if (callback) {
                callback();
            }
        }.bind(this));
    }

}
