import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { WebglScene } from '../scene';
import { Cleaner } from '../helpers/cleaner';
import { Material } from '../helpers/material';
import { WebglHullEntity } from '../hullEntity';
import { OBJLoader } from '../obj/OBJLoader.js';
import { BINLoader } from '../obj/BINLoader.js';
import { holdoutMaterial } from '../../constants';

declare let AssetManagerServices;
 
export class DynamicHull {

    public hull: THREE.Group;
    public group: THREE.Group;

    private savane: SavaneJS.Scene;
    private scene: WebglScene;
    private _entity: SavaneJS.Entity | null;

    constructor(obj: string, savane: SavaneJS.Scene, scene: WebglScene) {
        this.savane = savane;
        this.scene = scene;
        let loader;
        if (!scene.settings.hullbin) {
            loader = new OBJLoader();
            this.hull = loader.parse(obj);
        }
        else {
            loader = new BINLoader();
            this.hull = loader.parse(obj).object;
        }
        this.group = new THREE.Group();
        this.group.add(this.hull);
        //updated entity
        this._entity = null;
        this._process();
    }

    static create(obj: string, savane: SavaneJS.Scene, scene: WebglScene) {
        return new DynamicHull(obj, savane, scene);
    }

    dispose() : void {
        let traversing = function(child: THREE.Mesh) {
            if (child.geometry) {
                child.geometry.dispose();
            }
            if ((child as any).body) {
                this.scene.physics.destroy((child as any).body);
            }
            if (child.material) {
                Cleaner.cleanMaterial(child.material);
            }
            if ((child as any).cubeCamera) {
                (child as any).cubeCamera.renderTarget.dispose();
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    showCeiling() : void {
        let traversing = function(child: THREE.Object3D) {
            if (child.name.startsWith("axo")) {
                child.castShadow = true;
                child.receiveShadow = true;
                child.layers.set(0);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    hideCeiling() : void {
        let traversing = function(child: THREE.Object3D) {
            // Ceiling always hidden
            let hide = false;
            if (child.name.startsWith("axo")) {
                hide = true;
            }

            if (hide === true) {
                child.castShadow = false;
                child.receiveShadow = false;
                child.layers.set(1);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    showUpperFloor(floor: SavaneJS.Floor) : void {
        if (!floor) return;

        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(0);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    hideUpperFloor(floor: SavaneJS.Floor) : void {
        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(1);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    update(entity: SavaneJS.Entity) : void {
        this._entity = entity;
        this.hull.traverse(this._processChild.bind(this));
        this._entity = null;
    }

    updateCoatingParameters(component: SavaneJS.Component) : void {
        let traversing = function(child: THREE.Mesh) {
            if (child.name.startsWith("CoatingArea")) {
                let coatingAreaParse = child.name.split('_');
                let wallId = Number(coatingAreaParse[1]);

                if (wallId === component.entity.id) {
                    let wall = this.savane.getDeepChild(wallId);
                    let coatingAreaNb = Number(coatingAreaParse[2]) - 1;
                    if (!wall) {
                        return;
                    }

                    let coatings = wall.getComponents(SavaneJS.ComponentConstants.ComponentType.CoatingArea);

                    if ((coatings !== null) && (coatings.length !== 0)) {
                        let material = child.material as THREE.MeshPhongMaterial;
                        if (coatings[coatingAreaNb] === component) {
                            material.map.rotation = (coatings[coatingAreaNb].rotation * Math.PI) / 180;
                            material.map.offset.x = (coatings[coatingAreaNb].offset[0]);
                            material.map.offset.y = (coatings[coatingAreaNb].offset[1]);
                            material.map.repeat.x = (material.map as any).originalRepeat.x * (coatings[coatingAreaNb].repeat[0]);
                            material.map.repeat.y = (material.map as any).originalRepeat.y * (coatings[coatingAreaNb].repeat[1]);
                        }
                    }
                    else {
                        // Default color assignment when no coating
                        child.material = Material.default(new THREE.Color(0xffffff), null, {

                        });
                    }
                }
            }
            else if (child.name.startsWith("Credence")) {
                let credenceParse = child.name.split('_');
                let worktopId = Number(credenceParse[1]);

                if (worktopId === component.entity.id) {
                    let worktop = this.savane.getDeepChild(worktopId);
                    if (!worktop) {
                        return;
                    }

                    let coatings = worktop.getComponents(SavaneJS.ComponentConstants.ComponentType.Credence);

                    if ((coatings !== null) && (coatings.length !== 0)) {
                        let material = child.material as THREE.MeshPhongMaterial;
                        if (coatings[0] === component) {
                            material.map.rotation = (coatings[0].rotation * Math.PI) / 180;
                            material.map.offset.x = (coatings[0].offset[0]);
                            material.map.offset.y = (coatings[0].offset[1]);
                            material.map.repeat.x = (material.map as any).originalRepeat.x * (coatings[0].repeat[0]);
                            material.map.repeat.y = (material.map as any).originalRepeat.y * (coatings[0].repeat[1]);
                        }
                    }
                    else {
                        // Default color assignment when no coating
                        child.material = Material.default(new THREE.Color(0xffffff), null, {

                        });
                    }
                }
            } else if (child.name.startsWith("GeometryPrimitive") || child.name.startsWith("axo_GeometryPrimitive")) {
                //add node to wall
                let geometryPrimitiveParse = child.name.split('_');
                let geomPrimId = child.name.startsWith("axo_GeometryPrimitive") ? Number(geometryPrimitiveParse[2]) : Number(geometryPrimitiveParse[1]);

                if (geomPrimId === component.entity.id) {
                    let geomPrim = this.savane.getDeepChild(geomPrimId);

                    if (geomPrim !== null) {
                        let coatings = geomPrim.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating);

                        if ((coatings !== null) && (coatings.length !== 0)) {
                            let material = child.material as THREE.MeshPhongMaterial;
                            if (material.map) {
                                material.map.rotation = (coatings[0].rotation * Math.PI) / 180;
                                material.map.offset.x = (coatings[0].offset[0]);
                                material.map.offset.y = (coatings[0].offset[1]);
                                material.map.repeat.x = (material.map as any).originalRepeat.x * (coatings[0].repeat[0]);
                                material.map.repeat.y = (material.map as any).originalRepeat.y * (coatings[0].repeat[1]);
                            }
                        }
                    }
                }
            }
        };

        this.hull.traverse(traversing.bind(this));
    }

    static getIdFromChild(child: THREE.Object3D) : number | null {
        let result: number | null = null;

        if (child.name.startsWith("Worktop")) {
            let worktopParse = child.name.split('_');
            result = Number(worktopParse[1]);
        } else if (child.name.startsWith("fillers")) {
            let fillerParse = child.name.split('_');
            result = Number(fillerParse[1]);
        } else if (child.name.startsWith("Credence")) {
            let credenceParse = child.name.split('_');
            result = Number(credenceParse[1]);
        } else if (child.name.startsWith("CoatingArea") || child.name.startsWith("StickCoatingArea")) {
            let coatingAreaParse = child.name.split('_');
            result = Number(coatingAreaParse[1]);
        } else if (child.name.startsWith("KitchenPlinth")) {
            let plinthParse = child.name.split('_');
            result = Number(plinthParse[1]);
        } else if (child.name.startsWith("GeometryPrimitive")) {
            let parse = child.name.split('_');
            result = Number(parse[1]);
        } else if (child.name.startsWith("axo_GeometryPrimitive")) {
            let parse = child.name.split('_');
            result = Number(parse[2]);
        }

        return result ? Math.abs(result) : null;
    }

    getChildsFromId(id: number) : Array<THREE.Object3D> {
        let result = new Array<THREE.Object3D>();
        let traversing = function(child: THREE.Object3D) {
            if (id === DynamicHull.getIdFromChild(child)) {
                result.push(child);
            }
        }
        this.hull.traverse(traversing.bind(this));
        return result;
    }

    getEntityFromChild(child: THREE.Object3D) : SavaneJS.Entity | null {
        let id = DynamicHull.getIdFromChild(child);
        if (id) {
            return this.savane.getDeepChild(id);
        }
        return null;
    }

    static replaceChildNameId(child: THREE.Object3D, id: number) {
        if (child.name.startsWith("GeometryPrimitive")) {
            let parse = child.name.split('_');
            parse[1] = String(id);
            child.name = parse.join('_');
        } else if (child.name.startsWith("axo_GeometryPrimitive")) {
            let parse = child.name.split('_');
            parse[2] = String(id);
            child.name = parse.join('_');
        }
    }

    filterFloor(floor: SavaneJS.Floor) : void {
        let traversing = function(child: THREE.Object3D) {
            let entity = this.getEntityFromChild(child);
            if (floor !== null && entity !== null) {
                if (floor.getDeepChild(entity.id)) {
                    child.layers.set(0);
                } else {
                    child.layers.set(1);
                }
            } else {
                child.layers.set(0);
            }

        }
        this.hull.traverse(traversing.bind(this));
    }

    _process() : void {
        this.hull.traverse(this._processChild.bind(this));
    }

    _handleWorktop(child: THREE.Mesh) : void {
        if (!child.name.startsWith("Worktop")) {
            return;
        }

        let worktopParse = child.name.split('_');
        let id = Number(worktopParse[1]);
        let worktop = this.savane.getDeepChild(id);
        if (!worktop) {
            return;
        }

        let coatings = worktop.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;

        if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            Material.setMaterialFromCoating(child, coatings[0], true, coatings[0].colorIndex, this.scene, false, null);
            (child as any).coatingId = coatings[0].coatingId;
        }
        else {
            // Default color assignment when no coating
            child.material = Material.default(new THREE.Color(0xffffff), null, {

            });
        }
    }

    _handleFillers(child: THREE.Mesh) {
        if (!child.name.startsWith("fillers")) {
            return;
        }

        let fillerParse = child.name.split('_');
        let id = Number(fillerParse[1]);
        let arrangement = this.savane.getDeepChild(id);

        if (!arrangement) {
            return;
        }

        let coatings = arrangement.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;
        let found = false;

        for (let i = 0; i < coatings.length; i++) {
            if (coatings[i].hangType === SavaneJS.Coating.HangType.door) {
                found = true;
                AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.COATINGS, coatings[i].coatingId, null, function(coatingAsset) {
                    // Loaded ?
                    if (coatingAsset !== null) {
                        // Assign material to mesh
                        Material.setMaterialFromCoating(child, { coatingId:coatingAsset._id }, false, 1, this.scene, false, null);
                        (child as any).coatingId = coatings[i].coatingId;
                    }
                    else {
                        child.material = Material.default(new THREE.Color(0xffffff), null, {

                        });
                    }
                }.bind(this));

                break;
            }
        }

        // Not found, furtinure with no door
        if (!found) {
            for (let i = 0; i < coatings.length; i++) {
                if (coatings[i].hangType === SavaneJS.Coating.HangType.box) {
                    AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.COATINGS, coatings[i].coatingId, null, function(coatingAsset) {
                        // Loaded ?
                        if (coatingAsset !== null) {
                            // Assign material to mesh
                            Material.setMaterialFromCoating(child, coatingAsset, false, 1, this.scene, false, null);
                            (child as any).coatingId = coatings[i].coatingId;
                        }
                        else {
                            child.material = Material.default(new THREE.Color(0xffffff), null, {

                            });
                        }
                    }.bind(this));

                    break;
                }
            }
        }
    }

    _handleCredence(child: THREE.Mesh) : void {
        if (!child.name.startsWith("Credence")) {
            return;
        }

        let credenceParse = child.name.split('_');
        let id = Number(credenceParse[1]);
        let worktop = this.savane.getDeepChild(id);
        if (!worktop) {
            return;
        }

        let credenceIndex = Number(credenceParse[2]) - 1;
        let coatings = worktop.getComponents(SavaneJS.ComponentConstants.ComponentType.Credence) as Array<SavaneJS.Credence>;

        let coating: SavaneJS.Credence | null = null;
        if ((coatings !== null) && (coatings.length !== 0)) {
            let globalCoating: SavaneJS.Credence | null = null;
            for (let i = 0; i < coatings.length; ++i) {
                if (coatings[i].credenceIndex === credenceIndex) {
                    coating = coatings[i];
                }
                if (coatings[i].credenceIndex === null) {
                    globalCoating = coatings[i];
                }
            }
            if (!coating) coating = globalCoating;
        }
        if (coating) {
            // Assign material to mesh
            Material.setMaterialFromCoating(child, coating, true, coating.colorIndex, this.scene, false, null);
            (child as any).coatingId = coating.coatingId;
        }
        else {
            // Default color assignment when no coating
            child.material = Material.default(new THREE.Color(0xffffff), null, {

            });
        }
    }

    _handleCoatingArea(child: THREE.Mesh) : void {
        if (!child.name.startsWith("CoatingArea")) {
            return;
        }

        let coatingAreaParse = child.name.split('_');
        let wallId = Number(coatingAreaParse[1]);
        let wall = this.savane.getDeepChild(wallId);
        let coatingAreaNb = Number(coatingAreaParse[2]) - 1;
        if (!wall) {
            return;
        }

        let config = undefined;
        if (coatingAreaParse.length === 6) {
            config = coatingAreaParse[5];
        }

        let coatings = wall.getComponents(SavaneJS.ComponentConstants.ComponentType.CoatingArea) as Array<SavaneJS.CoatingArea>;

        if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            Material.setMaterialFromCoating(child, coatings[coatingAreaNb], true, config, this.scene, false, null);
            (child as any).coatingId = coatings[coatingAreaNb].coatingId;
            if (coatings[coatingAreaNb].floorGeneratorSettings && coatings[coatingAreaNb].floorGeneratorSettings.jointCoating && !config) {
                child.material = Material.default(new THREE.Color(coatings[coatingAreaNb].floorGeneratorSettings.jointCoating.colors[0].realColor), null, {

                });
            }
        }
        else {
            // Default color assignment when no coating
            child.material = Material.default(new THREE.Color(0xffffff), null, {

            });
        }
    }

    _handleKitchenPlinth(child: THREE.Mesh) : void {
        if (!child.name.startsWith("KitchenPlinth")) {
            return;
        }

        let plinthParse = child.name.split('_');
        let entityId = Number(plinthParse[1]);

        let entity = this.savane.getDeepChild(entityId);
        if (!entity) {
            return;
        }

        let coatings = entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;

        if ((coatings !== null) && (coatings.length > 0)) {
            for (let i = 0; i < coatings.length; i++) {
                if (coatings[i].hangType === SavaneJS.Coating.HangType.kitchenplinth) {
                    // Assign material to mesh
                    Material.setMaterialFromCoating(child, coatings[i], true, coatings[i].colorIndex, this.scene, false, null);
                    (child as any).coatingId = coatings[i].coatingId;
                    break;
                }
            }
        }
        else {
            child.material = Material.default(new THREE.Color(0xffffff), null, {

            });
        }
    }

    _handleGeometryPrimitive(child: THREE.Mesh, entity: SavaneJS.Entity) : void {
        let geometryPrimitiveParse = child.name.split('_');
        let index = geometryPrimitiveParse.indexOf("GeometryPrimitive");

        if (index === -1) {
            return;
        }

        let id = Number(geometryPrimitiveParse[index + 1]);
        let geomPrim = this.savane.getDeepChild(id);
        if (!geomPrim) {
            return;
        }

        if (geomPrim.holdout) {
            if (Array.isArray(child.material)) {
                for (let i = 0; i < child.material.length; ++i) {
                    let name = child.material[i].name;
                    child.material[i] = holdoutMaterial.clone();
                    child.material[i].name = name;
                }
            } else {
                let name = child.material.name;
                child.material = holdoutMaterial.clone();
                child.material.name = name;
            }
            return;
        }

        if (entity && entity.id !== id) {
            return;
        }

        let material = {
            color: 0xffffff
        };

        let coatings = geomPrim.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating) as Array<SavaneJS.Coating>;

        if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false, null);
        }
        else {
            if (Array.isArray(child.material)) {
                for (let i = 0; i < child.material.length; ++i) {
                    child.material[i] = Material.default(new THREE.Color(material.color), null, {

                    });
                }
            } else {
                child.material = Material.default(new THREE.Color(material.color), null, {

                });
            }
        }
    }

    _processChild(child: THREE.Mesh) {
        //Enable shadow
        child.receiveShadow = true;
        child.castShadow = true;

        this._handleWorktop(child);
        this._handleFillers(child);
        this._handleCredence(child);
        this._handleCoatingArea(child);
        this._handleKitchenPlinth(child);
        this._handleGeometryPrimitive(child, this._entity);

        if (!child.material && child.isMesh) {
            child.material = Material.default(new THREE.Color(0xffffff), null, {

            });
        }

        if (!this._entity) {
            if (child.isMesh) {
                let euler = new THREE.Euler(Math.PI * 0.5, 0, 0, 'XYZ');
                child.geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromEuler(euler));
                child.geometry.applyMatrix4(new THREE.Matrix4().makeScale(0.01, 0.01, 0.01));
            }

            //hull creation - link elements to scene entities - only geometry primitive can be moved
            let entity = this.getEntityFromChild(child);
            if (entity) {
                if (entity.isGeometryPrimitiveEntity()) {
                    this.scene.planEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, false));
                } else if (entity.isWorktopEntity()) {
                    this.scene.planEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, true));
                } else {
                    this.scene.nonInteractivePlanEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, true));
                }
            }
        }

        // apply customCoating
        for (let i = 0; i < this.savane.children.length; ++i) {
            let floor = this.savane.children[i] as SavaneJS.Floor;
            if (floor.entityType === SavaneJS.SceneConstants.EntityType.Floor) {
                let name = child.name.replace(/_[a-fA-F0-9]{24}_\d+/, '');
                let custom = floor.getCustomCoating(name);
                if (custom) {
                    Material.setMaterialFromCoating(child, custom.coating, true, undefined, this.scene, false, null);
                    (child as any).coatingId = custom.coating.coatingId;
                }
            }
        }
    }

}
