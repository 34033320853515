/**
 * WebGL savane other entity management (!= ArrangementObject since Arrangement objects use furniture), this typically serves to display entities that doesn't have any 3D representation yet
 * Generally those entities are generated directly in the hull
 */

import * as SavaneJS from '@rhinov/savane-js';
import { WebglScene } from './scene';
import * as THREE from 'three';

declare var EntityManager;

export class WebglEntity {

    public entity: SavaneJS.Entity;
    public object: THREE.Group;

    private scene: WebglScene;
    private previousScale: THREE.Vector3 = new THREE.Vector3(1, 1, 1);

    constructor(entity: SavaneJS.Entity, scene: WebglScene) {
        // Keep track of the savane entity
        this.entity       = entity;
        // Create a group for the object
        this.object       = new THREE.Group();
        this.object.userData = { id: this.entity.id };
    
        this.scene = scene;
    
        this.update();
    }

    update() : void {
        // Update THREE matrix based on savane matrix datas
        var localMatrix = this.entity.transform.localMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(localMatrix[0], localMatrix[1], localMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(localMatrix[4], localMatrix[5], localMatrix[6]).normalize();
        var Z = new THREE.Vector3().set(localMatrix[8], localMatrix[9], localMatrix[10]).normalize();

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);

        var position = new THREE.Vector3();
        var quaternion = new THREE.Quaternion();
        var scale = new THREE.Vector3();
        basis.decompose(position, quaternion, scale);

        this.object.quaternion.copy(quaternion);
        this.object.position.set(localMatrix[12] / 100, localMatrix[13] / 100, localMatrix[14] / 100);
        this.object.scale.copy(scale);
        this.scene.updateEnvs();
    }

    enableCollision(scale: boolean) : void {
    }

    /**
     * Apply back a change performed in the webGL view into the savane entity
     **/
    applyToEntity(mode: string, space: string) : void {
        if (mode === 'rotate') {
            var array = new Array<number>();
            this.object.matrix.toArray(array);
            if (space === 'local') {
                array[12] = this.entity.transform.localPosition[0] / 100;
                array[13] = this.entity.transform.localPosition[1] / 100;
                array[14] = this.entity.transform.localPosition[2] / 100;
            }
            var savaneMatrix = SavaneJS.math.mat4.create();
            SavaneJS.math.mat4.set(savaneMatrix,
                array[0], array[1], array[2], array[3],
                array[4], array[5], array[6], array[7],
                array[8], array[9], array[10], array[11],
                array[12] * 100, array[13] * 100, array[14] * 100, array[15]);
            this.entity.transform.localMatrix = savaneMatrix;
        }
        else if (mode === 'translate') {
            var array = new Array<number>();
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        } else {
            var scale = this.object.scale;
            var newScale = new THREE.Vector3().copy(scale);
            var entity = this.entity as SavaneJS.ArrangementObject;
            if (this.entity.entityType === SavaneJS.SceneConstants.EntityType.ArrangementObject && entity.stretchability != undefined) {
                if (entity.stretchability.x !== undefined && entity.stretchability.x.isStretchable && this.previousScale.x !== scale.x) {
                    entity.length = Math.min(Math.max(entity.originalLength * Math.abs(scale.x), entity.originalLength / 4), entity.originalLength * 2);
                    newScale.x = entity.length / entity.originalLength;
                }
                if (entity.stretchability.y !== undefined && entity.stretchability.y.isStretchable && this.previousScale.y !== scale.y) {
                    entity.width = Math.min(Math.max(entity.originalWidth * scale.y, entity.originalWidth / 4), entity.originalWidth * 2);
                    newScale.y = entity.width / entity.originalWidth;
                }
                if (entity.stretchability.z !== undefined && entity.stretchability.z.isStretchable && this.previousScale.z !== scale.z) {
                    entity.height = Math.min(Math.max(entity.originalHeight * scale.z, entity.originalHeight / 4), entity.originalHeight * 2);
                    newScale.z = entity.height / entity.originalHeight;
                }   
            } else {
                newScale.x = this.entity.transform.localScale[0];
                newScale.y = this.entity.transform.localScale[1];
                newScale.z = this.entity.transform.localScale[2];
            }
            this.previousScale = new THREE.Vector3().copy(this.object.scale);
            this.entity.transform.localScale = [newScale.x, newScale.y, newScale.z];
            var array = new Array<number>();
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        }

        if (typeof EntityManager !== 'undefined') {
            var node = EntityManager.getNode(this.entity);
            if (node !== null) {
                node.needRedraw = true;
            }
        }
        this.update();
    }
}
